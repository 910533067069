import React, { useState } from 'react';

import { Footer, Scenarios, What, Header } from './containers';
import { DEFS, PoweredBy, Navbar} from './components';
import './app.css';

const App = () => {
  // Sign up popup logic raised to high level to enable usage in other jsx pages
  const [isSignUpPopupVisible, setSignUpVisible] = useState(false);
  const [isSignInPopupVisible, setSignInVisible] = useState(false);
  const [intentionalSignIn, setIntentionalSignIn] = useState(false);

  const toggleSignUpPopup = () => {
    setSignInVisible(!isSignInPopupVisible);
    setSignUpVisible(!isSignUpPopupVisible);
  };

  const closePopup = () => {
    setSignUpVisible(false);
    setSignInVisible(false);
  };

  const showSignIn = () => {
    setSignInVisible(true);
  };

  const showSignUp = () => {
    setSignUpVisible(true);
  };

  const setSignIntent = () => {
    setIntentionalSignIn(true);
  };

  return (
    <div className='App'>
        <div className='gradient__bg'>
          <Navbar isSignUpVisible={isSignUpPopupVisible} 
                  isSignInVisible={isSignInPopupVisible} 
                  handleSignClick={toggleSignUpPopup} 
                  closePopup={closePopup}
                  showSignIn={showSignIn}
                  showSignUp={showSignUp}
                  setIntent={setSignIntent}
                  intent={intentionalSignIn}/>
          <Header showSignUp={showSignUp}
                  setIntent={setSignIntent} />
        </div>
        <PoweredBy />
        <What />
        <Scenarios />
        <DEFS />
        <Footer />
    </div>
  )
}

export default App