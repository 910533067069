import React from 'react';
import './terms.css';
import { useNavigate } from 'react-router-dom';



const TermsPage = () => {
    let navigate = useNavigate();

    const goHome = () => {
        navigate('../');
    }


    return (
        <div className="container">
            
            <button className="return-button" onClick={goHome}>
                Return Home
            </button>

            <div className="terms-card">
                <h1 className="terms-title">Terms and Conditions</h1>
                <p className="terms-text"><i>Last Updated: 3/6/24</i></p>
                <p className="terms-text">Verbeloquence is an application designed to help users master the art of 
                communication by engaging in professional scenarios through life-like conversations with AI. 
                By accessing or using our app, you agree to be bound by these Terms and Conditions. 
                If you do not agree to these terms, please do not use our services.</p>

                <p className="terms-header"><b>1. Use of Our Service</b></p>
                <p className="terms-text">Verbeloquence provides an interactive experience using technologies such as 
                Deepgram speech-to-text, OpenAI GPT-3.5-turbo, and Google Cloud Text-to-Speech. These technologies are 
                utilized to simulate realistic conversations in various professional and casual scenarios.</p>

                <p className="terms-header"><b>2. User Accounts</b></p>
                <p className="terms-text">To access some features of the app, you may need to create an account. 
                You are responsible for maintaining the confidentiality of your account credentials and for all 
                activities that occur under your account. You agree to notify us immediately upon becoming aware 
                of any breach of security or unauthorized use of your account.</p>

                <p className="terms-header"><b>3. Privacy and Data Use</b></p>
                <p className="terms-text">We take your privacy seriously. Information collected 
                from you, including conversation data, is stored and used in accordance with our Privacy Policy (found on this page).
                By using Verbeloquence, you consent to the collection, storage, and use of your data as outlined in our Privacy Policy. 
                We use Firebase for authentication and store data in Firestore.</p>

                <p className="terms-header"><b>4. Intellectual Property Rights</b></p>
                <p className="terms-text">The content and technologies provided through Verbeloquence, including all software, text, 
                and graphics, are open-source and can be found on GitHub. You may use any content from Verbeloquence without 
                our prior written permission.</p>

                <p className="terms-header"><b>5. Prohibited Conduct</b></p>
                <p className="terms-text">You agree not to use the app for any unlawful purpose or in any way that interrupts, damages, 
                or impairs the service. You may not use the app to harass, abuse, or harm another person or to post or distribute 
                content that is obscene, offensive, or infringes upon another’s rights.</p>

                <p className="terms-header"><b>6. Termination</b></p>
                <p className="terms-text">We may terminate or suspend your access to our app immediately, without prior notice or 
                liability, for any reason, including but not limited to a breach of these Terms and Conditions.</p>

                <p className="terms-header"><b>7. Changes to These Terms and Conditions</b></p>
                <p className="terms-text">We reserve the right to modify these terms at any time. By continuing to access or use 
                our app after those revisions become effective, you agree to be bound by the revised terms.</p>

                <p className="terms-header"><b>8. Disclaimer and Limitation of Liability</b></p>
                <p className="terms-text">Verbeloquence is provided on an "AS IS" and "AS AVAILABLE" basis. We make no representations 
                or warranties of any kind, express or implied, as to the operation of our app or the information, content, or materials 
                included on our app. You agree that your use of the app is at your sole risk.</p>

                <p className="terms-header"><b>9. Governing Law</b></p>
                <p className="terms-text">These Terms and Conditions shall be governed by and construed in accordance with the laws 
                of the United State of America, without regard to its conflict of law provisions.</p>

                <p className="terms-header"><b>10. Refund Policy</b></p>
                <p className="terms-text">All purchases made through Verbeloquence are final and non-refundable. 
                We do not offer refunds or credits for any transactions. Before finalizing your purchase, 
                please make sure you have carefully reviewed your order. By making a purchase with us, 
                you are confirming that you have read, understood, and agreed to this No-Refund Policy.</p>
                <p className="terms-text">In exceptional circumstances, such as a mistake in the amount of purchase or duplicated orders, 
                Verbeloquence reserves the right, at its sole discretion, to evaluate the situation and decide whether a refund, adjustment, 
                or credit is warranted. Any refund issued in such cases is a gesture of goodwill and does not constitute an obligation or 
                precedent for future requests.</p>
                <p className="terms-text">For purchases involving digital products or services, you acknowledge that access is granted immediately 
                after your purchase is processed, and therefore, you waive any right to cancel the purchase under any statutory right of cancellation 
                or "cooling-off" period that might otherwise apply.</p>
                <p className="terms-text">For subscription-based products or services, it is your responsibility to cancel your subscription 
                in accordance with the cancellation terms provided at the time of purchase. Cancellations must be made before the renewal date 
                to avoid being charged for the next subscription period. No refunds will be provided for charges incurred due to failure to cancel 
                in accordance with the cancellation terms.</p>

                <p className="terms-header"><b>11. Contact Us</b></p>
                <p className="terms-text">For any questions or concerns regarding these Terms and Conditions, 
                please contact us at mike@verbeloquence.com</p>
            </div>

            <div className="privacy-card">
                <h1 className="terms-title">Privacy Policy</h1>
                <p className="terms-text"><i>Last Updated: 2/24/24</i></p>
                <p className="terms-text">At Verbeloquence, accessible from verbeloquence.ai, one of our main priorities is the 
                privacy of our users. This Privacy Policy document contains types of information that is collected and recorded 
                by Verbeloquence and how we use it.</p>
                <p className="terms-text">If you have additional questions or require more information about our Privacy Policy, 
                do not hesitate to contact us.</p>

                <p className="terms-header"><b>Consent</b></p>
                <p className="terms-text">By using our app, you hereby consent to our Privacy Policy and agree to its terms.</p>

                <p className="terms-header"><b>Information We Collect</b></p>
                <p className="terms-text">The personal information that you are asked to provide, and the reasons why you are 
                asked to provide it, will be made clear to you at the point we ask you to provide your personal information.</p>
                <p className="terms-text">If you choose to use our app, we may require you to provide us with certain personally 
                identifiable information, including but not limited to: email address and Google account information.</p>
                <p className="terms-text">Other collected information includes but is not limited to: conversation data, login time-stamps, 
                payment time-stamps, conversation counters, and character selections. Some information (not cookies) is stored locally on the 
                client-side to track user gameplay choices and preferences</p>
                <p className="terms-text">We use Firebase for authentication and data storage in Firestore.</p>

                <p className="terms-header"><b>How We Use Your Information</b></p>
                <p className="terms-text">We use the information we collect in various ways, including to:</p>
                <li>Provide, operate, and maintain our app</li>
                <li>Improve, personalize, and expand our app</li>
                <li>Understand and analyze how you use our app</li>
                <li>Develop new products, services, features, and functionality</li>
                <li>Communicate with you, either directly or through one of our partners, 
                    including for customer service, to provide you with updates and other 
                    information relating to the app, and for marketing and promotional purposes</li>
                <li>Find and prevent fraud or misuse</li>

                <p className="terms-header"><b>Log Data</b></p>
                <p className="terms-text">We want to inform you that whenever you visit our Service, 
                we collect information that your browser sends to us that is called Log Data. This Log 
                Data may include information such as your computers Internet Protocol ("IP") address, browser version, 
                pages of our Service that you visit, the time and date of your visit, the time sspent on those pages, and other statistics.</p>

                <p className="terms-header"><b>Cookies</b></p>
                <p className="terms-text">Cookies are files with small amounts of data that is commonly used as an anonymous 
                unique identifier. These are sent to your browser from the website that you visit and are stored on your 
                computers hard drive.</p>
                <p className="terms-text">We reserve the right to use these "cookies" to collect information and to 
                improve our service. You have the option to either accept or refuse these cookies, and know when a 
                cookie is being sent to your computer. If you choose to refuse our cookies, you may not be able to use 
                or access portions of our service.</p>
            </div>
        </div>
    )
}

export default TermsPage;