import React from 'react';

import { FourPage } from './components';
import './app.css';


const NoPage = () => {
    return (
        <div className='App'>
            <div className='gradient__bg'>
                <FourPage />
            </div>
        </div>
    )
 }
  
  export default NoPage