import React from 'react';
import './characters.css';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../config/firebase.js';

import char1 from '../../assets/character1.png';
import char2 from '../../assets/character2.png';
import char3 from '../../assets/character3.png';
import char4 from '../../assets/character4.png';
import char5 from '../../assets/character5.png';
import char6 from '../../assets/character6.png';
import char7 from '../../assets/character7.png';
import char8 from '../../assets/character8.png';
import char9 from '../../assets/character9.png';
import char10 from '../../assets/character10.png';

const UserChoices = require('../../userChoices.js');

const DOMAIN = `${process.env.REACT_APP_API_URL}`;

const ChooseChar = () => {
    UserChoices.rehydrate();

    let navigate = useNavigate();
    
    const goToPreGame = (charId) => {
        UserChoices.setCharacter(charId);
        
        // Update database character if logged in
        if (auth?.currentUser?.uid) {
            console.log("Setting character for: " + auth?.currentUser?.uid);
            updateCharacter(auth?.currentUser?.uid, charId);
        }

        navigate('../Prep');
    }

    const goHome = () => {
        navigate('../');
    }

    // Update database character
    async function updateCharacter(userId, char) {
        fetch(DOMAIN + "/set-user-character", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        mode: "cors",
        body: JSON.stringify({ 
            firebaseId: userId,
            charId: char
        }),
        })
        .then(res => {
            if (!res.ok) {
            throw new Error(`HTTP error! Status: ${res.status}`);
            }
            return res.json();
        })
        .then(response => {
            console.log("Character Set: ", response.character);
            return response.character;
        })
        .catch((e) => {
            console.log("Error: " + e);
        });
    }

    return (
        <div className="select-char">
            <button className="return-button" onClick={goHome}>
                Return Home
            </button>
            <h1>Choose Your Character</h1>
            <div className="choose-char-container">
                
                <div className="chars-container">
                    <div>
                        <div className="character">
                            <img src={char4} alt="interview" onClick={() => goToPreGame("victoria")}/>
                        </div>
                        <p>Victoria Raven</p>
                        <h3>Influential - Leader - Attentive</h3>
                    </div>
                    <div>
                        <div className="character">
                            <img src={char2} alt="negotiator" onClick={() => goToPreGame("ashton")}/>
                        </div>
                        <p>Ashton Lockwood</p>
                        <h3>Charismatic - Steadfast - Convincing</h3>
                    </div>
                    <div>
                        <div className="character">
                            <img src={char9} alt="influence" onClick={() => goToPreGame("amber")}/>
                        </div>
                        <p>Amber Hawthorne</p>
                        <h3>Vibrant - Unyielding - Resolute</h3>
                    </div>

                    <div>
                        <div className="character">
                            <img src={char5} alt="debate" onClick={() => goToPreGame("yasuke")}/>
                        </div>
                        <p>Yasuke Javier</p>
                        <h3>Visionary - Magnetic - Resonant</h3>
                    </div>

                    <div>
                        <div className="character">
                            <img src={char8} alt="persuade" onClick={() => goToPreGame("naomi")}/>
                        </div>
                        <p>Naomi Winters</p>
                        <h3>Observant - Methodical - Resourceful</h3>
                    </div>

                    <div>
                        <div className="character">
                            <img src={char1} alt="salesman" onClick={() => goToPreGame("victor")}/>
                        </div>
                        <p>Victor Gray</p>
                        <h3>Strategic - Articulate - Calculating</h3>
                    </div>

                    <div>
                        <div className="character">
                            <img src={char3} alt="saleswoman" onClick={() => goToPreGame("maya")}/>
                        </div>
                        <p>Maya West</p>
                        <h3>Persuasive - Confident - Sharp</h3>
                    </div>

                    <div>
                        <div className="character">
                            <img src={char7} alt="closer" onClick={() => goToPreGame("xavier")}/>
                        </div>
                        <p>Xavier Pierce</p>
                        <h3>Commanding - Ambitious - Expressive</h3>
                    </div>

                    <div>
                        <div className="character">
                            <img src={char6} alt="inspire" onClick={() => goToPreGame("akira")}/>
                        </div>
                        <p>Akira Akai</p>
                        <h3>Captivating - Inspirational - Engaging</h3>
                    </div>











                    <div>
                        <div className="character">
                            <img src={char10} alt="charisma" onClick={() => goToPreGame("sebastian")}/>
                        </div>
                        <p>Sebastian Beaumont</p>
                        <h3>Adaptive - Precise - Diligent</h3>
                    </div>

                </div>
            </div>
        </div>
        
    );
};

export default ChooseChar;