import React from 'react';
//import Feature from '../../components/feature/Feature';
import './scenarios.css';
import aiCEO1 from '../../assets/aiCEO1.png';

const scenariosData = [
  {
    title: 'Art of Selling',
    text: 'Master the art of persuasion and prospecting. Learn to understand customer needs, effectively communicate value, and close deals with confidence. The art of selling is about fostering trust and turning interactions into successful transactions.',
  },
  {
    title: 'Art of Interviewing',
    text: 'Master the art of presenting yourself effectively. Learn to articulate your strengths and experiences confidently, respond thoughtfully to challenging questions, and engage with interviewers in a meaningful way. Showcase your qualifications, demonstrate your potential, and build rapport that leaves a lasting positive impression.',
  },
  {
    title: 'Art of Negotiation',
    text: 'Master the art of reaching mutually beneficial agreements. Balance assertiveness and empathy, strategy and flexibility. Understand the counterpartys interests and effectively communicate your own to find common ground for successful outcomes.',
  },
  {
    title: 'Art of Debate',
    text: 'Master the art of intellectual discourse. Construct clear, logical arguments and present them persuasively. Understand the strengths and weaknesses of your position and your opponents. Engage in a respectful exchange of ideas and learn to think on your feet.',
  },
  {
    title: 'Customized Scenarios',
    text: 'Create your own scenarios by entering domain-specific information effortlessly. Provide context such as job descriptions, sales products, debate topics, etc. to suit your training needs.',
  }
];

const Scenarios = () => {
  return (
    <div className='scen__format section__padding' id='scenarios'>
        <div className='scen__format-heading'>
          <h1 className='gradient__text'>Repetition produces excellence. Robots can help you get those reps.</h1>
          <img className='ceo' src={aiCEO1} alt='interviewer'/>
          <p><b>Play for Free</b> to View All Scenarios</p>
        </div>
        <div className='scen__format-container'>
          {scenariosData.map((item, index) => (
            <div key={item.title + index} className="arts-container">
              <h2 className="arts-title">{item.title}</h2>
              <div className="gradient-line"></div>
              <p className="arts-text">{item.text}</p>
            </div>
          ))}
        </div>
    </div>
  )
}

export default Scenarios