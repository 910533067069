import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../config/firebase.js';
import SiriWave from "siriwave";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { /*faCog,*/ faHouse, faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import './introgame.css';
import io from 'socket.io-client';
// Only desc imports
import { CarSalesDesc, CarSalesChar, PortStratDesc, PortStratTom } from '../pregame/Prep.jsx'
// Info Imports
import { ColdCallLandscape, ColdCallMassage, ColdCallVeterinary } from '../pregame/Prep.jsx'
import { WallStGMT, WallStEES, WallStFFI } from '../pregame/Prep.jsx'
import { InsuranceLife, InsuranceHealth, InsuranceHome } from '../pregame/Prep.jsx'
import { WolfStNTII, WolfStBGAC, WolfStQLT } from '../pregame/Prep.jsx'
import { BasicIntDev, BasicIntCoord, BasicIntExec } from '../pregame/Prep.jsx'
import { TechIntEng, TechIntSoft, TechIntSci } from '../pregame/Prep.jsx'
import { CreateIntWrite, CreateIntUI, CreateIntSocial } from '../pregame/Prep.jsx'
import { LeadIntCOO, LeadIntCFO, LeadIntCEO } from '../pregame/Prep.jsx'
import { SalaryNegDev, SalaryNegProj, SalaryNegDesign } from '../pregame/Prep.jsx'
import { RealEstateDesc, RealEstateLinda } from '../pregame/Prep.jsx'
import { InvestmentPitchUrban, InvestmentPitchTreat, InvestmentPitchStem } from '../pregame/Prep.jsx'
import { UsPoliticsGreen, UsPoliticsGun, UsPoliticsUBI } from '../pregame/Prep.jsx'

const DOMAIN = `${process.env.REACT_APP_API_URL}`;
const UserChoices = require('../../userChoices.js');


const ColdCallDesc = (
    <div className="desc">
        <h1>Cold Calling</h1>
        <p>As the owner of a Social Media Marketing Agency, your firm specializes in elevating the online presence of small to medium-sized companies, funneling paying clients to your customers.</p>
        <p>Your task is to reach out to potential clients who could benefit from a more strategic approach to their marketing efforts.</p>
        <p>Your immediate objective: lock in a future meeting with the prospect. Spark their interest and get them excited to work with you.</p>
    </div>
);

const ColdCallLandscapeDesc = (
  <div>
    <h3>Landscaping Services</h3>
    <ul className="pros">
        <li>Visual Appeal: Easily contrast before-and-after transformations</li>
        <li>Seasonal: Opportunities for targeted campaigns during peak cycle</li>
        <li>Potential community engagement</li>
    </ul>
    <ul className="cons">
        <li>Saturation: Landscapers might already be utilizing social media effectively</li>
        <li>Seasonal: Slower off-seasons</li>
        <li>Content Variety: Finding diverse content beyond project showcases might be challenging.</li>
    </ul>
  </div>
);

const ColdCallMassageDesc = (
  <div>
    <h3>Massage Therapy Center</h3>
    <ul className="pros">
        <li>Wellness Trend: Self-care is popular</li>
        <li>Repeat Engagement: Regular updates, customer testimonials, and wellness tips to keep followers engaged.</li>
        <li>Emotional Connection: Content can resonate with followers seeking relaxation and stress relief</li>
    </ul>
    <ul className="cons">
        <li>Privacy Concerns: Navigate client privacy sensitively</li>
        <li>Niche: Expanding to a broader audience may prove difficult</li>
        <li>Misconceptions: Avoid misrepresenting benefits or fostering unrealistic expectations.</li>
    </ul>
  </div>
);

const ColdCallVeterinaryDesc = (
  <div>
    <h3>Veterinary Clinics</h3>
    <ul className="pros">
        <li>High Engagement: Pet content is extremely popular</li>
        <li>Emotional Storytelling: Share heartwarming stories of pet care and recovery</li>
        <li>Educational Content: Provide valuable pet health tips, promoting the clinic as a trusted source</li>
    </ul>
    <ul className="cons">
        <li>Sensitivity: Balance educational and promotional content without appearing insensitive to pet health problems</li>
        <li>Diverse Audience Expectations: Cater to the wide range of pet owners</li>
    </ul>
  </div>
)

const WallStDesc = (
  <div className="desc">
    <h1>Wall Street Simulator</h1>
    <p>As a savvy investment broker, you maintain a network of wealthy institutional investors seeking the highest possible risk-adjusted returns. Your commission is earned when they trade through you.</p>
    <p>Your mission is to analyze, pitch, and convince seasoned investors to allocate capital towards these stocks.</p>
    <p>The possible arguments are unlimited - ranging through macro, fundamentals, and technicals.</p>
    <p>Select an investor/stock to pitch.</p>
  </div>
)

const WallStGMTDesc = (
  <div>
    <h3>GlobalMedTech (GMT)</h3>
    <h3>(Technology/Health)</h3>
    <p>GMT is pioneering the integration of artificial intelligence in medical diagnostics. 
        Advanced algorithms analyze medical imaging data, aiding physicians in identifying malignancies 
        at their nascent stages</p>
    <ul className="pros">
        <li>Innovative: At the forefront of medically applied AI - high growth potential</li>
        <li>Core Product: AI Early Tumor Detection - statistically significant results</li>
        <li>Strong pipeline of upcoming products pending approval: Namely detection methods for other cancers and heart conditions</li>
        <li>Robust R&D investments indicate a strong commitment to innovation and positions it well for future breakthroughs</li>
    </ul>
    <ul className="cons">
        <li>Competition: Constant pressure to innovate</li>
        <li>Slow Adoption: AI may be statistically better, but doctors struggle to trust AI diagnosis</li>
        <li>Dependence on Key Clients: Significant revenue is tied to three major hospitals</li>
    </ul>
  </div>
)

const WallStEESDesc = (
  <div>
    <h3>EcoEnergy Solutions (EES)</h3>
      <h3>(Energy)</h3>
      <p>EES is a hybrid provider, selling power directly to the grid through a mix of traditional fossil 
          fuels and renewable sources. EES particularly interested in leveraging its infrastructure to establish
          wind turbines on its properties.</p>
      <ul className="pros">
          <li>Diversified Energy Mix: Oil, Natural Gas, Wind</li>
          <li>Stable Revenue Streams: With oil projects averaging $50/barrel breakeven and secured long-term NatGas contracts, revenue is largely dependable</li>
          <li>Government Support: Elegible for subsidies and tax incentives due to wind projects</li>
      </ul>
      <ul className="cons">
          <li>Transition Infefficiencies: Balancing standard operations with renewable investments can be complex and may lead to sub-optimal operations</li>
          <li>High capital costs are associated with infrastructure-heavy projects</li>
          <li>Commodity Price Fluctuations: Lower Oil/NatGas prices reduces revenues which could impact capital available for wind projects.</li>
      </ul>
  </div>
)

const WallStFFIDesc = (
  <div>
    <h3>Future Foods Int. (FFI)</h3>
    <h3>(Consumer Staples)</h3>
    <p>FFI offers personalized nutrition plans where customers can get their food 
        genetically tailored to their health needs. By analyzing a customer's DNA 
        sample, FFI crafts and ships customized meals optimized for health, taste, and 
        dietary requirements.</p>
    <ul className="pros">
        <li>Well-aligned with the growing global trend toward health-conscious eating and sustainability, with a focus on alternative, healthy food products</li>
        <li>Global Footprint: Global demand is only increasing, FFIs international presence positions it to capitalize and reduce dependency on any single region</li>
        <li>Innovative product development keeps FFI at the cuttting edge of consumer trends</li>
    </ul>
    <ul className="cons">
        <li>Competition: Alternative food sector is becoming increasingly crowded</li>
        <li>Supply Chain Risks: As a global company, FFI may face risks related to international trade tensions, supply chain disruptions, or fluctuations in commodity prices</li>
        <li>Quality Control: With a broad product range and high production volume, maintaining consistent quality is critical. Any quality slips could harm the brand's reputation.</li>
    </ul>
  </div>
)

const InsuranceDesc = (
  <div className="desc">
    <h1>Insurance Sales</h1>
    <p>You are a professional insurance salesman. You earn your commission by calling and closing warm leads.</p>
    <p>Your goal is to convince the prospect to spend a monthly fee for services they hopefully never need to use.</p>
    <p>The economy is tight, and these prospects dont have much discretionary income. Yet, better to pay small sums now rather than be ruined by an accident.</p>
    <p>Select the type of insurance you'd like to sell.</p>
  </div>
)

const InsuranceLifeDesc = (
  <div>
    <h3>Life Insurance</h3>
  </div>
)

const InsuranceHealthDesc = (
  <div>
    <h3>Health Insurance</h3>
  </div>
)

const InsuranceHomeDesc = (
  <div>
    <h3>Home Insurance</h3>
  </div>
)

const WolfStDesc = (
  <div className="desc">
    <h1>Wolf Street Simulator</h1>
    <p>As a shady investment broker, you seek out rich and gullible penny stock buyers with $10k to burn. Your commission is earned when they trade through you.</p>
    <p>These companies have a very small chance of success. You might stand a better chance if you ignore the fundamentals and use manipulative tactics along with some neurolinguistic programming to ensure success.</p>
    <p>Clearly, this scenario is unethical. We hope that you will take these skills and act morally in the real world!</p>
    <p>Select a prospect/stock to pitch.</p>
  </div>
)

const WolfStNTIIDesc = (
  <div>
    <h3>NanoTech Innovations (NTII)</h3>
    <p>Claims to be at the forefront of nanotech. They tout a revolutionary process for creating 
        high-capacity batteries using a proprietary, and entirely secretive, nano-structuring technique.</p>
    <ul className="cons">
        <li>Staff is elusive about providing data or demonstrations</li>
        <li>Unverified, possibly fabricated research</li>
        <li>Operates from a makeshift lab out of a small converted storage unit</li>
    </ul>
  </div>
)

const WolfStBGACDesc = (
  <div>
    <h3>BioGrow AgriTech (BGAC)</h3>
    <p>Boasts a new hydroponic growth system enabled by autonomous roaming robots 
        that can supposedly increase crop yields tenfold.</p>
    <ul className="cons">
        <li>Demonstrations are few and far between, some videos appear faked</li>
        <li>Dire need of funding to continue operations</li>
        <li>Operates from a large unmarked barn in a remote area.</li>
    </ul>
  </div>
)

const WolfStQLTDesc = (
  <div>
    <h3>QuantumLedger Token (QLT)</h3>
    <p>Self-described cutting-edge cryptocurrency that uses purported quantum computing 
        technologies to ensure unparalleled security and transaction speeds. 
    </p>
    <ul className="cons">
        <li>Leadership composed of unknown figures in the crypto space</li>
        <li>Vague and technical jargon-filled whitepapers</li>
        <li>Flashy marketing campaigns, including social media influencer endorsements and announcements of partnerships with obscure tech companies</li>
    </ul>
  </div>
)

const BasicIntDesc = (
  <div className="desc">
    <h1>Basic Interview</h1>
    <p>As a young and hungry jobseeker, you have scored a series of interviews </p>
    <p>Your task is to answer the interviewers questions in a professional and satisfactory manner.</p>
    <p>Select an interviewer. All questions will be generic and unspecific. The idea is to test your general interviewing abilities, not your qualifications for a specific job.</p>
  </div>
)

const BasicIntDevDesc = (
  <div>
    <h3>Business Development Rep.</h3>
    <ul className="pros">
        <li>Interpersonal Skills</li>
        <li>Drive and Enthusiasm</li>
        <li>Adaptibilty</li>
    </ul>
  </div>
)

const BasicIntCoordDesc = (
  <div>
    <h3>Marketing Coordinator</h3>
    <ul className="pros">
        <li>Initiative</li>
        <li>Teamwork</li>
        <li>Unique Thinking</li>
    </ul>
  </div>
)

const BasicIntExecDesc = (
  <div>
    <h3>Executive Assistant</h3>
    <ul className="pros">
        <li>Organization</li>
        <li>Proactive</li>
        <li>Time Management</li>
    </ul>
  </div>
)

const TechIntDesc = (
  <div className="desc">
    <h1>Technical Interview</h1>
    <p>You are a young and hungry technical professional. Hard skills are highly valued in the workplace and these interviews will test your domain-specific knowledge. </p>
    <p>Your task is to answer the interviewers questions in a professional, satisfactory, and accurate manner.</p>
    <p>Select an interviewer. Prepare to answer generalized questions related to the selected profession. The job itself is unspecific.</p>
  </div>
)

const TechIntEngDesc = (
  <div>
    <h3>Engineering</h3>
    <ul className="pros">
        <li><b>Math: </b>calculus, differential equations, statistics</li>
        <li><b>Physics: </b>mechanical, chemical, electrical, material</li>
        <li><b>Software: </b>simulation, circuits, CAD</li>
    </ul>
  </div>
)

const TechIntSoftDesc = (
  <div>
    <h3>Software</h3>
    <ul className="pros">
        <li><b>Programming Languages: </b>Javascript, C++, Python,, etc</li>
        <li><b>SDLC: </b>Software Development Life Cycle</li>
        <li><b>Algorithms: </b>sorting, search, graph</li>
        <li><b>Data Structures: </b>objects, stacks, linked lists</li>
    </ul>
  </div>
)

const TechIntSciDesc = (
  <div>
    <h3>Scientific Research</h3>
    <ul className="pros">
        <li><b>Methodology: </b>scientific method, experimental design, statistical analysis</li>
        <li><b>Laboratory Skills: </b>instrument operation, technique, safety</li>
        <li><b>Technical Writing: </b>clear and concise research papers, proposals, documentation</li>
        <li><b>Critical Thinking: </b>analyze data, pattern recognition</li>
    </ul>
  </div>
)

const CreateIntDesc = (
  <div className="desc">
    <h1>Creative Interview</h1>
    <p>You are a young and hungry creative professional. Truly creative skills are hard to come by. These interviews will test you abilities relative to a few generalized professions. </p>
    <p>Your task is to answer the interviewers questions in a professional and satisfactory manner, proving your capabilities.</p>
    <p>Select an interviewer. Prepare to answer generalized questions related to the selected profession. The job itself is unspecific.</p>
  </div>
)

const CreateIntWriteDesc = (
  <div>
    <h3>Creative Writer</h3>
    <ul className="pros">
        <li><b>Storytelling: </b>craft compelling narratives that engage and retain the audience's attention</li>
        <li><b>Emotionally Intelligent: </b>advantageously tailor various tones and styles to grip your unique audiences</li>
        <li><b>Research Skills: </b>adds authenticity and depth</li>
    </ul>
  </div>
)

const CreateIntUIDesc = (
  <div>
    <h3>UI/UX Design</h3>
    <ul className="pros">
        <li><b>User Psychology: </b>understand how site layout impacts user attitudes and click activity</li>
        <li><b>Prototyping: </b>wireframes, storyboards, user flows</li>
        <li><b>User Research: </b> conduct tests to gather feedback and gauge user needs</li>
        <li><b>Visual Design: </b>effectively leverage color, layout, and typography</li>
    </ul>
  </div>
)

const CreateIntSocialDesc = (
  <div>
    <h3>Social Media Specialist</h3>
    <ul className="pros">
        <li><b>Content Creation: </b>produce engaging content that resonates with the target audience and adheres to the brand</li>
        <li><b>Platforms: </b>knowledge of social media platforms and best practices</li>
        <li><b>Trend Awareness: </b>up to date with the latest social media trends and algorithm changes</li>
        <li><b>Community Engagement: </b>foster a positive network of interaction</li>
    </ul>
  </div>
)

const LeadIntDesc = (
  <div className="desc">
    <h1>Leadership Interview</h1>
    <p>You are a young and experienced professional who has worked your way up the ranks. The ability to lead people both operationally and as a visionary can be incredibly valuable and rewarding. These interviews will test whether you're up to the task. </p>
    <p>Your task is to answer the interviewers questions in a professional and exceedingly qualified manner, proving your capabilities.</p>
    <p>Select an interviewer. Prepare to answer generalized leadership questions related to the selected role. The company is left unspecified because we are more concerned with assessing you leadership qualities.</p>
  </div>
)

const LeadIntCOODesc = (
  <div>
    <h3>Chief Operating Officer</h3>
    <p>A fast-growing tech startup is looking for a COO to streamline operations and support aggressive expansion plans</p>
    <ul className="pros">
        <li>Strategic planning and vision</li>
        <li>Operational efficiency and process improvement</li>
        <li>Leadership and team management</li>
        <li>Adaptability and innovation in a fast-paced industry</li>
        <li>Experience in scaling a business</li>
    </ul>
  </div>
)

const LeadIntCFODesc = (
  <div>
    <h3>Chief Financial Officer</h3>
    <p>A multinational corporation is seeking a CFO to manage its financial strategy and operations, including navigating complex international tax laws and regulations.</p>
    <ul className="pros">
        <li>Financial acumen and expertise in corporate finance</li>
        <li>Expertise with international finance and taxation</li>
        <li>Risk management and compliance</li>
        <li>Strategic thinking in terms of financial planning</li>
        <li>Communication skills, especially in explaining financial concepts</li>
    </ul>
  </div>
)

const LeadIntCEODesc = (
  <div>
    <h3>Chief Executive Officer</h3>
    <p>A well-established manufacturing company is searching for a new CEO to revitalize the brand and lead the company into new markets</p>
    <ul className="pros">
        <li>Visionary leadership and ability to inspire and motivate</li>
        <li>Expertise with corporate restructuring and change management</li>
        <li>Innovative skills to foster market growth</li>
        <li>Alignment with company values and mission</li>
    </ul>
  </div>
)

const SalaryNegDesc = (
  <div className="desc">
    <h1>Salary Negotiation</h1>
    <p>As an employee, you've excelled at your job. Now its time to get what you're worth.</p>
    <p>Communicate your value to your boss. Can you get them to see things from your perspective?</p>
    <p>Choose a job for which you will try to negotiate a higher salary.</p>
  </div>
)

const SalaryNegDevDesc = (
  <div>
    <h3>Software Developer</h3>
    <ul className="pros">
        <li>Your work has directly impacted your startups success and valuation</li>
        <li>AI is a hot sector, and your expertise is not easily replaceable</li>
        <li>You have concrete results to show for your time at the company</li>
    </ul>
    <ul className="cons">
        <li>Startups often have tight salary structures</li>
        <li>Equity is often preferred over cash compensation</li>
    </ul>
  </div>
)

const SalaryNegProjDesc = (
  <div>
    <h3>Project Manager</h3>
    <ul className="pros">
        <li>You have a solid understanding of the company's processes and client base</li>
        <li>Proven ability to manage the company's most lucrative and complex projects</li>
        <li>Strong relationships with key stakeholders and team members</li>
    </ul>
    <ul className="cons">
        <li>Market fluctuations in construction may impact the availability of funds for raises</li>
        <li>Internal policies may cap salary increases, regardless of individual performance</li>
        <li>The company may have a fixed budget cycle for salary reviews</li>
    </ul>
  </div>
)

const SalaryNegDesDesc = (
  <div>
    <h3>Graphic Designer</h3>
    <ul className="pros">
        <li>Your designs have directly contributed to the agency's reputation and client satisfaction</li>
        <li>You've demonstrated versatility, adapting to various clients' needs and styles</li>
        <li>Established as a reliable and innovative asset to the creative team</li>
    </ul>
    <ul className="cons">
        <li>Creative roles can often see slower salary progression compared to sales or technical positions</li>
        <li>The agency may prioritize client acquisition investments over existing staff raises</li>
    </ul>
  </div>
)

const InvestmentPitchDesc = (
  <div className="desc">
    <h1>Investment Pitch</h1>
    <p>You've finally taken that big leap to start your business! After taking it as far as possible, you find yourself in need of funding.</p>
    <p>Luckily, a wealthy financier is interested in your project!</p>
    <p>Your goal is to secure favorable terms on a financing deal. You can structure this deal as a combination of debt and equity.</p>
    <p>Select a business to pitch to the investor.</p>
  </div>
)

const InvestmentPitchUrbanDesc = (
  <div>
    <h3>Urban Gardening</h3>
    <p>Kits that allow city dwellers to grow their own food in small spaces. The kits include seeds for various vegetables and herbs, 
        soil, and a modular container system that can fit in tight spaces like balconies 
        and windowsills. Your unique selling point is the integration of a smart app that 
        guides users through the planting process, offers care reminders, and provides recipes for their harvest.</p>
    <ul className="pros">
        <li>Rising trend in urban self-sustainability and organic eating</li>
        <li>Testimonials demonstrating the product's success and user satisfaction</li>
        <li>Marketing strategy focusing on partnerships with eco-conscious influencers and brands</li>
    </ul>
    <ul className="cons">
        <li>You need <b>$200k</b> to scale up production, including expanding the range of plants available and possible entry into the educational sector as a tool for schools</li>
    </ul>
  </div>
)

const InvestmentPitchTreatDesc = (
  <div>
      <h3>Gourmet Pet Treats</h3>
    <p>Made from high-quality, locally sourced ingredients, your treats cater 
        to pets with special dietary needs and preferences, offering grain-free, 
        gluten-free, and hypoallergenic options. All while offering superior taste!</p>
    <ul className="pros">
        <li>Increasing expenditure on pet care and the consumer preference for health-focused products</li>
        <li>Growing specialty pet food market and how your product is superior to competitors</li>
        <li>Intensive product development, including veterinary nutritionist consultations and safety testing</li>
        <li>Working distribution strategy through boutique pet stores and online direct-to-consumer sales channels</li>
    </ul>
    <ul className="cons">
        <li>You need <b>$200k</b> to scale up production, including brand expansion and marketing for a new subscription service</li>
    </ul>
  </div>
)

const InvestmentPitchStemDesc = (
  <div>
    <h3>STEM Toy</h3>
    <p>Children's toy that combines physical play with digital interactivity 
        to teach basic principles of science, technology, engineering, and math. 
        The toy is designed to be both entertaining and educational, promoting 
        problem-solving skills and creativity. It's accompanied by an app that provides 
        a variety of learning modules and tracks the child's progress.</p>
    <ul className="pros">
        <h3>Features</h3>
        <li><b>Building Structures: </b>guided tutorials to build models, such as bridges or towers, learning about architectural principles and material strength</li>
        <li><b>Creating Machines: </b>by constructing simple machines like windmills or cars, kids explore mechanics and energy conversion</li>
        <li><b>Circuit Integration: </b>Adding lights, sounds, or movement with electronic components introduces basic electrical engineering concepts.</li>
        <li><b>Programming Basics: </b>A companion app allows children to program the behavior of their creations, teaching them fundamental coding logic and problem-solving skills</li>
    </ul>
    <ul className="pros">
        <li>Growing market demand for toys that support learning in these areas</li>
        <li>Differentiates from similar toys through its interactivity and adaptability to learning styles and paces.</li>
        <li>Early testing phases showcase effectiveness and engagement</li>
    </ul>
    <ul className="cons">
        <li>You need <b>$200k</b> to scale up production and meet demand</li>
    </ul>
  </div>
)

const UsPoliticsDesc = (
  <div className="desc">
    <h1>US Politics</h1>
    <p>When legislation is "on the floor" this means it is up for debate. Legislators speak for or agains the bill and respond directly to each others arguments in real time.</p>
    <p>Unlike real politics, your score will not be guided</p>
    <p>Choose a piece of legislation to argue for/against.</p>
  </div>
)

const UsPoliticsGreenDesc = (
  <div>
    <h3>Green Initiative Act</h3>
    <p>Aimed at combating climate change through a series of regulatory measures and subsidies.</p>
    <ul>
        <li>Nationwide cap on CO2 emissions</li>
        <li>Subsidizing the development and deployment of solar, wind, and hydroelectric power</li>
        <li>Tax incentives for businesses/homeowners for clean energy improvements</li>
        <li>Mandating fossil fuel phase-out and EV adoption by 2035</li>
        <li>Investing in green infrastructure jobs</li>
    </ul>
  </div>
)

const UsPoliticsGunDesc = (
  <div>
    <h3>Gun Control Act</h3>
    <p>Introduces stringent firearm regulations to improve public safety and reduce gun violence</p>
    <ul>
        <li>Universal background checks for all gun sales</li>
        <li>A ban on the sale and manufacture of high-capacity magazines and assault-style weapons</li>
        <li>A national gun registry for all firearms sales and transfers</li>
        <li>Red Flag laws that allow law enforcement to temporarily remove firearms from individuals deemed a threat to themselves or others</li>
    </ul>
  </div>
)

const UsPoliticsUBIDesc = (
  <div>
      <h3>Universal Basic Income Act</h3>
    <p>Social welfare program designed to provide financial stability and stimulate economic growth. This legislation would establish a monthly stipend to all adult citizens.</p>
    <ul>
        <li>Reducing poverty and income inequality by ensuring a minimum level of income</li>
        <li>Replacing a complex array of social welfare programs with a simplified, direct form of financial assistance</li>
        <li>Alleviating economic distress caused by automation and loss of traditional jobs</li>
        <li>Simplifying the tax system and reducing administrative costs associated with welfare programs</li>
    </ul>
  </div>
)


const IntroGame = () => {
  
  UserChoices.rehydrate();
  //UserChoices.printUserData();

  let navigate = useNavigate();
  
  //const UserChoicesRef = useRef(UserChoices);

  const audioChunks = useRef([]);
  const audioElement = useRef(null);

  const scenario = useRef(UserChoices.scenario);
  //console.log("Intro Scenario: " + scenario.current);
  const character = useRef(UserChoices.character);
  //console.log("Intro Character: " + character.current);
  const difficulty = useRef(UserChoices.difficulty);
  //console.log("Intro Difficulty: " + difficulty.current);
  const favor = useRef(UserChoices.foragainst);
  //console.log("Intro Favor: " +  favor.current);

  //Custom Refs
  // Sales
  const opponent = useRef(UserChoices.opponent);
  //console.log("Intro Opponent: " +  opponent.current);
  const product = useRef(UserChoices.customsellProduct);
  //console.log("Intro Custom Sell: " +  product.current);
  const productDesc = useRef(UserChoices.customsellDesc);
  //console.log("Intro Custom Sell Desc: " +  priductDesc.current);
  const context = useRef(UserChoices.customsellContext);
  //console.log("Intro Custom Sell Context: " +  context.current);
  const traits = useRef(UserChoices.traits);
  //console.log("Intro Custom Sell Traits: " +  traits.current);
  //Interviews
  const title = useRef(UserChoices.customintTitle);
  //console.log("Intro Title: " +  title.current);
  const company = useRef(UserChoices.customintCompany);
  //console.log("Intro Company: " +  company.current);
  const jobdesc = useRef(UserChoices.customintDesc);
  //console.log("Intro JobDesc: " +  jobdesc.current);
  const negGoal = useRef(UserChoices.customnegGoal);
  //console.log("Intro Goal: " +  negGoal.current);
  const counterGoal = useRef(UserChoices.customnegCounter);
  //console.log("Intro Counter: " +  counterGoal.current);
  const debatePos = useRef(UserChoices.customdebatePos);
  //console.log("Intro Debate: " +  debatePos.current);

  const avatarClass = "avatar user-avatar " + character.current;
  const aiAvatarClass = "avatar ai-avatar " + scenario.current;

  const isUserTurn = useRef(true);
  const [isRecording, setIsRecording] = useState(false);
  const [isAiSpeaking, setIsAiSpeaking] = useState(true);
  const userSiriWaveContainer = useRef(null);
  const aiSiriWaveContainer = useRef(null);
  const mediaRecorder = useRef(null);
  const [userSiriWave, setUserSiriWave] = useState(null);
  const [aiSiriWave, setAiSiriWave] = useState(null);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [isWinLosePopupVisible, setIsWinLosePopupVisible] = useState(false);
  const [winLoseMessage, setWinLoseMessage] = useState("");

  const fullConvo = useRef({});
  const turnCount = useRef(0);

  // User and AI Transcriptions of speech
  const [transcription, setTranscription] = useState("");
  const [aiTranscription, setAITranscription] = useState("");
  
  // AI Reasoning for grade
  const [aiReason, setAIReason] = useState("");
  const [aiReasonClass, setAIReasonClass] = useState("");
  const [triggerReasonAnimation, setTriggerReasonAnimation] = useState(false);

  // AI Suggestion for proceeding
  const [aiSuggestion, setAISuggestion] = useState("");
  const [aiSuggestionClass, setAISuggestionClass] = useState("");
  const [triggerSuggestionAnimation, setTriggerSuggestionAnimation] = useState(false);

  // AI Feeling about recent user response
  const [aiFeeling, setAIFeeling] = useState("");
  const [aiFeelClass, setAIFeelClass] = useState("");
  const [triggerFeelAnimation, setTriggerFeelAnimation] = useState(false);

  // Timer
  const [timerVisible, setTimerVisible] = useState(false);
  const countdownInterval = useRef(null);

  // Microphone
  const isClickable = useRef(true);
  const [startSpeakVisible, setStartSpeakVisible] = useState(true);

  // Transcription logistics
  const firstChunk = useRef(true);
  const chunkQueue = useRef([]);
  const audioBufferMap = useRef(new Map());
  const currentSentence = useRef(1);
  const firstAudio = useRef(true);

  const isInitialMount = useRef(true);
  const clientSocket = useRef(null);
  const firstClick = useRef(true);
  const localStream = useRef(null);

  // Progress Bar
  const progress = useRef(0);
  const [progressClass, setProgressClass] = useState("");
  const [triggerProgressAnimation, setTriggerProgressAnimation] = useState(false);
  const [triggerRegressAnimation, setTriggerRegressAnimation] = useState(false);

  // Force Render
  const renderTrigger = useRef(() => {});
  const [key, setKey] = useState(false);
  renderTrigger.current = () => {
    setKey(!key);
  };

  useEffect(() => {
    //Only set up microphone on first click
    const setUpMic = () => {
      //Create Socket Connection
      if (!clientSocket.current || !clientSocket.current.connected) {
        //console.log('Setting up socket: ' + clientSocket.current)
        clientSocket.current = io.connect(process.env.REACT_APP_API_URL);

        clientSocket.current.on('connect', () => {
          console.log('Connected to the server: ' + clientSocket.current.id);
          // Concat Scenario and difficulty to get the correct scenario
          
          if (scenario.current.substring(0, 10) === 'uspolitics') {
            clientSocket.current.emit('favor', favor.current);
            clientSocket.current.emit('scenario', scenario.current + difficulty.current);
          } else if (scenario.current.substring(0, 10) === 'customsell'){
            if (difficulty.current === 'Easy') {
              clientSocket.current.emit('customsell', 'You are ' + opponent.current + 
                                      ', an easygoing prospective buyer of ' + product.current + 
                                      ' in context ' + context.current + '. You are highly susceptible to sales tactics. ' + 
                                      'Briefly (concisely) respond human-like to the salesperson, unaware of the product until its mentioned. Express interest and raise a new objection. ' + 
                                      'Avoid highly technical language. Be friendly and polite. Stay in character as a prospect who is ' + traits.current + '. ' + 
                                      'When appropriate, agree to buy. Product/service description: ' + productDesc.current);
            } else if (difficulty.current === 'Medium') {
              clientSocket.current.emit('customsell', 'You are ' + opponent.current + 
                                        ', a realistic prospective buyer of ' + product.current + 
                                        ' in context ' + context.current + '. You are susceptible to sales tactics. ' + 
                                        'Briefly (concisely) respond human-like to the salesperson. Express limited interest and raise a new objection. ' + 
                                        'Avoid highly technical language. Be skeptical. Stay in character as a prospect who is ' + traits.current + '. ' + 
                                        'When appropriate, agree to buy. Product/service description: ' + productDesc.current);
            } else if (difficulty.current === 'Hard') {
              clientSocket.current.emit('customsell', 'You are ' + opponent.current + 
                                        ', a difficult prospective buyer of ' + product.current + 
                                        ' in context ' + context.current + '. You are highly defensive to sales tactics. ' + 
                                        'Briefly (concisely) respond human-like to the salesperson. Express tentative interest and raise new objections and outright rejections. ' + 
                                        'Be skeptical and rude. Stay in character as a prospect who is ' + traits.current + '. ' + 
                                        'When appropriate, agree to buy. Product/service description: ' + productDesc.current);
            }
            
          } else if (scenario.current.substring(0, 9) === 'customint') {
            if (difficulty.current === 'Easy') {
              clientSocket.current.emit('customint', 'You are ' + opponent.current + 
                                        ', an easygoing job interviewer. Conduct an interview for a ' + title.current + 
                                        ' job for your company ' + company.current + '. ' +
                                        'Briefly (concisely) ask one new easy interview question at a time to assess whether the candidate is a good fit for the role. ' + 
                                        'Avoid highly technical language. Be friendly, polite, and maintain a positive attitude towards the prospect. ' + 
                                        'Stay in human-like character as an interviewer who is ' + traits.current + '. ' + 
                                        'If appropriate, offer the job. Job description: ' + jobdesc.current);
            } else if (difficulty.current === 'Medium') {
              clientSocket.current.emit('customint', 'You are ' + opponent.current + 
                                        ', a job interviewer. Conduct an interview for a ' + title.current + 
                                        ' job for your company ' + company.current + '. ' +
                                        'Briefly (concisely) ask one new intermediate interview question at a time to assess whether the candidate is a good fit for the role. ' + 
                                        'Maintain a neutral attitude towards the prospect. Stay in human-like character as an interviewer who is ' + traits.current + '. ' + 
                                        'If appropriate, offer the job. Job description: ' + jobdesc.current);
            } else if (difficulty.current === 'Hard') {
              clientSocket.current.emit('customint', 'You are ' + opponent.current + 
                                        ', a job interviewer. Conduct an interview for a ' + title.current + 
                                        ' job for your company ' + company.current + '. ' +
                                        'Briefly (concisely) ask one new hard interview question at a time to assess whether the candidate is a good fit for the role. ' + 
                                        'Be a bit rude, but maintain a neutral attitude towards the prospect. Stay in human-like character as an interviewer who is ' + traits.current + '. ' + 
                                        'If appropriate, offer the job. Job description: ' + jobdesc.current);
            }
          } else if (scenario.current.substring(0, 9) === 'customneg') {
            if (difficulty.current === 'Easy') {
              clientSocket.current.emit('customneg', 'You are ' + opponent.current + 
                                        ', an easygoing negotiator. Conduct a negotiation and argue for your interests: ' + counterGoal.current + 
                                        'The counterparties (users) interests are: ' + negGoal.current +
                                        'Briefly (concisely) respond with a new argument favoring your interests. ' + 
                                        'Avoid highly technical language. Be friendly and polite. ' + 
                                        'Stay in human-like character as a negotiator who is ' + traits.current + '. ' + 
                                        'When appropriate, accept mutually agreed terms.');
            } else if (difficulty.current === 'Medium') {
              clientSocket.current.emit('customneg', 'You are ' + opponent.current + 
                                        ', a professional negotiator. Conduct a negotiation and argue for your interests: ' + counterGoal.current + 
                                        'The counterparties (users) interests are: ' + negGoal.current +
                                        'Briefly (concisely) respond with a new thoughtful argument favoring your interests. ' + 
                                        'Avoid highly technical language. ' + 
                                        'Stay in human-like character as a negotiator who is ' + traits.current + '. ' + 
                                        'When appropriate, accept mutually agreed terms.');
            } else if (difficulty.current === 'Hard') {
              clientSocket.current.emit('customneg', 'You are ' + opponent.current + 
                                        ', an tough professional negotiator. Conduct a negotiation and argue for your interests: ' + counterGoal.current + 
                                        'The counterparties (users) interests are: ' + negGoal.current +
                                        'Briefly (concisely) respond with a new convincing argument favoring your interests. ' + 
                                        'Be rude and difficult. ' + 
                                        'Stay in human-like character as a negotiator who is ' + traits.current + '. ' + 
                                        'When appropriate, accept mutually agreed terms.');
            }
          } else if (scenario.current.substring(0, 12) === 'customdebate') {
            if (difficulty.current === 'Easy') {
              clientSocket.current.emit('customdebate', 'You are ' + opponent.current + 
                                        ', an easygoing debater. Participate in a debate and argue the opposite of the following statement: ' + debatePos.current + 
                                        'Briefly (concisely) respond with a new fallacious argument for your position and raise a new objection to the opponents argument. ' + 
                                        'Avoid highly technical language. Be friendly and polite. Appeal to logos, pathos, and ethos.' + 
                                        'Stay in human-like character as a debater who is ' + traits.current + '.');
            } else if (difficulty.current === 'Medium') {
              clientSocket.current.emit('customdebate', 'You are ' + opponent.current + 
                                        ', a debater. Participate in a debate and argue the opposite of the following statement: ' + debatePos.current + 
                                        'Briefly (concisely) respond with a new cogent argument for your position and raise a new objection to the opponents argument. ' + 
                                        'Avoid highly technical language. Be friendly and polite. Appeal to logos, pathos, and ethos.' + 
                                        'Stay in human-like character as a smart debater who is ' + traits.current + '.');
            } else if (difficulty.current === 'Hard') {
              clientSocket.current.emit('customdebate', 'You are ' + opponent.current + 
                                        ', a difficult debater. Participate in a debate and argue the opposite of the following statement: ' + debatePos.current + 
                                        'Briefly (concisely) respond with a new convincing argument for your position and raise a new objection to the opponents argument. ' + 
                                        'Be rude and snarky. Appeal to logos, pathos, and ethos.' + 
                                        'Stay in human-like character as a debater who is ' + traits.current + '.');
            }
          } else {
            clientSocket.current.emit('scenario', scenario.current + difficulty.current);
          }
          
        });

        clientSocket.current.on("no_audio", (msg) => {
          setTranscription(msg);
          alert("No detected audio, please check your microphone and try again.");
        });

        clientSocket.current.on("connect_error", (err) => {
          // the reason of the error, for example "xhr poll error"
          console.log(err.message);
        
          // some additional description, for example the status code of the initial HTTP response
          console.log(err.description);
        
          // some additional context, for example the XMLHttpRequest object
          console.log(err.context);
        });

        clientSocket.current.on("disconnect", (msg) => {
          // the reason of the error, for example "xhr poll error"
          //console.log('Disconnect: ' + msg);
          clientSocket.current.removeAllListeners();
          clientSocket.current = null;
        });

        clientSocket.current.on("disconnecting", (msg) => {
          // the reason of the error, for example "xhr poll error"
          console.log('Disconnecting: ' + msg);
        });

        // Retrieve reasoning for grade
        clientSocket.current.on('reasoning', (reason) => {
          //console.log("Reason received: " + reason);
          setTriggerReasonAnimation(true);
          setAIReason(reason);
        });

        // Retrieve suggestions for proceeding
        clientSocket.current.on('suggestion', (suggestion) => {
          //console.log("Feeling received: " + suggestion);
          setTriggerSuggestionAnimation(true);
          setAISuggestion(suggestion);
        });

        // Retrieve AI feeling
        clientSocket.current.on('feeling', (feel) => {
          //console.log("Feeling received: " + feel);
          setTriggerFeelAnimation(true);
          setAIFeeling(feel);
        });

        // Win condition met
        clientSocket.current.on('winner', (win) => {
          switch (win) {
            case 'sales':
              setWinLoseMessage("You have successfully closed the sale!");
              break;
            case 'interview':
              setWinLoseMessage("You have successfully received a job offer!");
              break;
            case 'negotiation':
              setWinLoseMessage("You have successfully negotiated a deal!");
              break;
            case 'debate':
              setWinLoseMessage("You have successfully won the debate!");
              break;
            default:
              console.log("WINNER: " + win);
          }
          
          addWinId(auth?.currentUser?.uid, scenario.current + difficulty.current);
          setIsWinLosePopupVisible(true);
        });

        // Lose condition met
        clientSocket.current.on('loser', (lose) => {
          switch (lose) {
            case 'sales':
              setWinLoseMessage("You have failed to close the sale!");
              break;
            case 'interview':
              setWinLoseMessage("You have failed to receive a job offer!");
              break;
            case 'negotiation':
              setWinLoseMessage("You have failed to negotiate a deal!");
              break;
            case 'debate':
              setWinLoseMessage("You have lost the debate!");
              break;
            default:
              console.log("LOSER: " + lose);
          }
          
          setIsWinLosePopupVisible(true);
        });
        
        //Retrieve AI transcription
        clientSocket.current.on('chunk', (chunk) => {
          const chunkContent = chunk.choices[0]?.delta?.content || '';
          chunkQueue.current.push(chunkContent);

          if (firstChunk.current) {
            processNextChunk();
            firstChunk.current = false;
          }
        });
        
        clientSocket.current.on('accumulateBuffers', (bufferQ, sentenceId) => {
          audioBufferMap.current.set(sentenceId, bufferQ);
          if (firstAudio.current) {
            play11();
            firstAudio.current = false;
          }
        })

        function play11() {
          //console.log("PLAY BUFFERQ");

          function bufferToBlob(buffer, mimeType) {
            return new Blob([buffer], { type: mimeType });
          }

          function createAudioURL(blob) {
            return URL.createObjectURL(blob);
          }

          function playAudioFromBlob(blob) {
            const audioURL = createAudioURL(blob);
            const audio = new Audio(audioURL);
            audio.play().catch(error => console.error("Error playing audio:", error));

            audio.onended = () => {
              currentSentence.current++;
              if (audioBufferMap.current.has(currentSentence.current)) {
                play11();
              } else {
                //console.log("Last Audio");
                firstAudio.current = true;
                isUserTurn.current = true;
                isClickable.current = true;
                renderTrigger.current();
              }
            }
          }

          if (audioBufferMap.current.has(currentSentence.current)) {
            const audioBlob = bufferToBlob(audioBufferMap.current.get(currentSentence.current), 'audio/mpeg');
            playAudioFromBlob(audioBlob);
          } else {
            console.log("Trying to find current sentence: " + currentSentence.current);
            setTimeout(play11, 1000);
          }
        }

        const processNextChunk = () => {
          if (chunkQueue.current.length > 0) {
              const nextChunk = chunkQueue.current.shift(); // Dequeue the next chunk
              typeEffect(nextChunk);
          } else {
            firstChunk.current = true;
          }
        };
      
        const typeEffect = (text) => {
          let i = 0;
          const intervalId = setInterval(() => {
              if (i < text.length) {
                  setAITranscription(prevTranscription => prevTranscription + text[i]);
                  i++;
              } else {
                  clearInterval(intervalId);
                  processNextChunk(); // Process the next chunk in the queue
              }
          }, 40); // Adjust the speed as necessary
        };

      }

      

      const handleDataAvailable = (event) => {
        audioChunks.current.push(event.data);

        if (clientSocket.current !== null && clientSocket.current.connected) {
          //console.log('Emitting audio data size: ' + event.data.size);
          clientSocket.current.emit('audioData', event.data);
        }
      };
      
      if (mediaRecorder.current === null || mediaRecorder.current === '') {
        //console.log('Setting User Media');
        navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
          
          localStream.current = stream;
    
          // Stream cannot be changed without creating new instance
          mediaRecorder.current = new MediaRecorder(localStream.current, { mimeType: 'audio/webm' });
    
          mediaRecorder.current.addEventListener('dataavailable', handleDataAvailable);
          
          mediaRecorder.current.start(250);
  
        });
      } else {
        console.log('Media Recorder exists');
      }

  
      clientSocket.current.on("transcript", (transcript) => {
        if ( transcript !== null && transcript !== '') {
          setTranscription(prevTranscription => prevTranscription + " " + transcript);
        }
      })

      clientSocket.current.on("score", (score) => {
        
        if (score > progress.current) {
          setTriggerProgressAnimation(true);
          
        } else if (score < progress.current) {
          setTriggerRegressAnimation(true);
        }

        //console.log("SCORE: " + score);
        progress.current = score;
      })
    };

    // Add 1 to convo counts
    async function plusOneConvo(userId) {
      fetch(DOMAIN + "/add-one-convo", {
      method: "POST",
      headers: {
          "Content-Type": "application/json",
      },
      mode: "cors",
      body: JSON.stringify({ 
          firebaseId: userId
      }),
      })
      .then(res => {
          if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
          }
          return res.json();
      })
      .then(response => {
          //console.log("Convo Count Set: ", response.NewConvoCount);
          return response.NewConvoCount;
      })
      .catch((e) => {
          console.log("Error: " + e);
      });
    }


    
    // Don't start recording on mount
    if (isInitialMount.current) {
      isInitialMount.current = false;

    } else { // Now useEffect is only called when isRecording changes
      if (!auth?.currentUser?.uid) {
        alert("Please sign in to continue.");
      } else {
        if (isRecording) {
          //console.log('User Logic')
          //console.log('First Click: ' + firstClick.current);

          if (firstClick.current) {
            setStartSpeakVisible(false);
            setUpMic();
            plusOneConvo(auth?.currentUser?.uid);
            firstClick.current = false;
          }

          
          
          if (mediaRecorder.current) {
            //console.log('Media Recorder State: ' + mediaRecorder.current.state);
            mediaRecorder.current.resume();
          }  else {
            //console.log('Media Recorder non-existent');
          }

          setTranscription('');
          clientSocket.current.emit('turn-switch', true);

        } else {
          // AI LOGIC
          //console.log('AI Logic')

          
          setAITranscription('');

          if (mediaRecorder.current) {
            mediaRecorder.current.pause();

            //console.log('Audio Chunks: ' + JSON.stringify(audioChunks.current, null, 2));

            let audioBlob = new Blob(audioChunks.current, { type: 'audio/wav' });
            let audUrl = URL.createObjectURL(audioBlob);
            audioElement.current = document.querySelector('.audio-playback')
            if (audioElement.current.src) {
              URL.revokeObjectURL(audioElement.current.src);
            }
            
            audioElement.current.src = audUrl;

          } else {
            //console.log('Media Recorder non-existent');
          }
        
          
          clientSocket.current.emit('turn-switch', false);

        }
      }
    }
    
    return () => {
      //console.log('Unmount Cleanup');

    }
    
  }, [isRecording])



  // ANIMATIONS

  // Reason
  useEffect(() => {
    if (triggerReasonAnimation) {
      setAIReasonClass('ai-reason-animation');

      const timer = setTimeout(() => {
        setAIReasonClass('');
        //setTriggerReasonAnimation(false);
      }, 2500); // Adjust the duration to match your animation

      return () => clearTimeout(timer);
    }
  }, [triggerReasonAnimation]);

  // Suggestion
  useEffect(() => {
    if (triggerSuggestionAnimation) {
      setAISuggestionClass('ai-suggestion-animation');

      const timer = setTimeout(() => {
        setAISuggestionClass('');
        //setTriggerSuggestionAnimation(false);
      }, 2500); // Adjust the duration to match your animation

      return () => clearTimeout(timer);
    }
  }, [triggerSuggestionAnimation]);

  // Feeling
  useEffect(() => {
    if (triggerFeelAnimation) {
      setAIFeelClass('ai-feeling-animation');

      const timer = setTimeout(() => {
        setAIFeelClass('');
        //setTriggerFeelAnimation(false);
      }, 2500); // Adjust the duration to match your animation

      return () => clearTimeout(timer);
    }
  }, [triggerFeelAnimation]);

  useEffect(() => {
    if (triggerProgressAnimation) {
      setProgressClass('progress-bar-increase');

      const timer = setTimeout(() => {
        setProgressClass('');
        setTriggerProgressAnimation(false);
      }, 3000); // Adjust the duration to match your animation

      return () => clearTimeout(timer);
    } else if (triggerRegressAnimation) {
      setProgressClass('progress-bar-decrease');

      const timer = setTimeout(() => {
        setProgressClass('');
        setTriggerRegressAnimation(false);
      }, 3000); // Adjust the duration to match your animation

      return () => clearTimeout(timer);
    }
  }, [triggerProgressAnimation, triggerRegressAnimation]);
  


//Cleanup on unmount
  useEffect(() => {

    //fullConvo.current[`Audio`] = audioChunks.current;
    const convo = fullConvo.current;

    return () => {
      //console.log('Unmount Cleanup');
      
      // Save convo to db
      if (Object.keys(convo).length !== 0) {
        saveConvo(auth?.currentUser?.uid, convo);
      }
      

      if (mediaRecorder.current && mediaRecorder.current.state !== 'inactive') {
        //console.log("Stopping media on unmount");
        mediaRecorder.current.stop();
        //mediaRecorder.current.removeEventListener('dataavailable', handleDataAvailable);
      }

      if (localStream.current) {
        //console.log("Stopping local stream on unmount");
        localStream.current.getTracks().forEach(track => track.stop());
      }

      localStream.current = null;

      //Clean socket on unmount
      if (clientSocket.current !== null) {
        clientSocket.current.off("transcript");
        //console.log('Disconnecting socket on unmount');
        clientSocket.current.disconnect();
      }

      if (countdownInterval.current) {
        clearInterval(countdownInterval.current);
      }

      isInitialMount.current = true;
      firstClick.current = true;
    };
  }, []);


  

  //Render on load and cleanup on unload
  useEffect(() => {

    // Initialize the user SiriWave instance
    if (userSiriWaveContainer.current && !userSiriWave) {
      const userInstance = new SiriWave({
        container: userSiriWaveContainer.current,
        style: 'ios',
        speed: 0.1,
        cover: true,
        // Additional user SiriWave options...
      });
      setUserSiriWave(userInstance);
      userInstance.start();
    }

    // Initialize the AI SiriWave instance
    if (aiSiriWaveContainer.current && !aiSiriWave) {
      const aiInstance = new SiriWave({
        container: aiSiriWaveContainer.current,
        style: 'ios',
        speed: 0.1,
        cover: true,
        // Additional AI SiriWave options...
      });
      setAiSiriWave(aiInstance);
      aiInstance.start();
    }
    
    // Start/stop the user SiriWave based on isRecording
    if (userSiriWave) {
      if (isRecording) {
        userSiriWave.start();
      } else {
        userSiriWave.stop();
      }
    }

    // Start/stop the AI SiriWave based on isAiSpeaking
    if (aiSiriWave) {
      if (isAiSpeaking) {
        aiSiriWave.start();
      } else {
        aiSiriWave.stop();
      }
    }
      
    // Clean up on unmount
    return () => {
      if (userSiriWave) userSiriWave.stop();
      if (aiSiriWave) aiSiriWave.stop();
    }
  }, [isRecording, isAiSpeaking, userSiriWave, aiSiriWave]);


  function ProgressBar({ progress }) {
    return (
        <div className="progress-bar-background">
            <div 
                className={`progress-bar-fill ${progressClass}`}
                style={{ width: `${progress}%` }}
            >
            </div>
        </div>
    );
  }

  // INFO POPUP
  function Popup() {
    let descElement;
    let charElement;
    let descElement2;
    //console.log("SCENARIO: " + UserChoices.scenario);

    switch (UserChoices.scenario) {
      case "musclecarsales":
        descElement = CarSalesDesc;
        charElement = CarSalesChar;
        break;
      case "coldcalllandscape":
        descElement = ColdCallDesc;
        charElement = ColdCallLandscape;
        descElement2 = ColdCallLandscapeDesc;
        break;
      case "coldcallmassage":
        descElement = ColdCallDesc;
        charElement = ColdCallMassage;
        descElement2 = ColdCallMassageDesc;
        break;
      case "coldcallvet":
        descElement = ColdCallDesc;
        charElement = ColdCallVeterinary;
        descElement2 = ColdCallVeterinaryDesc;
        break;
      case "wallstgmt":
        descElement = WallStDesc;
        charElement = WallStGMT;
        descElement2 = WallStGMTDesc;
        break;
      case "wallstees":
        descElement = WallStDesc;
        charElement = WallStEES;
        descElement2 = WallStEESDesc;
        break;
      case "wallstffi":
        descElement = WallStDesc;
        charElement = WallStFFI;
        descElement2 = WallStFFIDesc;
        break;
      case "insurancelife":
        descElement = InsuranceDesc;
        charElement = InsuranceLife;
        descElement2 = InsuranceLifeDesc;
        break;
      case "insurancehealth":
        descElement = InsuranceDesc;
        charElement = InsuranceHealth;
        descElement2 = InsuranceHealthDesc;
        break;
      case "insurancehome":
        descElement = InsuranceDesc;
        charElement = InsuranceHome;
        descElement2 = InsuranceHomeDesc;
        break;
      case "wolfstntii":
        descElement = WolfStDesc;
        charElement = WolfStNTII;
        descElement2 = WolfStNTIIDesc;
        break;
      case "wolfstbgac":
        descElement = WolfStDesc;
        charElement = WolfStBGAC;
        descElement2 = WolfStBGACDesc;
        break;
      case "wolfstqlt":
        descElement = WolfStDesc;
        charElement = WolfStQLT;
        descElement2 = WolfStQLTDesc;
        break;
      case "portstrattom":
        descElement = PortStratDesc;
        charElement = PortStratTom;
        //descElement2 = "";
        break;
      case "basicintdev":
        descElement = BasicIntDesc;
        charElement = BasicIntDev;
        descElement2 = BasicIntDevDesc;
        break;
      case "basicintcoord":
        descElement = BasicIntDesc;
        charElement = BasicIntCoord;
        descElement2 = BasicIntCoordDesc;
        break;
      case "basicintexec":
        descElement = BasicIntDesc;
        charElement = BasicIntExec;
        descElement2 = BasicIntExecDesc;
        break;
      case "techinteng":
        descElement = TechIntDesc;
        charElement = TechIntEng;
        descElement2 = TechIntEngDesc;
        break;
      case "techintsoft":
        descElement = TechIntDesc;
        charElement = TechIntSoft;
        descElement2 = TechIntSoftDesc;
        break;
      case "techintsci":
        descElement = TechIntDesc;
        charElement = TechIntSci;
        descElement2 = TechIntSciDesc;
        break;
      case "createintwrite":
        descElement = CreateIntDesc;
        charElement = CreateIntWrite;
        descElement2 = CreateIntWriteDesc;
        break;
      case "createintui":
        descElement = CreateIntDesc;
        charElement = CreateIntUI;
        descElement2 = CreateIntUIDesc;
        break;
      case "createintsocial":
        descElement = CreateIntDesc;
        charElement = CreateIntSocial;
        descElement2 = CreateIntSocialDesc;
        break;
      case "leadintcoo":
        descElement = LeadIntDesc;
        charElement = LeadIntCOO;
        descElement2 = LeadIntCOODesc;
        break;
      case "leadintcfo":
        descElement = LeadIntDesc;
        charElement = LeadIntCFO;
        descElement2 = LeadIntCFODesc;
        break;
      case "leadintceo":
        descElement = LeadIntDesc;
        charElement = LeadIntCEO;
        descElement2 = LeadIntCEODesc;
        break;
      case "salarynegdev":
        descElement = SalaryNegDesc;
        charElement = SalaryNegDev;
        descElement2 = SalaryNegDevDesc;
        break;
      case "salarynegproj":
        descElement = SalaryNegDesc;
        charElement = SalaryNegProj;
        descElement2 = SalaryNegProjDesc;
        break;
      case "salarynegdes":
        descElement = SalaryNegDesc;
        charElement = SalaryNegDesign;
        descElement2 = SalaryNegDesDesc;
        break;
      case "realestate":
        descElement = RealEstateDesc;
        charElement = RealEstateLinda;
        //descElement2 = "";
        break;
      case "investmentpitchurban":
        descElement = InvestmentPitchDesc;
        charElement = InvestmentPitchUrban;
        descElement2 = InvestmentPitchUrbanDesc;
        break;
      case "investmentpitchtreat":
        descElement = InvestmentPitchDesc;
        charElement = InvestmentPitchTreat;
        descElement2 = InvestmentPitchTreatDesc;
        break;
      case "investmentpitchstem":
        descElement = InvestmentPitchDesc;
        charElement = InvestmentPitchStem;
        descElement2 = InvestmentPitchStemDesc;
        break;
      case "uspoliticsgreen":
        descElement = UsPoliticsDesc;
        charElement = UsPoliticsGreen;
        descElement2 = UsPoliticsGreenDesc;
        break;
      case "uspoliticsgun":
        descElement = UsPoliticsDesc;
        charElement = UsPoliticsGun;
        descElement2 = UsPoliticsGunDesc;
        break;
      case "uspoliticsubi":
        descElement = UsPoliticsDesc;
        charElement = UsPoliticsUBI;
        descElement2 = UsPoliticsUBIDesc;
        break;
      case "customsell":
        break;
      case "customint":
        break;
      case "customneg":
        break;
      case "customdebate":
        break;
      default:
        console.log("Scenario not found");
    }
    
    
    return (
      <div className="detail-overlay">
        <div className="pop-content">
          <div className="detail-left">
            {charElement}
          </div>
          <div className="detail-right">
            {descElement}
            {descElement2}
          </div>

          <button className="x-button" onClick={resumeGame}>X</button>
        </div>
        
      </div>
    )
  }

  function WinLosePopup() {
    return (
      <div className="detail-overlay">
        <div className="win-pop-content">
          <div className="celebration-message">
            <p>{winLoseMessage}</p>
          </div>
          <button className="continue-button" onClick={goPrep}>Continue</button>
        </div>
      </div>
    )
  }

  const handleMicrophoneClick = () => {
    //console.log("IsUserTurn: " + isUserTurn.current)
    //console.log("IsRecording: " + isRecording)
    //console.log("IsClickable: " + isClickable.current)
    if (isUserTurn.current) { // User Turn
      if (isClickable.current) {
        //Save AI Transcription to convo log
        if (aiTranscription !== "") {
          fullConvo.current[`AI${turnCount.current}`] = aiTranscription;
        }

        turnCount.current++;

        setIsRecording(true);
        setIsAiSpeaking(false);
        startTimer();
        
        setTriggerReasonAnimation(false);
        setTriggerSuggestionAnimation(false);
        setTriggerFeelAnimation(false);

        isUserTurn.current = false;
      }
    } else { // AI Turn
      //Save User Transcription to convo log
      if (transcription !== "") {
        fullConvo.current[`User${turnCount.current}`] = transcription;
      }
      setIsAiSpeaking(true);
      setIsRecording(false);
      stopTimer();
      
      isClickable.current = false;
    }

    //console.log("FullConvo: " + JSON.stringify(fullConvo.current, null, 2));
  }

  const pauseGame = () => {
    // Pause the game logic
    if (UserChoices.scenario.substring(0, 6) === 'custom') {

    } else {
      setIsPopupVisible(true);
    }
    
  };

  const resumeGame = () => {
    setIsPopupVisible(false);
  };

  const goPrep = () => {
    navigate('../prep');
  };

  const startTimer = () => {
    //console.log("START TIMER")

    if (countdownInterval.current) {
      clearInterval(countdownInterval.current); // Clear existing interval if any
    }

    setTimerVisible(true);
    let timeLeft = 30;
    let timerElement = document.getElementById('countdownTimer');
    timerElement.textContent = timeLeft;

    countdownInterval.current = setInterval(function() {
        timeLeft--;
        timerElement.textContent = timeLeft;

        if (timeLeft <= 0) {
            stopTimer();
            handleMicrophoneClick();
        }
    }, 1000);
  };

  const stopTimer = () => {
    if (countdownInterval.current) {
      //console.log("STOP TIMER")
      clearInterval(countdownInterval.current);
      setTimerVisible(false);
    }
  };

  async function saveConvo(userId, fullConvoDict) {
    fetch(DOMAIN + "/set-convo-data", {
    method: "POST",
    headers: {
        "Content-Type": "application/json",
    },
    mode: "cors",
    body: JSON.stringify({ 
        firebaseId: userId,
        convoDict: fullConvoDict
    }),
    })
    .then(res => {
        if (!res.ok) {
        throw new Error(`HTTP error! Status: ${res.status}`);
        }
        return res.json();
    })
    .then(response => {
        console.log("Convo Saved: " + response.convoSaveSuccess);
        return response;
    })
    .catch((e) => {
        console.log("Error: " + e);
    });
  }

  async function addWinId(userId, winCode) {
    fetch(DOMAIN + "/add-win", {
    method: "POST",
    headers: {
        "Content-Type": "application/json",
    },
    mode: "cors",
    body: JSON.stringify({ 
        firebaseId: userId,
        winId: winCode,
    }),
    })
    .then(res => {
        if (!res.ok) {
        throw new Error(`HTTP error! Status: ${res.status}`);
        }
        return res.json();
    })
    .then(response => {
        console.log("Win Saved: " + response.newWin);
        return response;
    })
    .catch((e) => {
        console.log("Error: " + e);
    });
  }

  const microphoneClass = isRecording ? " microphone pulse" : " microphone";

  return (
    <div className="game-container">
      <audio className="audio-playback" controls></audio>
      <div className={"prompt-start " + (startSpeakVisible ? "" : "invisible")}>Click to start speaking</div>

      <div className="circle-container">
        <div className={avatarClass}></div>
        <div className="fake-box user-fake-box">xxxxxxxxx</div>
        <div className={"speech-textbox user-text-absolute" + (transcription === "" ? " invisible" : "")}>{transcription}</div>
      </div>

      <div ref={userSiriWaveContainer} className="siri-wave-container user-siri-wave-container" /> 
      
      <ProgressBar progress={progress.current} />

      <button onClick={pauseGame} className="settings-button">
        <FontAwesomeIcon icon={faCircleInfo} />
      </button>

      <button onClick={goPrep} className="home-button">
        <FontAwesomeIcon icon={faHouse} />
      </button>

      <div className="level">{difficulty.current}</div>

      {isPopupVisible && <Popup onClose={resumeGame} />}
      {isWinLosePopupVisible && <WinLosePopup onClose={goPrep} />}

      <div className={!timerVisible ? "invisible" : ""}>
        <div className={"countdown-timer"} id="countdownTimer">
            <span>30</span>
        </div>
      </div>

      <div className={microphoneClass + (isClickable.current === true ? "" : " unclickable")} onClick={handleMicrophoneClick} id="record" key={renderTrigger.current}></div>
      
      
      
      
      <div ref={aiSiriWaveContainer} className="siri-wave-container ai-siri-wave-container" />
      
      <div className={`reason-textbox ai-reason-absolute ${aiReasonClass}` + (triggerReasonAnimation === false ? " invisible" : "")}>{aiReason}</div>
      <div className={`suggestion-textbox ai-suggestion-absolute ${aiSuggestionClass}` + (triggerSuggestionAnimation === false ? " invisible" : "")}>{aiSuggestion}</div>

      <div className={`feeling-textbox ai-feeling-absolute ${aiFeelClass}` + (triggerFeelAnimation === false ? " invisible" : "")}>{aiFeeling}</div>

      <div className="circle-container">
        <div className={"speech-textbox ai-text-absolute" + (aiTranscription === "" ? " invisible" : "")}>{aiTranscription}</div>
        <div className="fake-box ai-fake-box">xxxxxxxxxxxxxxxxxxxxx</div>
        <div className={aiAvatarClass}></div>
      </div>
    </div>
  )
};

export default IntroGame