import React from 'react';
import ReactDOM from 'react-dom';
import {
    BrowserRouter as Router,
    Routes,
    Route,
  } from 'react-router-dom';

import App from './App';
import './index.css'
import Game from './Game';
import Pre from './PreGame';
import ChooseChar from './ChooseChar';
import Subsciption from './Subscription';
import SuccessfulPayment from './SuccessfulPayment';
import Elem4 from './FourOFour';
import TermsPage from './TermsPage';
import ProfilePage from './ProfilePage';

ReactDOM.render(
    //<React.StrictMode>
        <Router>
            <Routes>
                <Route path="/" element={<App />}></Route>
                <Route path="/prep" element={<Pre />}></Route>
                <Route path="/game" element={<Game />}></Route>
                <Route path="/choosecharacter" element={<ChooseChar />}></Route>
                <Route path="/subscribe" element={<Subsciption />}></Route>
                <Route path="/payment-success" element={<SuccessfulPayment />}></Route>
                <Route path="/terms" element={<TermsPage />}></Route>
                <Route path="/profile" element={<ProfilePage />}></Route>
                <Route path="/*" element={<Elem4 />}></Route>
            </Routes>
        </Router>,
    //</React.StrictMode>,
    document.getElementById('root')
);