import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, googleProvider } from '../../config/firebase.js';
import logo from '../../assets/v.png';
import goog from '../../assets/google.svg';
import './navbar.css';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signInWithPopup, signOut } from 'firebase/auth';

import char1 from '../../assets/character1.png';
import char2 from '../../assets/character2.png';
import char3 from '../../assets/character3.png';
import char4 from '../../assets/character4.png';
import char5 from '../../assets/character5.png';
import char6 from '../../assets/character6.png';
import char7 from '../../assets/character7.png';
import char8 from '../../assets/character8.png';
import char9 from '../../assets/character9.png';
import char10 from '../../assets/character10.png';


const DOMAIN = `${process.env.REACT_APP_API_URL}`;

const UserChoices = require('../../userChoices.js');

const Menu = () => (
  <>
  <p><a href='#home'>Home</a></p>
  <p><a href='#howitworks'>How it Works</a></p>
  <p><a href='#scenarios'>Scenarios</a></p>
  </>
)

const Navbar = ( {isSignUpVisible, isSignInVisible, handleSignClick, closePopup, showSignIn, showSignUp, setIntent, intent } ) => {
  let navigate = useNavigate();
  
  const [isLoggedIn, setIsLoggedIn] = useState(auth?.currentUser?.email ? true : false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [isDefault, setIsDefault] = useState(null);
  const [signException, setSignException] = useState("");
  const [justSignedIn, setJustSignedIn] = useState(false);

  UserChoices.rehydrate();


  const [profilePic, setProfilePic] = useState(UserChoices.characterPic);

  // Receive login event from firebase.js
  useEffect(() => {
    const handleUserLogIn = async () => {

      await retrieveUserInfo(auth?.currentUser?.uid).then((data) => {
        //console.log(data.character);
        //console.log(data.premium);
        UserChoices.setEmail(auth?.currentUser?.email);
        UserChoices.setCharacter(data.character);
        UserChoices.setPremium(data.premium);

  
        switch (data.character) {
          case "victor":
            setProfilePic(char1);
            break;
          case "akira":
            setProfilePic(char6);
            break;
          case "xavier":
            setProfilePic(char7);
            break;
          case "maya":
            setProfilePic(char3);
            break;
          case "ashton":
            setProfilePic(char2);
            break;
          case "naomi":
            setProfilePic(char8);
            break;
          case "sebastian":
            setProfilePic(char10);
            break;
          case "victoria":
            setProfilePic(char4);
            break;
          case "yasuke":
            setProfilePic(char5);
            break;
          case "amber":
            setProfilePic(char9);
            setIsDefault(false);
            break;
          default:
            setProfilePic(char1);
            UserChoices.setCharacter("victor");
            setIsDefault(true);
        }
  

        setJustSignedIn(true);
        setIsLoggedIn(true);
      });
    };

    window.addEventListener('userLoggedIn', handleUserLogIn);

    return () => window.removeEventListener('userLoggedIn', handleUserLogIn);
  }, []);


  // Receive logout event from firebase.js
  useEffect(() => {
    const handleUserLogOut = () => {
      //console.log("User is signed out");
      setProfilePic(null);
    };

    window.addEventListener('userLoggedOut', handleUserLogOut);

    return () => window.removeEventListener('userLoggedOut', handleUserLogOut);
  }, []);

  

  // Update character pic
  useEffect(() => {
    //console.log("Update Char Pic")
    UserChoices.setCharacterPic(profilePic);
  }, [profilePic]);

  useEffect(() => {
    //console.log("Intent: " + intent)
    //console.log("Just signed in: " + justSignedIn)
    //console.log("Has premium access: " + UserChoices.premium)
    //console.log("Is default: " + isDefault)
    if (intent && justSignedIn) {
      
      if (isDefault) {
        navigate('../ChooseCharacter');

      } else if (UserChoices.premium === 'true') {
        console.log("Just signed in with premium access");
        navigate('../Prep');

      } else if (UserChoices.premium === 'false') {
        console.log("Just signed in with non-premium access");
        navigate('../Subscribe');
      }
    }
  }, [ intent, isDefault, justSignedIn, navigate]);
  

  // On render
  useEffect(() => {

    const handleClickOutside = (event) => {
      // Get the dropdown element
      const dropdownElement = document.querySelector('.profile-dropdown');
      
      // Check if the click is outside the dropdown
      if (dropdownElement && !dropdownElement.contains(event.target)) {
        setShowDropdown(false); // Assuming setShowDropdown is your state setter
      }
    };
  
    // Attach the event listener
    document.addEventListener('mousedown', handleClickOutside);
  
    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  

  const proceed = () => {
    if (isLoggedIn) {
      navigate('../Prep');
    } else {
      setJustSignedIn(false);
      setIntent(); 
      showSignUp(); 
      setSignException('');
    }
  }

  const goProfile = () => {
    navigate('../profile');
  }


  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };


    //event.preventDefault();


  function SignUpPopup({ onClose }) {
    const [email, setEmail] = useState(UserChoices.tempEmail);
    const [password, setPassword] = useState('');


    return (
      <div className="detail-overlay">
        <div className="sign-popup">
          <button className="close-button" onClick={onClose}>x</button>
          <h2>Let's get started</h2>
          
          <input 
            type="email" 
            placeholder="mail@website.com"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input 
            type="password" 
            placeholder="password"
            onChange={(e) => setPassword(e.target.value)}
          />
          <button className="email-sign-up-button" onClick={() => { signUp(email, password); }}>Sign Up</button>
          <p className="sign-exception">{signException}</p>
          <div className="divider">
            <hr/>
          </div>
          <button className="sign-up-button" onClick={signInWithGoogle}>
            <img src={goog} alt="Google sign up" />Sign up with Google
          </button>
          <p className="terms">
            By continuing you accept our <a href="/terms">standard terms and conditions</a> and <a href="/terms">privacy policy</a>.
          </p>
          <div className="switch-login">
            Already have an account? <p onClick={handleSignClick}>Log In</p>
          </div>
        </div>
      </div>
    );
  };

  function SignInPopup({ onClose }) {
    const [email, setEmail] = useState(UserChoices.tempEmail);
    const [password, setPassword] = useState('');

    return (
      <div className="detail-overlay">
        <div className="sign-popup">
          <button className="close-button" onClick={onClose}>x</button>
          <h2>Let's get started</h2>
          
          <input 
            type="email" 
            placeholder="mail@website.com" 
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input 
            type="password" 
            placeholder="password" 
            onChange={(e) => setPassword(e.target.value)}
          />
          <button className="email-sign-up-button" onClick={() => { signIn(email, password); }}>Sign In</button>
          <p className="sign-exception">{signException}</p>
          <div className="divider">
            <hr />
          </div>
          <button className="sign-up-button" onClick={signInWithGoogle}>
            <img src={goog} alt="Google sign up" />Sign In with Google
          </button>
          <p className="terms">
            By continuing you accept our <a href="/terms">standard terms and conditions</a> and <a href="/terms">privacy policy</a>.
          </p>
          <div className="switch-login">
            Don't have an account? <p onClick={handleSignClick}>Create Account</p>
          </div>
        </div>
      </div>
    );
  };

  const signUp = async (email, password) => {
    try {
      await createUserWithEmailAndPassword(auth, email, password).then((userCredential) => {
        updateDatabase(email, userCredential.user.uid);
      });
      closePopup();
    } catch (error) { // Sign-Up Exception Handling
      if (error.code === 'auth/invalid-email') {
        setSignException('Invalid email address');
      } else if (error.code === 'auth/email-already-in-use') {
        setSignException('Email already in use');
      } else if (error.code === 'auth/user-disabled') {
        setSignException('User disabled');
      } else if (error.code === 'auth/user-not-found') {
        setSignException('User not found');
      } else if (error.code === 'auth/wrong-password') {
        setSignException('Wrong password');
      } else if (error.code === 'auth/weak-password') {
        setSignException('Weak password');
      } else if (error.code === 'auth/missing-password') {
        setSignException('Missing password');
      } else if (error.code === 'auth/operation-not-allowed') {
        setSignException('Operation not allowed');
      } else if (error.code === 'auth/invalid-user-token') {
        setSignException('Invalid user token');
      } else if (error.code === 'auth/user-token-expired') {
        setSignException('User token expired');
      } else if (error.code === 'auth/network-request-failed') {
        setSignException('Network request failed');
      } else if (error.code === 'auth/too-many-requests') {
        setSignException('Too many requests');
      } else if (error.code === 'auth/requires-recent-login') {
        setSignException('Requires recent login')
      } else {
        console.log("Unknown Sign Up Error: " + error)
      }
      
    }
  };

  const signIn = async (email, password) => {
    try {
      await signInWithEmailAndPassword(auth, email, password).then((userCredential) => {
        updateDatabase(email, userCredential.user.uid);
      });
      closePopup();
    } catch (error) { // Sign-In Exception Handling
      if (error.code === 'auth/invalid-email') {
        setSignException('Invalid email address');
      } else if (error.code === 'auth/email-already-in-use') {
        setSignException('Email already in use');
      } else if (error.code === 'auth/user-disabled') {
        setSignException('User disabled');
      } else if (error.code === 'auth/user-not-found') {
        setSignException('User not found');
      } else if (error.code === 'auth/wrong-password') {
        setSignException('Wrong password');
      } else if (error.code === 'auth/weak-password') {
        setSignException('Weak password');
      } else if (error.code === 'auth/missing-password') {
        setSignException('Missing password');
      } else if (error.code === 'auth/operation-not-allowed') {
        setSignException('Operation not allowed');
      } else if (error.code === 'auth/invalid-user-token') {
        setSignException('Invalid user token');
      } else if (error.code === 'auth/user-token-expired') {
        setSignException('User token expired');
      } else if (error.code === 'auth/network-request-failed') {
        setSignException('Network request failed');
      } else if (error.code === 'auth/too-many-requests') {
        setSignException('Too many requests');
      } else if (error.code === 'auth/requires-recent-login') {
        setSignException('Requires recent login')
      } else {
        console.log("Unknown Sign Up Error: " + error)
      }
    }
  };

  const signInWithGoogle = async () => {
    try {
      await signInWithPopup(auth, googleProvider).then((result) => {
        updateDatabase(result.user.email, result.user.uid);
      });
      closePopup();
    } catch (error) {
      console.log("Unknown Google Sign-In Error: " + error)
    }
  };

  const logout = async () => {
    try {
      await signOut(auth)
      setProfilePic(null);
      setIsLoggedIn(false);
      toggleDropdown();

      UserChoices.setWins([]);
    } catch (error) {
      console.log("Logout Error: " + error)
    }
  };


  // Update database with new user
  async function updateDatabase(email, userId) {
    fetch(DOMAIN + "/update-db-newuser", {
      method: "POST",
      headers: {
          "Content-Type": "application/json",
      },
      mode: "cors",
      body: JSON.stringify({ 
          userEmail: email,
          firebaseId: userId,
      }),
    })
    .then(res => {
        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
        }
        return res.json();
    })
    .then(response => {
        console.log("Response object:", response);
        window.location.href = response.url;
    })
    .catch((e) => {
        console.log("Error: " + e);
    });
  }


  // Retrieve user data from database
  async function retrieveUserInfo(userId) {
    return fetch(DOMAIN + "/get-user-info", {
      method: "POST",
      headers: {
          "Content-Type": "application/json",
      },
      mode: "cors",
      body: JSON.stringify({ 
          firebaseId: userId,
      }),
    })
    .then(res => {
        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
        }
        return res.json();
    })
    .then(response => {
        //console.log("Character: " + response.character + " Premium: " + response.premium);
        return response;
    })
    .catch((e) => {
        console.log("Error: " + e);
    });
  }

  
  
  return (
    <div className='navbar'>
      {isSignUpVisible && <SignUpPopup onClose={closePopup}/>}
      {isSignInVisible && <SignInPopup onClose={closePopup}/>}

      <div className='navbar-links'>
        <div className='navbar-links_logo'>
           <img src={logo} alt='logo'/>
        </div>
          <div className='navbar-links_container'>
            <Menu />
            <p onClick={proceed}>Play for Free</p>
          </div>
        </div>
      <div className='navbar-sign'>
        {isLoggedIn ? (
          <React.Fragment>
            <img src={profilePic} alt="Profile" style={{ width: '85px', height: '85px', borderRadius: '50%' }} onClick={toggleDropdown} />
            {showDropdown && (
            <div className="profile-dropdown">
              <button onClick={goProfile}>Profile</button>
              <button onClick={proceed}>Play</button>
              <button onClick={logout}>Logout</button>
            </div>
            )}
          </React.Fragment>
        ) : (
          <React.Fragment>
            <p type='button' onClick={() => { setJustSignedIn(false);
                                              setIntent(); 
                                              showSignIn(); 
                                              setSignException(''); }}>Sign in</p>
            <button type='button' onClick={() => { setJustSignedIn(false); 
                                                   setIntent(); 
                                                   showSignUp(); 
                                                   setSignException(''); }}>Sign up</button>
          </React.Fragment>
        )}
      </div>
    </div>
  )
}

export default Navbar