import React from 'react';
import def1 from '../../assets/eloquence.JPG';
import def2 from '../../assets/closer.JPG';
import './defs.css';

const DEFS = () => {
  return (
    <div className="gpt3__cta">
      <img className="img1" src={def1} alt='eloquence' />
      <img src={def2} alt='closer' />
    </div>
  )
}

export default DEFS