class UserChoices {
    constructor() {
        if (!UserChoices.instance) {

            this.email = "";
            this.premium = false;
            this.character = "";
            this.characterPic = null;
            this.scenario = "";
            this.opponent = "";
            this.difficulty = "Easy";
            this.foragainst = "for";
            this.traits = "";
            this.wins = [""];

            this.tempEmail = "";
            
            //Custom Sales
            this.customsellProduct = "";
            this.customsellDesc = "";
            this.customsellContext = "";

            //Custom Interview
            this.customintTitle = "";
            this.customintCompany = "";
            this.customintDesc = "";

            //Custom Negotiation
            this.customnegGoal = "";
            this.customnegCounter = "";

            //Custom Debate
            this.customdebatePos = "";
        }
        return UserChoices.instance;
    }

    // Rehydrate User Choice data (from localstorage) on refresh
    rehydrate() {
        
        this.email = localStorage.getItem('email');
        this.premium = localStorage.getItem('premium');
        this.character = localStorage.getItem('character');
        this.characterPic = localStorage.getItem('characterPic');
        this.scenario = localStorage.getItem('scenario');
        this.opponent = localStorage.getItem('opponent');
        this.difficulty = localStorage.getItem('difficulty');
        this.foragainst = localStorage.getItem('foragainst');
        this.traits = localStorage.getItem('traits');
        this.wins = JSON.parse(localStorage.getItem('wins'));

        //Custom Sales
        this.customsellProduct = localStorage.getItem('customsellProduct');
        this.customsellDesc = localStorage.getItem('customsellDesc');
        this.customsellContext = localStorage.getItem('customsellContext');

        //Custom Interview
        this.customintTitle = localStorage.getItem('customintTitle');
        this.customintCompany = localStorage.getItem('customintCompany');
        this.customintDesc = localStorage.getItem('customintDesc');

        //Custom Negotiation
        this.customnegGoal = localStorage.getItem('customnegGoal');
        this.customnegCounter = localStorage.getItem('customnegCounter');

        //Custom Debate
        this.customdebatePos = localStorage.getItem('customdebatePos');

    }

    printUserData() {
        console.log("Email: " + this.email);
        console.log("Premium: " + this.premium);
        console.log("Character: " + this.character);
        //console.log("Character Pic: " + this.characterPic);
        console.log("Scenario: " + this.scenario);
        console.log("Opponent: " + this.opponent);
        console.log("Difficulty: " + this.difficulty);
        console.log("For/Against: " + this.foragainst);
        console.log("Traits: " + this.traits);
        console.log("Wins: " + this.wins);
        console.log("Custom Sell Product: " + this.customsellProduct);
        console.log("Custom Sell Desc: " + this.customsellDesc);
        console.log("Custom Sell Context: " + this.customsellContext);
        console.log("Custom Int Title: " + this.customintTitle);
        console.log("Custom Int Company: " + this.customintCompany);
        console.log("Custom Int Desc: " + this.customintDesc);
        console.log("Custom Neg Goal: " + this.customnegGoal);
        console.log("Custom Neg Counter: " + this.customnegCounter);
        console.log("Custom Debate Pos: " + this.customdebatePos);
    }

    setEmail(e) {
        this.email = e;
        localStorage.setItem('email', e);
    }

    setTempEmail(e) {
        this.tempEmail = e;
        localStorage.setItem('tempEmail', e);
    }

    setPremium(p) {
        this.premium = p;
        localStorage.setItem('premium', p);
    }

    setCharacter(char) {
        this.character = char;
        localStorage.setItem('character', char);
    }

    setCharacterPic(charPic) {
        this.characterPic = charPic;
        localStorage.setItem('characterPic', charPic);
    }

    setScenario(scen) {
        this.scenario = scen;
        localStorage.setItem('scenario', scen);
    }

    setOpponent(op) {
        this.opponent = op;
        localStorage.setItem('opponent', op);
    }

    setDifficulty(diff) {
        this.difficulty = diff;
        localStorage.setItem('difficulty', diff);
    }

    setForAgainst(fa) {
        this.foragainst = fa;
        localStorage.setItem('foragainst', fa);
    }

    setCustomSellProduct(product) {
        this.customsellProduct = product;
        localStorage.setItem('customsellProduct', product);
    }

    setCustomSellDesc(desc) {
        this.customsellDesc = desc;
        localStorage.setItem('customsellDesc', desc);
    }

    setCustomSellContext(context) {
        this.customsellContext = context;
        localStorage.setItem('customsellContext', context);
    }

    setCustomIntTitle(title) {
        this.customintTitle = title;
        localStorage.setItem('customintTitle', title);
    }

    setCustomIntCompany(company) {
        this.customintCompany = company;
        localStorage.setItem('customintCompany', company);
    }

    setCustomIntDesc(desc) {
        this.customintDesc = desc;
        localStorage.setItem('customintDesc', desc);
    }

    setCustomNegGoal(goal) {
        this.customnegGoal = goal;
        localStorage.setItem('customnegGoal', goal);
    }

    setCustomNegCounter(counter) {
        this.customnegCounter = counter;
        localStorage.setItem('customnegCounter', counter);
    }

    setCustomDebatePos(pos) {
        this.customdebatePos = pos;
        localStorage.setItem('customdebatePos', pos);
    }

    setTraits(traits) {
        this.traits = traits;
        localStorage.setItem('traits', traits);
    }

    setWins(wins) {
        this.wins = wins;
        localStorage.setItem('wins', JSON.stringify(wins));
    }

    static instance = new UserChoices();
}

module.exports = UserChoices.instance;