// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, onAuthStateChanged } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
//import { getAnalytics } from "firebase/analytics";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
let app;
let auth;
let googleProvider;
let firestore;

//Retrieve secret data from server env
async function loadFirebaseConfig() {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/firebase-config`);
  const config = await response.json();
  return config;
}

let appPromise = loadFirebaseConfig().then((firebaseConfig) => {
  app = initializeApp(firebaseConfig);
  auth = getAuth(app);
  googleProvider = new GoogleAuthProvider();
  firestore = getFirestore(app);

  onAuthStateChanged(auth, (user) => {
    if (user) {
      // User is signed in
      const loginEvent = new CustomEvent('userLoggedIn', { detail: { /* some data */ } });
      window.dispatchEvent(loginEvent);
    } else {
      // User is signed out
      const logoutEvent = new CustomEvent('userLoggedOut', { detail: { /* some data */ } });
      window.dispatchEvent(logoutEvent);
    }
  });

  return { app, auth, googleProvider, firestore};
}).catch(err => {
  console.error("Firebase initialization error: " + err);
  throw err; // Ensure initialization errors are propagated
});




export { auth, googleProvider };

export async function getFirebase() {
  return appPromise;
}



//export const auth = getAuth(app);
//export const googleProvider = new GoogleAuthProvider();


//const analytics = getAnalytics(app);