import React from 'react';

import { ProfilePage } from './components';
import './app.css';


const Profile = () => {
    return (
        <div className='App'>
            <div className='gradient__bg'>
                <ProfilePage />
            </div>
        </div>
    )
 }
  
  export default Profile