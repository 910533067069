import React from 'react';
import './fourofour.css';
import { useNavigate } from 'react-router-dom';



const FourPage = () => {
    let navigate = useNavigate();

    const goHome = () => {
        navigate('../');
    }


    return (
        <div className="container">
            <div className="four-card">
                <h1 className="four-title">ERROR 404: Page Not Found</h1>
                <p className="four-text">Please return to the home page</p>
                <button className="four-button" onClick={goHome}>
                    Return Home
                </button>
            </div>
        </div>
    )
}

export default FourPage;