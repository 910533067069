import React from 'react';
import vlogo from '../../assets/v.png';
import './footer.css';

const Footer = () => (
  <div className="footer section__padding">

    <div className="footer-links">
      <div className="footer-links_logo">
        <img src={vlogo} alt="gpt3_logo" />
      </div>
      <div className="footer-links_div">
        <h4>Links</h4>
        <p><a href="https://twitter.com/verbeloquence">Twitter</a></p>
        <p><a href="https://www.reddit.com/r/verbeloquence">Reddit</a></p>
        <p><a href="https://www.linkedin.com/company/verbeloquence">LinkedIn</a></p>
      </div>
      <div className="footer-links_div">
        <h4>Company</h4>
        <p>
          <a href="/terms">Terms & Conditions </a>
        </p>
        <p>
          <a href="/terms">Privacy Policy</a>
        </p>
      </div>
      <div className="footer-links_div">
        <h4>Get in touch</h4>
        <p>mike@verbeloquence.com</p>
        <p>406-224-4782</p>
      </div>
    </div>

    <div className="footer-copyright">
      <p>@2023 Verbeloquence. All rights reserved.</p>
    </div>
  </div>
);

export default Footer;