import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../config/firebase.js';
import './prep.css';
import prospect1 from '../../assets/boomer1.png';
//import homeowner1 from '../../assets/homeowner1.png';
import fordmustang from '../../assets/fordmustang.png';
import chevyimpala from '../../assets/chevyimpala.png';
import dodgechallenger from '../../assets/dodgechallenger.png';
import sell1 from '../../assets/sell1.png';
import landscape1 from '../../assets/landscape1.png';
import massage1 from '../../assets/massage1.png';
import veterinarian1 from '../../assets/veterinarian1.png';
import BasicInt1 from '../../assets/BasicInt1.png';
import BasicInt2 from '../../assets/BasicInt2.png';
import BasicInt3 from '../../assets/BasicInt3.png';
import interview2 from '../../assets/interview2.png';
//import interview3 from '../../assets/interview3.png';
import interview5 from '../../assets/interview5.png';
import createint1 from '../../assets/createint1.png';
import createint2 from '../../assets/createint2.png';
import engineer1 from '../../assets/engineer1.png';
import software1 from '../../assets/software1.png';
import negotiation1 from '../../assets/negotiation1.png';
//import negotiation2 from '../../assets/negotiation2.png';
import negotiation3 from '../../assets/negotiation3.png';
import negotiation4 from '../../assets/negotiation4.png';
import wallst1 from '../../assets/wallst1.png';
import wallst2 from '../../assets/wallst2.png';
import wallst3 from '../../assets/wallst3.png';
import wallst4 from '../../assets/wallst4.png';
import wolfst1 from '../../assets/wolfst1.png';
import wolfst2 from '../../assets/wolfst2.png';
import wolfst3 from '../../assets/wolfst3.png';
import insurance1 from '../../assets/insurance1.png';
import insurance2 from '../../assets/insurance2.png';
import insurance3 from '../../assets/insurance3.png';
import scientist1 from '../../assets/scientist1.png';
import ceo from '../../assets/aiCEO1.png';
import coo from '../../assets/interview4.png';
import cfo from '../../assets/cfo.png';
import pitch1 from '../../assets/pitch1.png';
import pitch2 from '../../assets/pitch2.png';
//import startup1 from '../../assets/startup1.png';
import coldcall1 from '../../assets/coldcall1.png';
import realestate1 from '../../assets/realestate1.png';
import law1 from '../../assets/law1.png';
import law2 from '../../assets/law2.png';
//import deal1 from '../../assets/deal1.png';
//import deal2 from '../../assets/deal2.png';
//import deal3 from '../../assets/deal3.png';
//import deal4 from '../../assets/deal4.png';
import deal5 from '../../assets/deal5.png';
import deal6 from '../../assets/deal6.png';
import debate1 from '../../assets/debate1.png';
import uspolitics1 from '../../assets/uspolitics1.png';
import uspolitics2 from '../../assets/uspolitics2.png';
import uspolitics3 from '../../assets/uspolitics3.png';
import aiCEO from '../../assets/aiCEO1.png';
import fin1 from '../../assets/fin1.png';
//import econ1 from '../../assets/econ1.png';
import politician1 from '../../assets/politician1.png';
//import int1 from '../../assets/int1.png';
//import theism from '../../assets/theism.png';
//import hostage1 from '../../assets/hostage1.png';

const UserChoices = require('../../userChoices.js');

const DOMAIN = `${process.env.REACT_APP_API_URL}`;

// CAR SALES
export const CarSalesDesc = (
    <div>
        <div className="desc">
            <h1>Muscle Car Sales</h1>
            <p>With a penchant for the classics and a longing for the "good ol' days", Chuck has a deep-seated nostalgia for muscle cars.</p>
            <p>Chuck values more than just utility, he seeks a car with character - one that’s both a collector's dream and a nod to the golden age of automotive design.</p>
            <p>Salespeople would be wise to engage with Chuck's stories and understand his passion to win him over.</p>
        </div>
        <div className="options">
            <div>
                <img src={fordmustang} alt='mustang' />
                <h3>1965 Ford Mustang</h3>
                <ul className="pros">
                    <li>Timeless design</li>
                    <li>Strong parts availability for maintencance or restoration</li>
                    <li>Vast network of enthusiasts and clubs</li>
                </ul>
                <ul className="cons">
                    <li>Primitive braking system, may require upgrades</li>
                    <li>Lack of safety and comfort features</li>
                    <li>Rust issues around floorboards and wheel wells</li>
                </ul>
            </div>
            <div>
                <img src={chevyimpala} alt='impala' />
                <h3>1967 Chevy Impala</h3>
                <ul className="pros">
                    <li>Comfortable ride with plenty of interior space</li>
                    <li>Big-block engines provide ample power</li>
                    <li>Cult following</li>
                </ul>
                <ul className="cons">
                    <li>Large, it won't handle as nimbly as smaller cars</li>
                    <li>Low fuel efficiency</li>
                    <li>Select parts often rare and expensive</li>
                </ul>
            </div>
            <div>
                <img src={dodgechallenger} alt='challenger' />
                <h3>1970 Dodge Challenger</h3>
                <ul className="pros">
                    <li>Aggressive styling that turns heads</li>
                    <li>Highly sought after, may be a good investment</li>
                    <li>Grear power and acceleration</li>
                </ul>
                <ul className="cons">
                    <li>High insurance costs due to larger engine</li>
                    <li>Scarce restoration resources</li>
                    <li>Unforgiving for inexperienced drivers</li>
                </ul>
            </div>
        </div>
    </div>
);

export const CarSalesChar = (
    <div>
        <img className="opponent" src={prospect1} alt='prospect' />
        <div className="chars">
            <p><b>Name:</b> Chuck "The Cruiser" Malone</p>
            <p><b>Profile:</b> 58yo Upper-Class American Male</p>
            <p><b>Traits:</b> Charming, sociable, over-confident</p>
            <p><b>Skills:</b> Haggling, Basic Negotiation</p>
        </div>
    </div>
);

// COLD CALL
export const ColdCallLandscape = (
    <div>
        <img className="opponent" src={landscape1} alt='prospect' />
        <div className="chars">
            <p><b>Name:</b> Emily Rivera</p>
            <p><b>Company:</b> Nature's Canvas LLC</p>
            <p><b>Traits:</b> Passionate, Self-Made, Community-Focused, Driven</p>
            <p><b>Skills:</b> Horticulture, Artistic Design, non-digital networking</p>
        </div>
        <div className="chars">
            <li>Emily maintains a basic website and sporadic social media presence</li>
            <li>Mostly relies on local advertising and community events for promotion</li>
        </div>
    </div>
);

export const ColdCallMassage = (
    <div>
        <img className="opponent" src={massage1} alt='prospect' />
        <div className="chars">
            <p><b>Name:</b> Aria Tanaka</p>
            <p><b>Company:</b> Harmony Wellness</p>
            <p><b>Traits:</b> Dedicated, Holistic, Comforting</p>
            <p><b>Skills:</b> Team management and client relations. Mastered techniques include Swedish, deep tissue, and reflexology.</p>
        </div>
        <div className="chars">
            <li>Aria only advertises in the local newspaper.</li>
            <li>Acquires most clients via strong local wellness network referalls. </li>
        </div>
    </div>
);

export const ColdCallVeterinary = (
    <div>
        <img className="opponent" src={veterinarian1} alt='prospect' />
        <div className="chars">
            <p><b>Name:</b> Dr. Lucas Bennett</p>
            <p><b>Company:</b> Paws & Claws Veterinary Center</p>
            <p><b>Traits:</b> Compassionate, Educator, Practical</p>
            <p><b>Skills:</b> Communication of complex topics. Conducts routine care and complex surgeries</p>
        </div>
        <div className="chars">
            <li>Modern state-of-the-art facility speaks for itself.</li>
            <li>Dr. Bennett's clients are typically older, he would benefit from outreach to younger demographics.</li>
        </div>
    </div>
);

// WALL STREET
export const WallStGMT = (
    <div>
        <img className="opponent" src={wallst1} alt='prospect' />
        <div className="chars">
            <p><b>Name:</b> Marcus Elliott (CFA)</p>
            <p><b>Profession:</b> Senior Hedge Fund Manager</p>
            <p><b>Company:</b> Sterling Capital Management</p>
            <p><b>Traits:</b> Resilient, Risk-Tolerant, Decisive</p>
            <p><b>Skills:</b> Portfolio Management, Macro/Market Analysis.</p>
        </div>
        <div className="chars">
            <li>Marcus' winning strategy relies upon adapting to changing market coditions.</li>
            <li>He values both short-term gains and long-term growth, balancing aggressive strategies with stable investments.</li>
        </div>
        <div className="chars thumbnail-hover">
            <p><b>Ratio vs Industry Avg</b></p>
            <div className="thumbnail-link">
                <p><b>P/E:</b> 35 vs 30</p>
                <p className="thumbnail">investors may expect higher earnings growth in the future relative to peers</p>
            </div>
            <div className="thumbnail-link">
                <p><b>P/S:</b> 10 vs 8</p>
                <p className="thumbnail">priced higher per dollar of sales, which could be justified by higher sales growth or better profit margins</p>
            </div>
            <div className="thumbnail-link">
                <p><b>P/B:</b> 6 vs 7</p>
                <p className="thumbnail">market undervalues GMT's net assets compared to its peers or its peers have more intangible assets boosting their book values</p>
            </div>
            <div className="thumbnail-link">
                <p><b>EV/EBITDA:</b> 25 vs 20</p>
                <p className="thumbnail">higher multiple could reflect a premium for its high growth prospects or a market perception of lower risk</p>
            </div>
            <div className="thumbnail-link">
                <p><b>Gross Margin:</b> 70% vs 60%</p>
                <p className="thumbnail">high margin may be due to efficient cost management or a higher-value product mix</p>
            </div>
            <div className="thumbnail-link">
                <p><b>Operating Margin:</b> 25% vs 20%</p>
                <p className="thumbnail">might be attributed to GMT's streamlined operations and successful cost control measures</p>
            </div>
            <div className="thumbnail-link">
                <p><b>ROE:</b> 15% vs 12%</p>
                <p className="thumbnail">indicates GMT is generating more profit from its shareholders' equity than the industry norm, which could be a sign of effective management</p>
            </div>
            <div className="thumbnail-link">
                <p><b>Debt-to-Equity:</b> 0.5 vs 0.8</p>
                <p className="thumbnail">GMT has less leverage and potentially lower financial risk</p>
            </div>
            <div className="thumbnail-link">
                <p><b>Current Ratio:</b> 1.5 vs 1.5</p>
                <p className="thumbnail">as capable of covering its short-term liabilities with its short-term assets as its industry counterparts</p>
            </div>
            <div className="thumbnail-link">
                <p><b>FCF Yield:</b> 4% vs 3%</p>
                <p className="thumbnail">attractive point for investors looking for companies with solid cash-generating ability</p>
            </div> 
        </div>
    </div>
);

export const WallStEES = (
    <div>
        <img className="opponent" src={wallst4} alt='prospect' />
        <div className="chars">
            <p><b>Name:</b> Elizabeth Hamilton</p>
            <p><b>Profession:</b> Senior Portfolio Manager</p>
            <p><b>Company:</b> Metropolitan Wealth Management</p>
            <p><b>Traits:</b> Astute, Risk-Taker, Client-Centric</p>
            <p><b>Skills:</b> Decisive when faced with difficult options, calculated risk-management, clients trust her despite the risks</p>
        </div>
        <div className="chars">
            <li>Her primary goal is to exceed clients' objectives through personalized strategies and proactive portfolio management</li>
            <li>Seeks to maintain her status as a top-tier portfolio manager in the wealth management industry</li>
        </div>
        <div className="chars thumbnail-hover">
            <p><b>Ratio vs Industry Avg</b></p>
            <div className="thumbnail-link">
                <p><b>P/E:</b> 20 vs 22</p>
                <p className="thumbnail">might be slightly undervalued compared to peers or that the market expects slower earnings growth due to its risky wind projects</p>
            </div>
            <div className="thumbnail-link">
                <p><b>P/S:</b> 3 vs 3</p>
                <p className="thumbnail">reflects that the company's sales are valued similarly to its peers, indicating that its transition strategy is well-received by the market</p>
            </div>
            <div className="thumbnail-link">
                <p><b>P/B:</b> 1.5 vs 2</p>
                <p className="thumbnail">could imply that EES' net assets are conservatively valued, considering its ongoing investments in renewable infrastructure</p>
            </div>
            <div className="thumbnail-link">
                <p><b>EV/EBITDA:</b> 11 vs 10</p>
                <p className="thumbnail">may be due to the market pricing in future growth from the companys renewable segment or a premium for its diversified energy portfolio</p>
            </div>
            <div className="thumbnail-link">
                <p><b>Gross Margin:</b> 40% vs 45%</p>
                <p className="thumbnail">could be attributed to the costs associated with maintaining both renewable and non-renewable operations</p>
            </div>
            <div className="thumbnail-link">
                <p><b>Operating Margin:</b> 25% vs 30%</p>
                <p className="thumbnail">may reflect the transitional costs or inefficiencies associated with operating a mixed portfolio of energy sources</p>
            </div>
            <div className="thumbnail-link">
                <p><b>ROE:</b> 10% vs 12%</p>
                <p className="thumbnail">suggests EES is generating less profit from its equity than its peers, which could be due to high reinvestment rates into renewable technology</p>
            </div>
            <div className="thumbnail-link">
                <p><b>Debt-to-Equity:</b> 0.8 vs 0.8</p>
                <p className="thumbnail">indicates typical leverage profile, balancing debt and equity financing in a manner common to the sector</p>
            </div>
            <div className="thumbnail-link">
                <p><b>Current Ratio:</b> 1.8 vs 1.5</p>
                <p className="thumbnail">suggests EES has a better short-term liquidity position and is more capable of covering its liabilities</p>
            </div>
            <div className="thumbnail-link">
                <p><b>FCF Yield:</b> 5% vs 6%</p>
                <p className="thumbnail">might reflect higher capital expenditures as EES invests in renewable energy projects</p>
            </div> 
        </div>
    </div>
);

export const WallStFFI = (
    <div>
        <img className="opponent" src={wallst3} alt='prospect' />
        <div className="chars">
            <p><b>Name:</b> Alexander Mercer (CPE)</p>
            <p><b>Profession:</b> Senior Pension Fund Manager</p>
            <p><b>Company:</b> Grandview Pension Management</p>
            <p><b>Traits:</b> Prudent, Long-Term Mindset, Ethical</p>
            <p><b>Skills:</b> Risk Management, Financial Analysis, Regulatory Compliance</p>
        </div>
        <div className="chars">
            <li>Alex follows a conservative investment philosophy, emphasizing asset preservation and steady growth</li>
            <li>He values transparency and stakeholder engagement, regularly updating pension beneficiaries on the fund’s performance and strategy</li>
        </div>
        <div className="chars thumbnail-hover">
            <p><b>Ratio vs Industry Avg</b></p>
            <div className="thumbnail-link">
                <p><b>P/E:</b> 28 vs 25</p>
                <p className="thumbnail">investors may expect higher future earnings growth from FFI due to its strong position in a growth segment</p>
            </div>
            <div className="thumbnail-link">
                <p><b>P/S:</b> 4.5 vs 3.5</p>
                <p className="thumbnail">could be justified by FFI's broader market reach and its innovative product lineup in a trending sector</p>
            </div>
            <div className="thumbnail-link">
                <p><b>P/B:</b> 3 vs 2</p>
                <p className="thumbnail">investors may value FFIs net assets more due to its brand recognition and market position</p>
            </div>
            <div className="thumbnail-link">
                <p><b>EV/EBITDA:</b> 16 vs 16</p>
                <p className="thumbnail">market consensus is that FFI is appropriately valued in terms of its profitability and growth prospects</p>
            </div>
            <div className="thumbnail-link">
                <p><b>Gross Margin:</b> 45% vs 55%</p>
                <p className="thumbnail">lower margin may indicate less efficient production or weak pricing power, despite FFIs supposed premium product offerings</p>
            </div>
            <div className="thumbnail-link">
                <p><b>Operating Margin:</b> 15% vs 25%</p>
                <p className="thumbnail">again, we see operating costs may be out of control relative to peers</p>
            </div>
            <div className="thumbnail-link">
                <p><b>ROE:</b> 18% vs 12%</p>
                <p className="thumbnail">generating more profit from its equity than peers, possibly due to its specialized focus</p>
            </div>
            <div className="thumbnail-link">
                <p><b>Debt-to-Equity:</b> 0.6 vs 0.4</p>
                <p className="thumbnail">FFI is more leveraged than peers, indicating increased risk and potential reward</p>
            </div>
            <div className="thumbnail-link">
                <p><b>Current Ratio:</b> 2.5 vs 1.8</p>
                <p className="thumbnail">strong liquidity position that can easily cover short-term liabilities</p>
            </div>
            <div className="thumbnail-link">
                <p><b>FCF Yield:</b> 4% vs 3.5%</p>
                <p className="thumbnail">relatively efficient cash flow management and solid sales conversion into cash</p>
            </div> 
        </div>
    </div>
);

//INSURANCE
export const InsuranceLife = (
    <div>
        <img className="opponent" src={insurance1} alt='prospect' />
        <div className="chars">
            <p><b>Name:</b> Ethan Kim</p>
            <p><b>Age:</b> 34</p>
            <p><b>Profession:</b> Software Engineer </p>
            <p><b>Marital Status:</b> Recently married, one kid</p>
            <p><b>Traits:</b> Family-Oriented, Prudent, Responsible</p>
        </div>
    </div>
);

export const InsuranceHealth = (
    <div>
        <img className="opponent" src={insurance3} alt='prospect' />
        <div className="chars">
            <p><b>Name:</b> Lauren Thompson</p>
            <p><b>Age:</b> 29</p>
            <p><b>Profession:</b> Graphic Designer </p>
            <p><b>Marital Status:</b> Single Mother of two</p>
            <p><b>Traits:</b> Caring, Resourceful, Pragmatic</p>
        </div>
    </div>
);

export const InsuranceHome = (
    <div>
        <img className="opponent" src={insurance2} alt='prospect' />
        <div className="chars">
            <p><b>Name:</b> David Anderson</p>
            <p><b>Age:</b> 35</p>
            <p><b>Profession:</b> Civil Engineer </p>
            <p><b>Marital Status:</b> Married, father of two</p>
            <p><b>Traits:</b> Risk-Aware, Conscientious, Pragmatic</p>
        </div>
        <div className="chars">
            <li>Owns a suburban home.</li>
            <li>Recently, a tree fell onto a neighbors porch, destroying it.</li>
        </div>
    </div>
);

// WOLF STREET
export const WolfStNTII = (
    <div>
        <img className="opponent" src={wolfst1} alt='prospect' />
        <div className="chars">
            <p><b>Name:</b> Jack Morrison</p>
            <p><b>Profession:</b> Retired Real Estate Agent</p>
            <p><b>Traits:</b> Competitive, Success-Oriented, Impressionable</p>
        </div>
    </div>
);

export const WolfStBGAC = (
    <div>
        <img className="opponent" src={wolfst2} alt='prospect' />
        <div className="chars">
            <p><b>Name:</b> Vivian Beaumont</p>
            <p><b>Profession:</b> Art Dealer</p>
            <p><b>Traits:</b> Sophisticated, Status-Oriented, Adventurous</p>
        </div>
    </div>
);

export const WolfStQLT = (
    <div>
        <img className="opponent" src={wolfst3} alt='prospect' />
        <div className="chars">
            <p><b>Name:</b> Alex Rivera</p>
            <p><b>Profession:</b> Startup Co-Founder</p>
            <p><b>Traits:</b> Tech Know-it-All, Risk-Taker, Impulsive</p>
        </div>
    </div>
);

// PORTFOLIO STRATEGY
export const PortStratDesc = (
    <div>
        <div className="desc">
            <h1>Investment Strategy</h1>
            <p>Investment Advisors offer expertise and professional money management in exchange for a commission. </p>
            <p>Financial Advisors will often enlist an Investment Advisor's services to help scale their own operation.</p>
            <p>In this scenario, you will need to convice a Financial Advisor of the merits of your investment strategy.</p>
            <p>Tailoring your pitch and strategy to the clients unique needs may help you close this deal.</p>
            <p>Money is a serious business. An FA is an expert, not to be easily manipulated by fancy word-play.</p>
            <p>Convince the FA that you have the best offering for their client!</p>
        </div>
    </div>
);

export const PortStratTom = (
    <div>
        <img className="opponent" src={fin1} alt='prospect' />
        <div className="chars">
            <p><b>Name:</b> Tom Jackson</p>
            <p><b>Profession:</b> Senior Financial Advisor</p>
            <p><b>Traits:</b> Educated, Classy, Formidable</p>
        </div>
    </div>
);

// BASIC INTERVIEW
export const BasicIntDev = (
    <div>
        <img className="opponent" src={BasicInt1} alt='prospect' />
        <div className="chars">
            <p><b>Name:</b> Claire Hamilton</p>
            <p><b>Profession:</b> Senior HR Manager</p>
            <p><b>Company:</b> Innovative Solutions Inc</p>
            <p><b>Traits:</b> Professional, Alert, Observant</p>
            <p><b>Skills:</b> Conflict Resolution, negotiation, sees you as more than a resume.</p>
        </div>
        <div className="chars">
            <li>Claire has worked recruitment for 15 years - she can see through BS</li>
            <li>She has shaped the existing team and can wont accept a bad fit.</li>
        </div>
    </div>
);

export const BasicIntCoord = (
    <div>
        <img className="opponent" src={BasicInt2} alt='prospect' />
        <div className="chars">
            <p><b>Name:</b> Michael Richardson</p>
            <p><b>Profession:</b> Corporate Recruitment</p>
            <p><b>Company:</b> Nexus Enterprise</p>
            <p><b>Traits:</b> Diplomatic, Friendly, Upbeat.</p>
            <p><b>Skills:</b> , Team-building, talent development.</p>
        </div>
        <div className="chars">
            <li>Michael wants candidates who will contribute, grow, and succeed.</li>
            <li>Your cultural fit is more important than your resume.</li>
        </div>
    </div>
);

export const BasicIntExec = (
    <div>
        <img className="opponent" src={BasicInt3} alt='prospect' />
        <div className="chars">
            <p><b>Name:</b> Vanessa Lee</p>
            <p><b>Profession:</b> Senior Executive</p>
            <p><b>Company:</b> Quantum Consulting</p>
            <p><b>Traits:</b> Organized, Assertive, Attentive</p>
            <p><b>Skills:</b> Corporate navigation, organization. Has mastered the art of reading people.</p>
        </div>
        <div className="chars">
            <li>Claire has worked recruitment for 15 years - she can see through BS</li>
            <li>She has shaped the existing team and can wont accept a bad fit.</li>
        </div>
    </div>
);

// TECHNICAL INTERVIEW
export const TechIntEng = (
    <div>
        <img className="opponent" src={engineer1} alt='engineer' />
        <div className="chars">
            <p><b>Name:</b> Carter Smith</p>
            <p><b>Profession:</b> Senior Engineering Manager</p>
            <p><b>Traits:</b> Experienced, Alert, Fast-Paced</p>
            <p><b>Skills:</b> Mentorship, Practical Physics, Project Management</p>
        </div>
        <div className="chars">
            <li>Track record of leading multidisciplinary teams and delivering projects within strict timelines and budgets</li>
            <li>Seeks candidates who not only have the necessary technical skills but also demonstrate potential for growth and leadership</li>
        </div>
    </div>
);

export const TechIntSoft = (
    <div>
        <img className="opponent" src={software1} alt='engineer' />
        <div className="chars">
            <p><b>Name:</b> Jessica Richardson</p>
            <p><b>Profession:</b> Lead Software Engineer</p>
            <p><b>Traits:</b> Innovative, Approachable, Meticulous</p>
            <p><b>Skills:</b> Foresight, Development Methodology, Team Synergy</p>
        </div>
        <div className="chars">
            <li>Seeking a flexible candidate eager to learn a new placement within the team</li>
            <li>Open-source project contibutor and active member of local tech community</li>
        </div>
    </div>
);

export const TechIntSci = (
    <div>
        <img className="opponent" src={scientist1} alt='engineer' />
        <div className="chars">
            <p><b>Name:</b> Hiroshi Nakamura</p>
            <p><b>Profession:</b> Principal Research Scientist</p>
            <p><b>Traits:</b> Inquisitive, Patient, Precise</p>
            <p><b>Skills:</b> Statistical analysis, Cross-Disciplinary Collaboration, Grant Writing</p>
        </div>
        <div className="chars">
            <li>Led multiple high-impact projects, particularly in development of gene therapies</li>
            <li>Assesses candidates understanding of scientific principles and their ability to apply these principles in a research setting</li>
        </div>
    </div>
);

// CREATIVE INTERVIEW
export const CreateIntWrite = (
    <div>
        <img className="opponent" src={createint1} alt='engineer' />
        <div className="chars">
            <p><b>Name:</b> Audrey Harper</p>
            <p><b>Profession:</b> Editor-in-Chief</p>
            <p><b>Traits:</b> Articulate, Perceptive, Imaginative</p>
            <p><b>Skills:</b> Literary Knowledge, Editorial Expertise, Proofreading</p>
        </div>
        <div className="chars">
            <li>Evaluates creative potential, writing skills, and stylistic fit of candidates for writing roles within the publishing house</li>
            <li>Seeks writers with a unique voice that aligns with the publishing house's brand and literary vision</li>
        </div>
    </div>
);

export const CreateIntUI = (
    <div>
        <img className="opponent" src={interview2} alt='engineer' />
        <div className="chars">
            <p><b>Name:</b> Gabby Turner</p>
            <p><b>Profession:</b> Senior UI/UX Designer</p>
            <p><b>Traits:</b> Collaborative, User-Focused, Insightful</p>
            <p><b>Skills:</b> Grand Design Planning, Prototyping, User Psychology</p>
        </div>
        <div className="chars">
            <li>Assesses the candidates’ design skills, creative thinking, and technical understanding related to UI/UX</li>
            <li>Seeks problem-solving skills and good handling of design challenges</li>
        </div>
    </div>
);

export const CreateIntSocial = (
    <div>
        <img className="opponent" src={createint2} alt='engineer' />
        <div className="chars">
            <p><b>Name:</b> Gabriel Contway</p>
            <p><b>Profession:</b> Social Media Director</p>
            <p><b>Traits:</b> Strategic, Adaptable, Data-Driven</p>
            <p><b>Skills:</b> Campaign Management, Content Creation, Analytics and Reporting</p>
        </div>
        <div className="chars">
            <li>Track record of increasing online presence and engagement for various brands</li>
            <li>Recognized for running successful influencer partnerships and viral marketing campaigns </li>
            <li>Seeks a blend of creative and marketing strategy expertise - ideas combined with practical execution skills</li>
        </div>
    </div>
);

// LEADERSHIP INTERVIEW
export const LeadIntCOO = (
    <div>
        <img className="opponent" src={coo} alt='engineer' />
        <div className="chars">
            <p><b>Name:</b> Jennifer Grant</p>
            <p><b>Profession:</b> Board Member</p>
            <p><b>Traits:</b> Professional, Cunning, Fierce</p>
            <p><b>Skills:</b> Decision Making, Managing People, Eloquent Communication</p>
        </div>
        <div className="chars">
            <li>Exudes a polished and competent demeanor, immediately establishing a tone of seriousness and focus</li>
            <li>Track record of successful company turnarounds, operational optimizations, and has led teams through significant transition</li>
            <li>Her evaluation will heavily influence the final decision made by the board of directors</li>
        </div>
    </div>
);

export const LeadIntCFO = (
    <div>
        <img className="opponent" src={cfo} alt='engineer' />
        <div className="chars">
            <p><b>Name:</b> Kenji Sato</p>
            <p><b>Profession:</b> Chairman of the Board</p>
            <p><b>Traits:</b> High Integrity, Authoritative, Meticulous</p>
            <p><b>Skills:</b> Corporate Financial Analysis, Risk Management, Negotiation</p>
        </div>
        <div className="chars">
            <li>Evaluates not just the technical knowledge of the candidates but also their strategic and leadership potential</li>
            <li>May challenge candidates with scenarios of financial crises or rapid growth to gauge their response strategies</li>
            <li>His approval is often the final step in the selection process for the CFO role, given his esteemed position and expertise</li>
        </div>
    </div>
);

export const LeadIntCEO = (
    <div>
        <img className="opponent" src={ceo} alt='engineer' />
        <div className="chars">
            <p><b>Name:</b> Rich Sterling</p>
            <p><b>Profession:</b> Senior Partner / Board Member</p>
            <p><b>Traits:</b> Visionary, Experienced, Charismatic</p>
            <p><b>Skills:</b> Business Acumen, Execution, Public Speaking</p>
        </div>
        <div className="chars">
            <li>Rich is looking not only for competence but also for chemistry and alignment with the company's culture and values</li>
            <li>He will challenge your leadership style, crisis management, and ability to innovate and adapt</li>
            <li>He is the gatekeeper for the CEO position. His approval is critical.</li>
        </div>
    </div>
);

// SALARY NEGOTIATION
export const SalaryNegDev = (
    <div>
        <img className="opponent" src={negotiation3} alt='prospect' />
        <div className="chars">
            <p><b>Name:</b> Thomas Reed</p>
            <p><b>Profession:</b> CFO</p>
            <p><b>Company:</b> Apex Dynamics</p>
            <p><b>Traits:</b> Fair-Minded, Empathetic, Steadfast</p>
            <p><b>Skills:</b> Numbers, pressuring, controlling the conversation.</p>
        </div>

        <div className="chars">
            <p>You've been with the startup for over a year, developing complex algorithms 
                and contributing to AI projects. With a new funding round completed, 
                it's time to discuss a salary increase reflecting your critical role in the 
                company's growth.</p>
        </div>
    </div>
);

export const SalaryNegProj = (
    <div>
        <img className="opponent" src={negotiation1} alt='prospect' />
        <div className="chars">
            <p><b>Name:</b> Julia Chen</p>
            <p><b>Profession:</b> COO</p>
            <p><b>Company:</b> Vertex Dynamics</p>
            <p><b>Traits:</b> Pragmatic, Respectful, Analytical</p>
            <p><b>Skills:</b> Confident leadership, fairness, values each member of her team.</p>
        </div>
        <div className="chars">
            <li>Julia struggles with retention - demand for your skills is high.</li>
            <li>Aims to make you feel valued and motivated, without caving on too many financial demands.</li>
        </div>
        <div className="chars">
            <p>After a year of managing high-profile construction projects, consistently 
                delivering results, and optimizing resources, you're in talks with senior 
                management about a raise. Your leadership has not only driven projects to 
                success but also fostered team growth.</p>
        </div>
    </div>
);

export const SalaryNegDesign = (
    <div>
        <img className="opponent" src={negotiation4} alt='prospect' />
        <div className="chars">
            <p><b>Name:</b> Daniel Moreno</p>
            <p><b>Profession:</b> Creative Director</p>
            <p><b>Company:</b> Spectrum Design</p>
            <p><b>Traits:</b> Business-savvy, process-oriented, appreciative</p>
            <p><b>Skills:</b> Stakeholder management, clear-and-concise, takes advantage of mistakes.</p>
        </div>
        <div className="chars">
            <li>Dont be disarmed by Daniels kindness. Despite his right-brain orientation, to him you are a cog in the machine.</li>
            <li>You need to really prove your excellence to get anywhere.</li>
        </div>
        <div className="chars">
            <p>With a year under your belt at the advertising agency, your designs have won 
                pitches and retained top-tier clients. As the agency gears up for a competitive 
                year, you believe your creative input warrants financial recognition.</p>
        </div>
    </div>
);

// REAL ESTATE
export const RealEstateDesc = (
    <div>
        <div className="desc">
            <h1>Real Estate Negotiation</h1>
            <p>You are an experienced Real Estate Agent. A prospective buyer has shown significant interest in one of your properties. </p>
            <p>Your goal is to secure a purchase agreement from the prospective buyer. Find out whats holding them back and close the sale.</p>
        </div>
        <div className="desc">
            <h1>Negotiation Dynamics</h1>
            <p>The buyer believes the price can be lowered due to market time </p>
            <p>The seller is motivated but does not want to drop the price significantly, fearing it might undervalue the property.</p>
            <p></p>
        </div>
        <div className="desc">
            <h1>Home Details</h1>
            <p>The property is a single-family home in a desirable neighborhood with good schools and amenities.</p>
            <p>The house is listed at a competitive market price, but it has been on the market for a few months.</p>
        </div>
    </div>
);

export const RealEstateLinda = (
    <div>
        <img className="opponent" src={realestate1} alt='engineer' />
        <div className="chars">
            <p><b>Name:</b> Linda Katz</p>
            <p><b>Profession:</b> Senior Project Manager for a Tech Company</p>
            <p><b>Traits:</b> Detail-oriented, cautious, and value-conscious</p>
        </div>
        <div className="chars">
            <li>Looking to buy a family home, is in no rush, and wants to make a wise investment</li>
        </div>
    </div>
);

// INVESTMENT PITCH
export const InvestmentPitchUrban = (
    <div>
        <img className="opponent" src={pitch1} alt='prospect' />
        <div className="chars">
            <p><b>Name:</b> Ben Greyson</p>
            <p><b>Profession:</b> Venture Capitalist</p>
            <p><b>Traits:</b> Skeptical, Long-Term Mindset, Trusts his Instincts</p>
            <p><b>Skills:</b> Financial Modeling, Guidance, Market Insight</p>
        </div>
        <div className="chars">
            <p>Ben believes in backing strong teams with clear visions. 
                He looks for companies that have a clear path to long-term profitability. 
                He values transparency and prefers to invest in founders who are upfront 
                about the risks and challenges ahead.</p>
        </div>
    </div>
);

export const InvestmentPitchTreat = (
    <div>
        <img className="opponent" src={deal6} alt='prospect' />
        <div className="chars">
            <p><b>Name:</b> Luna Florence</p>
            <p><b>Profession:</b> Venture Capitalist</p>
            <p><b>Traits:</b> Principled, Born Leader, Disruptor</p>
            <p><b>Skills:</b> Exceptional Predictive Power, Rallying the Troops for a Cause</p>
        </div>
        <div className="chars">
            <p>Luna is a partner to the founders she backs. She believes in investing 
                in companies that have a strong financial foundation and 
                potential to make significant impacts on society.</p>
        </div>
    </div>
);

export const InvestmentPitchStem = (
    <div>
        <img className="opponent" src={pitch2} alt='prospect' />
        <div className="chars">
            <p><b>Name:</b> Rico Hawthorne</p>
            <p><b>Profession:</b> Venture Capitalist</p>
            <p><b>Traits:</b> Risk-Addicted, Deal-Maker, High Standards</p>
            <p><b>Skills:</b> Strong reputation, Motivator, fails fast and pivots faster</p>
        </div>
        <div className="chars">
            <p>Rico believes in investing in people as much as ideas. 
                He looks for passionate entrepreneurs with a clear vision 
                and the tenacity to overcome obstacles. Do you have what it takes to work under his wing?</p>
        </div>
    </div>
);

// US POLITICS
export const UsPoliticsGreen = (
    <div>
        <img className="opponent" src={uspolitics1} alt='prospect' />
        <div className="chars">
            <p><b>Name:</b> Diana Caldwell</p>
            <p><b>Profession:</b> Senator</p>
            <p><b>Traits:</b> Compelling, Scientific, Determined</p>
            <p><b>Skills:</b> Public Speaking, Constituent Engagement, Detailed</p>
        </div>
        <div className="chars">
            <li>Environmental Policy MD with several published papers on viability of sustainable energy</li>
            <li>Evidence-based policy-maker</li>
        </div>
        <div className="chars">
            <div className="difficulty-options">
                <input type="radio" id="for" name="favor" value="Support" defaultChecked/>
                <label htmlFor="for">For</label>
                <input type="radio" id="against" name="favor" value="Oppose"/>
                <label htmlFor="against">Against</label>
            </div>
        </div>
    </div>
);

export const UsPoliticsGun = (
    <div>
        <img className="opponent" src={uspolitics2} alt='prospect' />
        <div className="chars">
            <p><b>Name:</b> Mike Thompson</p>
            <p><b>Profession:</b> Senator</p>
            <p><b>Traits:</b> Resolute, Influential, Dedicated</p>
            <p><b>Skills:</b> Debate, Emotional Intelligence, Coalition Building.</p>
        </div>
        <div className="chars">
            <li>Mike values individual rights and public safety</li>
            <li>Well versed in constitutional law moral argumentation.</li>
        </div>
        <div className="chars">
            <div className="difficulty-options">
                <input type="radio" id="for" name="favor" value="For" defaultChecked/>
                <label htmlFor="for">For</label>
                <input type="radio" id="against" name="favor" value="Against"/>
                <label htmlFor="against">Against</label>
            </div>
        </div>
    </div>
);

export const UsPoliticsUBI = (
    <div>
        <img className="opponent" src={uspolitics3} alt='prospect' />
        <div className="chars">
            <p><b>Name:</b> Ava Richardson</p>
            <p><b>Profession:</b> Senator</p>
            <p><b>Traits:</b> Insightful, Uniter, Understanding</p>
            <p><b>Skills:</b> Public Engagement, clarity-of-thought, controlling the conversation.</p>
        </div>
        <div className="chars">
            <li>Strong grasp of economic theory and its practical applications.</li>
            <li>Finds common ground in contentious issues, she is able to build coalitions and consensus.</li>
        </div>
        <div className="chars">
            <div className="difficulty-options">
                <input type="radio" id="for" name="favor" value="For" defaultChecked/>
                <label htmlFor="for">For</label>
                <input type="radio" id="against" name="favor" value="Against"/>
                <label htmlFor="against">Against</label>
            </div>
        </div>
    </div>
);

const Pre = () => {
    UserChoices.rehydrate();

    let navigate = useNavigate();



    const [isDetailVisible, setIsDetailVisible] = useState(false);
    const [isProcessVisible, setIsProcessVisible] = useState(false);
    const [descInfo, setDescInfo] = useState('');
    const [charInfo, setCharInfo] = useState('');
    const [processInfo, setProcessInfo] = useState('');
    const winsArr = useRef(UserChoices.wins ? UserChoices.wins : []);

    // Force Render
    const renderTrigger = useRef(() => {});
    const [key, setKey] = useState(false);
    renderTrigger.current = () => {
        setKey(!key);
    };

    // Load wins
    useEffect(() => {
        
        const getWins = async () => {
            await retrieveWins(auth?.currentUser?.uid).then((data) => {
                winsArr.current = data.winsArr;
                //console.log("Wins: " + data.winsArr);
                UserChoices.setWins(data.winsArr);
                renderTrigger.current();
            });
        }

        // Pull from database if signed in
        if (auth?.currentUser?.uid) {
            //console.log("Getting wins");
            getWins();
        }
    }, [navigate]);

    async function retrieveWins(userId) {
        return fetch(DOMAIN + "/get-user-wins", {
          method: "POST",
          headers: {
              "Content-Type": "application/json",
          },
          mode: "cors",
          body: JSON.stringify({ 
              firebaseId: userId,
          }),
        })
        .then(res => {
            if (!res.ok) {
              throw new Error(`HTTP error! Status: ${res.status}`);
            }
            return res.json();
        })
        .then(response => {
            return response;
        })
        .catch((e) => {
            console.log("Error: " + e);
        });
      }


    const validatePlayAvailable = async () => {
        try {
            if (auth?.currentUser?.uid) {
                await getPlayData(auth?.currentUser?.uid).then((data) => {
                    console.log("Play data: " + JSON.stringify(data));
                    if (data.premium) {
                        if (data.dailyConvos < 15) {
                            goToGame();
                        } else {
                            alert("You have no plays left today. Please come back tomorrow.");
                        }
                    } else {
                        if (data.monthlyConvos < 3 && !UserChoices.scenario.includes('custom')) {
                            goToGame();
                        } else {
                            navigate('../Subscribe');
                        }
                    }
                });
            } else {
                alert("Please sign in to play.");
                navigate('../');
            }
            
        } catch (err) {
            console.log("Failed to retrieve play data: " + err);
        }

    }

    // Non-premium users get 2 free plays (numConvos)
    // Premium users get 15 daily plays (dailyConvos)
    async function getPlayData(userId) {
        return fetch(DOMAIN + "/get-user-play-availability", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            mode: "cors",
            body: JSON.stringify({ 
                firebaseId: userId,
            }),
        })
        .then(res => {
            if (!res.ok) {
                throw new Error(`HTTP error! Status: ${res.status}`);
            }
            return res.json();
        })
        .then(response => {
            return response;
        })
        .catch((e) => {
            console.log("Error: " + e);
        });
    }

    const goToGame = () => {
        document.body.classList.remove("no-scroll");
        navigate('../Game');
    };

    const goHome = () => {
        navigate('../');
    }

    // Select a scenario
    const showDetail = (desc, characteristics, process) => {
        setDescInfo(desc);
        setCharInfo(characteristics);
        setProcessInfo(process);
        setIsDetailVisible(true);
        document.body.classList.add("no-scroll");
    };

    // Exit 1
    const closeDetail = () => {
        setIsDetailVisible(false);
        document.body.classList.remove("no-scroll");
    };

    // Play 1
    const showProcess = () => {
        getSelectedDifficulty();
        getSelectedFavor();

        // Obtain custom user inputs
        if (UserChoices.scenario.substring(0, 10) === 'customsell') {
            getCustomSalesInput();
        } else if (UserChoices.scenario.substring(0, 9) === 'customint') {
            getCustomIntInput();
        } else if (UserChoices.scenario.substring(0, 9) === 'customneg') {
            getCustomNegInput();
        } else if (UserChoices.scenario.substring(0, 12) === 'customdebate') {
            getCustomDebateInput();
        }
        
        setIsDetailVisible(false);
        setIsProcessVisible(true);
    };

    // Back 1
    const closeProcess = () => {
        setIsProcessVisible(false);
        setIsDetailVisible(true);
    };

    function ProcessPopup({ process }) {
        return (
            <div className="detail-overlay">
                <div className="process-content">
                    <div className="sales-process">
                        {process}
                        <div className="backorplay">
                            <button className="standard-button" onClick={closeProcess}>Back</button>
                            <button className="standard-button" onClick={validatePlayAvailable}>Play</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }


    function DetailPopup({ desc, characteristics, }) {
        return (
            <div className="detail-overlay">
                <div className="detail-content">
                    <div className="detail-left">
                        <div className="detail-characteristics">
                            {characteristics}
                            <div className="backorplay">
                                <button className="standard-button" onClick={closeDetail}>Exit</button>
                                <button className="standard-button" onClick={showProcess}>Play</button>
                            </div>
                            <div className="difficulty-options">
                                <input type="radio" id="easy" name="difficulty" value="Easy" defaultChecked/>
                                <label htmlFor="easy">Easy</label>
                                <input type="radio" id="medium" name="difficulty" value="Medium"/>
                                <label htmlFor="medium">Medium</label>
                                <input type="radio" id="hard" name="difficulty" value="Hard"/>
                                <label htmlFor="hard">Hard</label>
                            </div>
                        </div>
                    </div> 
                    <div className="detail-right">
                        {desc}
                    </div>
                </div>
            </div>
        );
    }

    function getSelectedDifficulty() {
        const radios = document.getElementsByName('difficulty');
        let selectedValue;
        radios.forEach((radio) => {
          if (radio.checked) {
            selectedValue = radio.value;
          }
        });
        //console.log("Difficulty: " + selectedValue);
        UserChoices.setDifficulty(selectedValue);
    }

    function getSelectedFavor() {
        const radios = document.getElementsByName('favor');
        let selectedValue;
        radios.forEach((radio) => {
          if (radio.checked) {
            selectedValue = radio.value;
          }
        });
        //console.log("Difficulty: " + selectedValue);
        UserChoices.setForAgainst(selectedValue);
    }

    function getCustomSalesInput() {
        let customProduct = document.getElementById('productName').value;
        let customDes = document.getElementById('productDescription').value;
        let customContext = document.getElementById('productContext').value;
        UserChoices.setCustomSellProduct(customProduct);
        UserChoices.setCustomSellDesc(customDes);
        UserChoices.setCustomSellContext(customContext);
    }

    function getCustomIntInput() {
        let customTitle = document.getElementById('jobName').value;
        let customCompany = document.getElementById('companyName').value;
        let customDes = document.getElementById('jobDescription').value;

        UserChoices.setCustomIntTitle(customTitle);
        UserChoices.setCustomIntCompany(customCompany);
        UserChoices.setCustomIntDesc(customDes);
        console.log("Custom Title: " + UserChoices.customintTitle);
        console.log("Custom Company: " + UserChoices.customintCompany);
        console.log("Custom Description: " + UserChoices.customintDesc);
    }

    function getCustomNegInput() {
        let goal = document.getElementById('negParty1').value;
        let countergoal = document.getElementById('negParty2').value;
        UserChoices.setCustomNegGoal(goal);
        UserChoices.setCustomNegCounter(countergoal);
        console.log("Custom Goal: " + UserChoices.customnegGoal);
        console.log("Custom Counter: " + UserChoices.customnegCounter);
    }

    function getCustomDebateInput() {
        let position = document.getElementById('debateParty1').value;
        UserChoices.setCustomDebatePos(position);
        console.log("Custom Position: " + UserChoices.customdebatePos);
    }

    // CAR SALES
    const CarSalesProcess = (
        <div>
            <h1>Eloquent Sales Process</h1>
            <div className="backorplay">
                <button className="standard-button" onClick={closeProcess}>Back</button>
                <button className="standard-button" onClick={validatePlayAvailable}>Play</button>
            </div>
            <div className="step">
                <div className="step-number">Step 1: Introduction and Rapport Building</div>
                <div className="step-description">Make a good first impression and establish a friendly atmosphere. Prospects tend to be naturally defensive to salespeople. You must get past this barrier.</div>
            </div>
            <div className="step">
                <div className="step-number">Step 2: Needs Assessment</div>
                <div className="step-description">Ask questions. Do you know the prospect's needs, preferences, budget, etc? Don't expect to close a sale if you don't understand a prospects desires.</div>
            </div>
            <div className="step">
                <div className="step-number">Step 3: Selection and Presentation</div>
                <div className="step-description">Pitch a provided product option. Explain the benefits and features. A good salesperson will keep his pitch relevant to the prospects established preferences.</div>
            </div>
            <div className="step">
                <div className="step-number">Step 4: Objection Handling</div>
                <div className="step-description">A wide variety of objections can occur at any point. Gracefully dispel concerns, keep the prospect talking and keep the sale alive. Help the prospect rationalize the purchase to themselves.</div>
            </div>
            <div className="step">
                <div className="step-number">Step 5: Close the Sale</div>
                <div className="step-description">Obtain verbal confirmation of the sale. Eloquently CLOSE.</div>
            </div>
        </div>
    );
    
    // COLD CALL
    const ColdCallDesc = (
        <div>
            <div className="desc">
                <h1>Cold Calling</h1>
                <p>As the owner of a Social Media Marketing Agency, your firm specializes in elevating the online presence of small to medium-sized companies, funneling paying clients to your customers.</p>
                <p>Your task is to reach out to potential clients who could benefit from a more strategic approach to their marketing efforts.</p>
                <p>Select a target niche. Your immediate objective: lock in a future meeting with the prospect. Sparking their interest and get them excited to work with you.</p>
            </div>
            <div className='options'>
                <div>
                    <img onClick={() => {setCharInfo(ColdCallLandscape); 
                                        UserChoices.setScenario('coldcalllandscape');}} 
                                        src={landscape1} alt='landscaping' />
                    
                    <h3>Landscaping Services</h3>
                    <ul className="pros">
                        <li>Visual Appeal: Easily contrast before-and-after transformations</li>
                        <li>Seasonal: Opportunities for targeted campaigns during peak cycle</li>
                        <li>Potential community engagement</li>
                    </ul>
                    <ul className="cons">
                        <li>Saturation: Landscapers might already be utilizing social media effectively</li>
                        <li>Seasonal: Slower off-seasons</li>
                        <li>Content Variety: Finding diverse content beyond project showcases might be challenging.</li>
                    </ul>
                </div>
                <div>
                    <img onClick={() => {setCharInfo(ColdCallMassage); 
                                        UserChoices.setScenario('coldcallmassage')}} 
                                        src={massage1} alt='massage' />
                    <h3>Massage Therapy Center</h3>
                    <ul className="pros">
                        <li>Wellness Trend: Self-care is popular</li>
                        <li>Repeat Engagement: Regular updates, customer testimonials, and wellness tips to keep followers engaged.</li>
                        <li>Emotional Connection: Content can resonate with followers seeking relaxation and stress relief</li>
                    </ul>
                    <ul className="cons">
                        <li>Privacy Concerns: Navigate client privacy sensitively</li>
                        <li>Niche: Expanding to a broader audience may prove difficult</li>
                        <li>Misconceptions: Avoid misrepresenting benefits or fostering unrealistic expectations.</li>
                    </ul>
                </div>
                <div>
                    <img onClick={() => {setCharInfo(ColdCallVeterinary); 
                                        UserChoices.setScenario('coldcallvet')}} 
                                        src={veterinarian1} alt='veterinary' />
                    <h3>Veterinary Clinics</h3>
                    <ul className="pros">
                        <li>High Engagement: Pet content is extremely popular</li>
                        <li>Emotional Storytelling: Share heartwarming stories of pet care and recovery</li>
                        <li>Educational Content: Provide valuable pet health tips, promoting the clinic as a trusted source</li>
                    </ul>
                    <ul className="cons">
                        <li>Sensitivity: Balance educational and promotional content without appearing insensitive to pet health problems</li>
                        <li>Diverse Audience Expectations: Cater to the wide range of pet owners</li>
                    </ul>
                </div>
            </div>
        </div>
    );

    const ColdCallProcess = (
        <div>
            <h1>Eloquent Cold Call Process</h1>
            <div className="backorplay">
                <button className="standard-button" onClick={closeProcess}>Back</button>
                <button className="standard-button" onClick={validatePlayAvailable}>Play</button>
            </div>
            <div className="step">
                <div className="step-number">Step 1: Introduction</div>
                <div className="step-description">First impressions are everything. The prospect doesn't know you and is naturally defensive. Establish yourself as a professional worthy of consideration.</div>
            </div>
            <div className="step">
                <div className="step-number">Step 2: Qualification</div>
                <div className="step-description">Is the call worth your (and the prospects) time? We know they are decision makers, but how could they benefit from what you are selling?</div>
            </div>
            <div className="step">
                <div className="step-number">Step 3: Value Proposition</div>
                <div className="step-description">'Gap Selling' is one example of a sales technique. This method contrasts the prospects current state vs the prospects perfect ideal (which you are happy to provide!)</div>
            </div>
            <div className="step">
                <div className="step-number">Step 4: Objection Handling</div>
                <div className="step-description">A wide variety of objections can occur at any point. Gracefully dispel concerns, keep the prospect talking and dont let them hang up. Help the prospect rationalize why a meeting would be worthwhile.</div>
            </div>
            <div className="step">
                <div className="step-number">Step 5: Book the Meeting</div>
                <div className="step-description">Obtain verbal confirmation of the meeting date and time.</div>
            </div>
        </div>
    );

    // WALL ST SIMULATOR
    const WallStDesc = (
        <div>
            <div className="desc">
                <h1>Wall Street Simulator</h1>
                <p>As a savvy investment broker, you maintain a network of wealthy institutional investors seeking the highest possible risk-adjusted returns. Your commission is earned when they trade through you.</p>
                <p>Your mission is to analyze, pitch, and convince seasoned investors to allocate capital towards these stocks.</p>
                <p>The possible arguments are unlimited - ranging through macro, fundamentals, and technicals.</p>
                <p>Select an investor/stock to pitch.</p>
            </div>
            <div className='options'>
                <div>
                    <img onClick={() => {setCharInfo(WallStGMT); 
                                        UserChoices.setScenario('wallstgmt');}} 
                                        src={wallst1} alt='wallstreet' />
                    
                    <h3>GlobalMedTech (GMT)</h3>
                    <h3>(Technology/Health)</h3>
                    <p>GMT is pioneering the integration of artificial intelligence in medical diagnostics. 
                        Advanced algorithms analyze medical imaging data, aiding physicians in identifying malignancies 
                        at their nascent stages</p>
                    <ul className="pros">
                        <li>Innovative: At the forefront of medically applied AI - high growth potential</li>
                        <li>Core Product: AI Early Tumor Detection - statistically significant results</li>
                        <li>Strong pipeline of upcoming products pending approval: Namely detection methods for other cancers and heart conditions</li>
                        <li>Robust R&D investments indicate a strong commitment to innovation and positions it well for future breakthroughs</li>
                    </ul>
                    <ul className="cons">
                        <li>Competition: Constant pressure to innovate</li>
                        <li>Slow Adoption: AI may be statistically better, but doctors struggle to trust AI diagnosis</li>
                        <li>Dependence on Key Clients: Significant revenue is tied to three major hospitals</li>
                    </ul>
                </div>
                <div>
                    <img onClick={() => {setCharInfo(WallStEES); 
                                        UserChoices.setScenario('wallstees')}} 
                                        src={wallst4} alt='wallstreet' />
                    <h3>EcoEnergy Solutions (EES)</h3>
                    <h3>(Energy)</h3>
                    <p>EES is a hybrid provider, selling power directly to the grid through a mix of traditional fossil 
                        fuels and renewable sources. EES particularly interested in leveraging its infrastructure to establish
                        wind turbines on its properties.</p>
                    <ul className="pros">
                        <li>Diversified Energy Mix: Oil, Natural Gas, Wind</li>
                        <li>Stable Revenue Streams: With oil projects averaging $50/barrel breakeven and secured long-term NatGas contracts, revenue is largely dependable</li>
                        <li>Government Support: Elegible for subsidies and tax incentives due to wind projects</li>
                    </ul>
                    <ul className="cons">
                        <li>Transition Infefficiencies: Balancing standard operations with renewable investments can be complex and may lead to sub-optimal operations</li>
                        <li>High capital costs are associated with infrastructure-heavy projects</li>
                        <li>Commodity Price Fluctuations: Lower Oil/NatGas prices reduces revenues which could impact capital available for wind projects.</li>
                    </ul>
                </div>
                <div>
                    <img onClick={() => {setCharInfo(WallStFFI); 
                                        UserChoices.setScenario('wallstffi')}} 
                                        src={wallst3} alt='wallstreet' />
                    <h3>Future Foods Int. (FFI)</h3>
                    <h3>(Consumer Staples)</h3>
                    <p>FFI offers personalized nutrition plans where customers can get their food 
                        genetically tailored to their health needs. By analyzing a customer's DNA 
                        sample, FFI crafts and ships customized meals optimized for health, taste, and 
                        dietary requirements.</p>
                    <ul className="pros">
                        <li>Well-aligned with the growing global trend toward health-conscious eating and sustainability, with a focus on alternative, healthy food products</li>
                        <li>Global Footprint: Global demand is only increasing, FFIs international presence positions it to capitalize and reduce dependency on any single region</li>
                        <li>Innovative product development keeps FFI at the cuttting edge of consumer trends</li>
                    </ul>
                    <ul className="cons">
                        <li>Competition: Alternative food sector is becoming increasingly crowded</li>
                        <li>Supply Chain Risks: As a global company, FFI may face risks related to international trade tensions, supply chain disruptions, or fluctuations in commodity prices</li>
                        <li>Quality Control: With a broad product range and high production volume, maintaining consistent quality is critical. Any quality slips could harm the brand's reputation.</li>
                    </ul>
                </div>
            </div>
        </div>
    );

    const WallStProcess = (
        <div>
            <h1>Eloquent Broker Sales Process</h1>
            <div className="backorplay">
                <button className="standard-button" onClick={closeProcess}>Back</button>
                <button className="standard-button" onClick={validatePlayAvailable}>Play</button>
            </div>
            <div className="step">
                <div className="step-number">Step 1: Preparation</div>
                <div className="step-description">Gather as much intel as possible to understand your targets strategy and preferences.</div>
            </div>
            <div className="step">
                <div className="step-number">Step 2: Re-Introduction</div>
                <div className="step-description">This is a long-standing relationship. Re-introduce yourself and state the purpose of the call.</div>
            </div>
            <div className="step">
                <div className="step-number">Step 3: Rapport Building + Needs Assessment</div>
                <div className="step-description">Are any of your prospects current portfolio needs not being met? Can you fulfill these needs?</div>
            </div>
            <div className="step">
                <div className="step-number">Step 4: Pitch the Stock</div>
                <div className="step-description">Highlighting strengths and relevance to the prospects strategy.</div>
            </div>
            <div className="step">
                <div className="step-number">Step 5: Handle Objections</div>
                <div className="step-description">Address risks and concerns.</div>
            </div>
            <div className="step">
                <div className="step-number">Step 6: Call to Action</div>
                <div className="step-description">Encourage the prospect to initiate a purchase.</div>
            </div>
        </div>
    );

    // INSURANCE
    const InsuranceDesc = (
        <div>
            <div className="desc">
                <h1>Insurance Sales</h1>
                <p>You are a professional insurance salesman. You earn your commission by calling and closing warm leads.</p>
                <p>Your goal is to convince the prospect to spend a monthly fee for services they hopefully never need to use.</p>
                <p>The economy is tight, and these prospects dont have much discretionary income. Yet, better to pay small sums now rather than be ruined by an accident.</p>
                <p>Select the type of insurance you'd like to sell.</p>
            </div>
            <div className='options'>
                <div>
                    <img onClick={() => {setCharInfo(InsuranceLife); 
                                        UserChoices.setScenario('insurancelife');}} 
                                        src={insurance1} alt='life insurance' />
                    
                    <h3>Life Insurance</h3>
                </div>
                <div>
                    <img onClick={() => {setCharInfo(InsuranceHealth); 
                                        UserChoices.setScenario('insurancehealth')}} 
                                        src={insurance3} alt='health insurance' />
                    <h3>Health Insurance</h3>
                </div>
                <div>
                    <img onClick={() => {setCharInfo(InsuranceHome); 
                                        UserChoices.setScenario('insurancehome')}} 
                                        src={insurance2} alt='home insurance' />
                    <h3>Home Insurance</h3>
                </div>
            </div>
        </div>
    );

    const InsuranceProcess = (
        <div>
            <h1>Eloquent Cold Call Process</h1>
            <div className="backorplay">
                <button className="standard-button" onClick={closeProcess}>Back</button>
                <button className="standard-button" onClick={validatePlayAvailable}>Play</button>
            </div>
            <div className="step">
                <div className="step-number">Step 1: Introduction</div>
                <div className="step-description">First impressions are everything. A warm lead knows who you are, but you should still establish yourself as a professional worthy of consideration.</div>
            </div>
            <div className="step">
                <div className="step-number">Step 2: Build Rapport</div>
                <div className="step-description">Show genuine interest in the leads personal situation to understand their specific needs.</div>
            </div>
            <div className="step">
                <div className="step-number">Step 3: Value Proposition</div>
                <div className="step-description">'Gap Selling' is one example of a sales technique. This method contrasts the prospects current state vs the prospects perfect ideal (which you are happy to provide!)</div>
            </div>
            <div className="step">
                <div className="step-number">Step 4: Objection Handling</div>
                <div className="step-description">A wide variety of objections can occur at any point. Gracefully dispel concerns. Help the prospect rationalize their purchase.</div>
            </div>
            <div className="step">
                <div className="step-number">Step 5: Close</div>
                <div className="step-description">Obtain verbal confirmation of the sale.</div>
            </div>
        </div>
    );

    // WOLF STREET
    const WolfStDesc = (
        <div>
            <div className="desc">
                <h1>Wolf Street Simulator</h1>
                <p>As a shady investment broker, you seek out rich and gullible penny stock buyers with $10k to burn. Your commission is earned when they trade through you.</p>
                <p>These companies have a very small chance of success. You might stand a better chance if you ignore the fundamentals and use manipulative tactics along with some neurolinguistic programming to ensure success.</p>
                <p>Clearly, this scenario is unethical. We hope that you will take these skills and act morally in the real world!</p>
                <p>Select a prospect/stock to pitch.</p>
            </div>
            <div className='options'>
                <div>
                    <img onClick={() => {setCharInfo(WolfStNTII); 
                                        UserChoices.setScenario('wolfstntii');}} 
                                        src={wolfst1} alt='wallstreet' />
                    
                    <h3>NanoTech Innovations (NTII)</h3>
                    <p>Claims to be at the forefront of nanotech. They tout a revolutionary process for creating 
                        high-capacity batteries using a proprietary, and entirely secretive, nano-structuring technique.</p>
                    <ul className="cons">
                        <li>Staff is elusive about providing data or demonstrations</li>
                        <li>Unverified, possibly fabricated research</li>
                        <li>Operates from a makeshift lab out of a small converted storage unit</li>
                    </ul>
                </div>
                <div>
                    <img onClick={() => {setCharInfo(WolfStBGAC); 
                                        UserChoices.setScenario('wolfstbgac')}} 
                                        src={wolfst2} alt='wallstreet' />
                    <h3>BioGrow AgriTech (BGAC)</h3>
                    <p>Boasts a new hydroponic growth system enabled by autonomous roaming robots 
                        that can supposedly increase crop yields tenfold.</p>
                    <ul className="cons">
                        <li>Demonstrations are few and far between, some videos appear faked</li>
                        <li>Dire need of funding to continue operations</li>
                        <li>Operates from a large unmarked barn in a remote area.</li>
                    </ul>
                </div>
                <div>
                    <img onClick={() => {setCharInfo(WolfStQLT); 
                                        UserChoices.setScenario('wolfstqlt')}} 
                                        src={wolfst3} alt='wallstreet' />
                    <h3>QuantumLedger Token (QLT)</h3>
                    <p>Self-described cutting-edge cryptocurrency that uses purported quantum computing 
                        technologies to ensure unparalleled security and transaction speeds. 
                    </p>
                    <ul className="cons">
                        <li>Leadership composed of unknown figures in the crypto space</li>
                        <li>Vague and technical jargon-filled whitepapers</li>
                        <li>Flashy marketing campaigns, including social media influencer endorsements and announcements of partnerships with obscure tech companies</li>
                    </ul>
                </div>
            </div>
        </div>
    );

    // PORTFOLIO STRATEGY
    const PortStratProcess = (
        <div>
            <h1>Investment Advisor Business Development Process</h1>
            <div className="backorplay">
                <button className="standard-button" onClick={closeProcess}>Back</button>
                <button className="standard-button" onClick={validatePlayAvailable}>Play</button>
            </div>
            <div className="step">
                <div className="step-number">Step 1: Preparation</div>
                <div className="step-description">Collect your thoughts and understand your offer.</div>
            </div>
            <div className="step">
                <div className="step-number">Step 2: Introduction</div>
                <div className="step-description">Greet the FA and build rapport.</div>
            </div>
            <div className="step">
                <div className="step-number">Step 3: Tailored Presentation</div>
                <div className="step-description">Get down to business and make your case. How does your offer address specific needs and potentially enhance returns? Demonstrate value and differentiation.</div>
            </div>
            <div className="step">
                <div className="step-number">Step 4: Handle Objections</div>
                <div className="step-description">Truly address concerns. An FA will not be swayed to risk capital through tricky word play and manipulative tactics.</div>
            </div>
            <div className="step">
                <div className="step-number">Step 6: Close the Deal</div>
                <div className="step-description">Ensure the FA verbaly confirms their readiness to proceed.</div>
            </div>
        </div>
    );

    // BASIC INTERVIEW
    const BasicIntDesc = (
        <div>
            <div className="desc">
                <h1>Basic Interview</h1>
                <p>As a young and hungry jobseeker, you have scored a series of interviews </p>
                <p>Your task is to answer the interviewers questions in a professional and satisfactory manner.</p>
                <p>Select an interviewer. All questions will be generic and unspecific. The idea is to test your general interviewing abilities, not your qualifications for a specific job.</p>
            </div>
            <div className="options">
                <div>
                    <img onClick={() => {setCharInfo(BasicIntDev); 
                                        UserChoices.setScenario('basicintdev')} } 
                                        src={BasicInt1} alt='landscaping' />
                    <h3>Business Development Rep.</h3>
                    <ul className="pros">
                        <li>Interpersonal Skills</li>
                        <li>Drive and Enthusiasm</li>
                        <li>Adaptibilty</li>
                    </ul>
                </div>
                <div>
                    <img onClick={() => {setCharInfo(BasicIntCoord); 
                                        UserChoices.setScenario('basicintcoord')}} 
                                        src={BasicInt2} alt='massage' />
                    <h3>Marketing Coordinator</h3>
                    <ul className="pros">
                        <li>Initiative</li>
                        <li>Teamwork</li>
                        <li>Unique Thinking</li>
                    </ul>
                </div>
                <div>
                    <img onClick={() => {setCharInfo(BasicIntExec); 
                                        UserChoices.setScenario('basicintexec')}} 
                                        src={BasicInt3} alt='veterinary' />
                    <h3>Executive Assistant</h3>
                    <ul className="pros">
                        <li>Organization</li>
                        <li>Proactive</li>
                        <li>Time Management</li>
                    </ul>
                </div>
            </div>
        </div>
    );

    const BasicIntProcess = (
        <div>
            <h1>Eloquent Interview Process</h1>
            <div className="backorplay">
                <button className="standard-button" onClick={closeProcess}>Back</button>
                <button className="standard-button" onClick={validatePlayAvailable}>Play</button>
            </div>
            <div className="step">
                <div className="step-number">Step 1: Preparation</div>
                <div className="step-description">Research the company and interviewer.</div>
            </div>
            <div className="step">
                <div className="step-number">Step 2: Introduction</div>
                <div className="step-description">Make a good first impression.</div>
            </div>
            <div className="step">
                <div className="step-number">Step 3: Build Rapport</div>
                <div className="step-description">Demonstrate your interpersonal skills through small talk.</div>
            </div>
            <div className="step">
                <div className="step-number">Step 4: Respond to Questions</div>
                <div className="step-description">Answer questions directly, clearly and concisely.</div>
            </div>
            <div className="step">
                <div className="step-number">Step 5: Ask Questions</div>
                <div className="step-description">Having your own questions demonstrates your interest in the role.</div>
            </div>
            <div className="step">
                <div className="step-number">Step 6: Close the Interview</div>
                <div className="step-description">You might be offered the job on the spot. Be prepared to accept.</div>
            </div>
        </div>
    );

    // TECHNICAL INTERVIEW
    const TechIntDesc = (
        <div>
            <div className="desc">
                <h1>Technical Interview</h1>
                <p>You are a young and hungry technical professional. Hard skills are highly valued in the workplace and these interviews will test your domain-specific knowledge. </p>
                <p>Your task is to answer the interviewers questions in a professional, satisfactory, and accurate manner.</p>
                <p>Select an interviewer. Prepare to answer generalized questions related to the selected profession. The job itself is unspecific.</p>
            </div>
            <div className="options">
                <div>
                    <img onClick={() => {setCharInfo(TechIntEng); 
                                        UserChoices.setScenario('techinteng')} } 
                                        src={engineer1} alt='engineer' />
                    <h3>Engineering</h3>
                    <ul className="pros">
                        <li><b>Math: </b>calculus, differential equations, statistics</li>
                        <li><b>Physics: </b>mechanical, chemical, electrical, material</li>
                        <li><b>Software: </b>simulation, circuits, CAD</li>
                    </ul>
                </div>
                <div>
                    <img onClick={() => {setCharInfo(TechIntSoft); 
                                        UserChoices.setScenario('techintsoft')}} 
                                        src={software1} alt='software' />
                    <h3>Software</h3>
                    <ul className="pros">
                        <li><b>Programming Languages: </b>Javascript, C++, Python,, etc</li>
                        <li><b>SDLC: </b>Software Development Life Cycle</li>
                        <li><b>Algorithms: </b>sorting, search, graph</li>
                        <li><b>Data Structures: </b>objects, stacks, linked lists</li>
                    </ul>
                </div>
                <div>
                    <img onClick={() => {setCharInfo(TechIntSci); 
                                        UserChoices.setScenario('techintsci')}} 
                                        src={scientist1} alt='science' />
                    <h3>Scientific Research</h3>
                    <ul className="pros">
                        <li><b>Methodology: </b>scientific method, experimental design, statistical analysis</li>
                        <li><b>Laboratory Skills: </b>instrument operation, technique, safety</li>
                        <li><b>Technical Writing: </b>clear and concise research papers, proposals, documentation</li>
                        <li><b>Critical Thinking: </b>analyze data, pattern recognition</li>
                    </ul>
                </div>
            </div>
        </div>
    );

    // CREATIVE INTERVIEW
    const CreateIntDesc = (
        <div>
            <div className="desc">
                <h1>Creative Interview</h1>
                <p>You are a young and hungry creative professional. Truly creative skills are hard to come by. These interviews will test you abilities relative to a few generalized professions. </p>
                <p>Your task is to answer the interviewers questions in a professional and satisfactory manner, proving your capabilities.</p>
                <p>Select an interviewer. Prepare to answer generalized questions related to the selected profession. The job itself is unspecific.</p>
            </div>
            <div className="options">
                <div>
                    <img onClick={() => {setCharInfo(TechIntEng); 
                                        UserChoices.setScenario('createintwrite')} } 
                                        src={createint1} alt='writer' />
                    <h3>Creative Writer</h3>
                    <ul className="pros">
                        <li><b>Storytelling: </b>craft compelling narratives that engage and retain the audience's attention</li>
                        <li><b>Emotionally Intelligent: </b>advantageously tailor various tones and styles to grip your unique audiences</li>
                        <li><b>Research Skills: </b>adds authenticity and depth</li>
                    </ul>
                </div>
                <div>
                    <img onClick={() => {setCharInfo(CreateIntUI); 
                                        UserChoices.setScenario('createintui')}} 
                                        src={interview2} alt='UI Designer' />
                    <h3>UI/UX Design</h3>
                    <ul className="pros">
                        <li><b>User Psychology: </b>understand how site layout impacts user attitudes and click activity</li>
                        <li><b>Prototyping: </b>wireframes, storyboards, user flows</li>
                        <li><b>User Research: </b> conduct tests to gather feedback and gauge user needs</li>
                        <li><b>Visual Design: </b>effectively leverage color, layout, and typography</li>
                    </ul>
                </div>
                <div>
                    <img onClick={() => {setCharInfo(CreateIntSocial); 
                                        UserChoices.setScenario('createintsocial')}} 
                                        src={createint2} alt='social media' />
                    <h3>Social Media Specialist</h3>
                    <ul className="pros">
                        <li><b>Content Creation: </b>produce engaging content that resonates with the target audience and adheres to the brand</li>
                        <li><b>Platforms: </b>knowledge of social media platforms and best practices</li>
                        <li><b>Trend Awareness: </b>up to date with the latest social media trends and algorithm changes</li>
                        <li><b>Community Engagement: </b>foster a positive network of interaction</li>
                    </ul>
                </div>
            </div>
        </div>
    );

    // LEADERSHIP INTERVIEW
    const LeadIntDesc = (
        <div>
            <div className="desc">
                <h1>Leadership Interview</h1>
                <p>You are a young and experienced professional who has worked your way up the ranks. The ability to lead people both operationally and as a visionary can be incredibly valuable and rewarding. These interviews will test whether you're up to the task. </p>
                <p>Your task is to answer the interviewers questions in a professional and exceedingly qualified manner, proving your capabilities.</p>
                <p>Select an interviewer. Prepare to answer generalized leadership questions related to the selected role. The company is left unspecified because we are more concerned with assessing you leadership qualities.</p>
            </div>
            <div className="options">
                <div>
                    <img onClick={() => {setCharInfo(LeadIntCOO); 
                                        UserChoices.setScenario('leadintcoo')} } 
                                        src={coo} alt='COO' />
                    <h3>Chief Operating Officer</h3>
                    <p>A fast-growing tech startup is looking for a COO to streamline operations and support aggressive expansion plans</p>
                    <ul className="pros">
                        <li>Strategic planning and vision</li>
                        <li>Operational efficiency and process improvement</li>
                        <li>Leadership and team management</li>
                        <li>Adaptability and innovation in a fast-paced industry</li>
                        <li>Experience in scaling a business</li>
                    </ul>
                </div>
                <div>
                    <img onClick={() => {setCharInfo(LeadIntCFO); 
                                        UserChoices.setScenario('leadintcfo')}} 
                                        src={cfo} alt='CFO' />
                    <h3>Chief Financial Officer</h3>
                    <p>A multinational corporation is seeking a CFO to manage its financial strategy and operations, including navigating complex international tax laws and regulations.</p>
                    <ul className="pros">
                        <li>Financial acumen and expertise in corporate finance</li>
                        <li>Expertise with international finance and taxation</li>
                        <li>Risk management and compliance</li>
                        <li>Strategic thinking in terms of financial planning</li>
                        <li>Communication skills, especially in explaining financial concepts</li>
                    </ul>
                </div>
                <div>
                    <img onClick={() => {setCharInfo(LeadIntCEO); 
                                        UserChoices.setScenario('leadintceo')}} 
                                        src={ceo} alt='CEO' />
                    <h3>Chief Executive Officer</h3>
                    <p>A well-established manufacturing company is searching for a new CEO to revitalize the brand and lead the company into new markets</p>
                    <ul className="pros">
                        <li>Visionary leadership and ability to inspire and motivate</li>
                        <li>Expertise with corporate restructuring and change management</li>
                        <li>Innovative skills to foster market growth</li>
                        <li>Alignment with company values and mission</li>
                    </ul>
                </div>
            </div>
        </div>
    );

    // SALARY NEGOTIATION
    const SalaryNegDesc = (
        <div>
            <div className="desc">
                <h1>Salary Negotiation</h1>
                <p>As an employee, you've excelled at your job. Now its time to get what you're worth.</p>
                <p>Communicate your value to your boss. Can you get them to see things from your perspective?</p>
                <p>Choose a job for which you will try to negotiate a higher salary.</p>
            </div>
            <div className="options">
                <div>
                    <img onClick={() => {setCharInfo(SalaryNegDev); 
                                        UserChoices.setScenario('salarynegdev')} } 
                                        src={negotiation3} alt='biz' />
                    <h3>Software Developer</h3>
                    <ul className="pros">
                        <li>Your work has directly impacted your startups success and valuation</li>
                        <li>AI is a hot sector, and your expertise is not easily replaceable</li>
                        <li>You have concrete results to show for your time at the company</li>
                    </ul>
                    <ul className="cons">
                        <li>Startups often have tight salary structures</li>
                        <li>Equity is often preferred over cash compensation</li>
                    </ul>
                </div>
                <div>
                    <img onClick={() => {setCharInfo(SalaryNegProj); 
                                        UserChoices.setScenario('salarynegproj')}} 
                                        src={negotiation1} alt='management' />
                    <h3>Project Manager</h3>
                    <ul className="pros">
                        <li>You have a solid understanding of the company's processes and client base</li>
                        <li>Proven ability to manage the company's most lucrative and complex projects</li>
                        <li>Strong relationships with key stakeholders and team members</li>
                    </ul>
                    <ul className="cons">
                        <li>Market fluctuations in construction may impact the availability of funds for raises</li>
                        <li>Internal policies may cap salary increases, regardless of individual performance</li>
                        <li>The company may have a fixed budget cycle for salary reviews</li>
                    </ul>
                </div>
                <div>
                    <img onClick={() => {setCharInfo(SalaryNegDesign); 
                                        UserChoices.setScenario('salarynegdes')}} 
                                        src={negotiation4} alt='negotiation' />
                    <h3>Graphic Designer</h3>
                    <ul className="pros">
                        <li>Your designs have directly contributed to the agency's reputation and client satisfaction</li>
                        <li>You've demonstrated versatility, adapting to various clients' needs and styles</li>
                        <li>Established as a reliable and innovative asset to the creative team</li>
                    </ul>
                    <ul className="cons">
                        <li>Creative roles can often see slower salary progression compared to sales or technical positions</li>
                        <li>The agency may prioritize client acquisition investments over existing staff raises</li>
                    </ul>
                </div>
            </div>
        </div>
    );

    const SalaryNegProcess = (
        <div>
            <h1>Eloquent Salary Negotiation Process</h1>
            <div className="backorplay">
                <button className="standard-button" onClick={closeProcess}>Back</button>
                <button className="standard-button" onClick={validatePlayAvailable}>Play</button>
            </div>
            <div className="step">
                <div className="step-number">Step 1: Etiquette</div>
                <div className="step-description">Realistically, you would need to pick a decision-maker and schedule a meeting beforehand to ensure appropriate timing. Patience is a virtue. Coming on too hard at the wrong time will get you nowhere.</div>
            </div>
            <div className="step">
                <div className="step-number">Step 2: Preparation</div>
                <div className="step-description">Collect evidence of your contributions and achievements.</div>
            </div>
            <div className="step">
                <div className="step-number">Step 3: Rapport</div>
                <div className="step-description">Express appreciation for opportunities provided thus far. Express the value the company and team has provided to you.</div>
            </div>
            <div className="step">
                <div className="step-number">Step 4: Request a raise - present your case</div>
                <div className="step-description">Describe what you've brought and will bring to the table. Why are you valuable to the company?</div>
            </div>
            <div className="step">
                <div className="step-number">Step 5: Handle Objections</div>
                <div className="step-description">Your boss may take issue with your case. They may agree with your case entirely but still object to said raise. Counter these objections thoughtfully.</div>
            </div>
            <div className="step">
                <div className="step-number">Step 6: Professionally Close the Meeting</div>
                <div className="step-description">Regardless of the outcome, maintain professionalism. You may recieve a yes/no/maybe. Nothing is to be gained by taking it personally.</div>
            </div>
        </div>
    );

    // REAL ESTATE NEGOTIATION
    const RealEstateProcess = (
        <div>
            <h1>Real Estate Negotiation Process</h1>
            <div className="backorplay">
                <button className="standard-button" onClick={closeProcess}>Back</button>
                <button className="standard-button" onClick={validatePlayAvailable}>Play</button>
            </div>
            <div className="step">
                <div className="step-number">Step 1: Establish Rapport</div>
                <div className="step-description">Start by reinforcing the positive aspects of the home and neighborhood that align with the buyer's desires and needs.</div>
            </div>
            <div className="step">
                <div className="step-number">Step 2: Highlight Urgency</div>
                <div className="step-description">Emphasize any recent interest from other buyers or market trends that suggest the home won’t be available for long at the current price.</div>
            </div>
            <div className="step">
                <div className="step-number">Step 3: Address Concerns</div>
                <div className="step-description">Discuss property details that may be causing hesitation, like age of the home, repairs, or upgrades.</div>
            </div>
            <div className="step">
                <div className="step-number">Step 4: Negotiate Terms</div>
                <div className="step-description">Flexibly address financial concerns, such as offering to cover closing costs, or suggest a home warranty to sweeten the deal.</div>
            </div>
            <div className="step">
                <div className="step-number">Step 5: Close the Deal</div>
                <div className="step-description">: Encourage the buyer to make a final offer, suggesting that the seller is willing to negotiate but is also considering other offers.</div>
            </div>
        </div>
    );

    // INVESTMENT PITCH
    const InvestmentPitchDesc = (
        <div>
            <div className="desc">
                <h1>Investment Pitch</h1>
                <p>You've finally taken that big leap to start your business! After taking it as far as possible, you find yourself in need of funding.</p>
                <p>Luckily, a wealthy financier is interested in your project!</p>
                <p>Your goal is to secure favorable terms on a financing deal. You can structure this deal as a combination of debt and equity.</p>
                <p>Select a business to pitch to the investor.</p>
            </div>
            <div className="options">
                <div>
                    <img onClick={() => {setCharInfo(InvestmentPitchUrban); 
                                        UserChoices.setScenario('investmentpitchurban')} } 
                                        src={pitch1} alt='gardening' />
                    <h3>Urban Gardening</h3>
                    <p>Kits that allow city dwellers to grow their own food in small spaces. The kits include seeds for various vegetables and herbs, 
                        soil, and a modular container system that can fit in tight spaces like balconies 
                        and windowsills. Your unique selling point is the integration of a smart app that 
                        guides users through the planting process, offers care reminders, and provides recipes for their harvest.</p>
                    <ul className="pros">
                        <li>Rising trend in urban self-sustainability and organic eating</li>
                        <li>Testimonials demonstrating the product's success and user satisfaction</li>
                        <li>Marketing strategy focusing on partnerships with eco-conscious influencers and brands</li>
                    </ul>
                    <ul className="cons">
                        <li>You need <b>$200k</b> to scale up production, including expanding the range of plants available and possible entry into the educational sector as a tool for schools</li>
                    </ul>
                </div>
                <div>
                    <img onClick={() => {setCharInfo(InvestmentPitchTreat); 
                                        UserChoices.setScenario('investmentpitchtreat')}} 
                                        src={deal6} alt='pet' />
                    <h3>Gourmet Pet Treats</h3>
                    <p>Made from high-quality, locally sourced ingredients, your treats cater 
                        to pets with special dietary needs and preferences, offering grain-free, 
                        gluten-free, and hypoallergenic options. All while offering superior taste!</p>
                    <ul className="pros">
                        <li>Increasing expenditure on pet care and the consumer preference for health-focused products</li>
                        <li>Growing specialty pet food market and how your product is superior to competitors</li>
                        <li>Intensive product development, including veterinary nutritionist consultations and safety testing</li>
                        <li>Working distribution strategy through boutique pet stores and online direct-to-consumer sales channels</li>
                    </ul>
                    <ul className="cons">
                        <li>You need <b>$200k</b> to scale up production, including brand expansion and marketing for a new subscription service</li>
                    </ul>
                </div>
                <div>
                    <img onClick={() => {setCharInfo(InvestmentPitchStem); 
                                        UserChoices.setScenario('investmentpitchstem')}} 
                                        src={pitch2} alt='STEM' />
                    <h3>STEM Toy</h3>
                    <p>Children's toy that combines physical play with digital interactivity 
                        to teach basic principles of science, technology, engineering, and math. 
                        The toy is designed to be both entertaining and educational, promoting 
                        problem-solving skills and creativity. It's accompanied by an app that provides 
                        a variety of learning modules and tracks the child's progress.</p>
                    <ul className="pros">
                        <h3>Features</h3>
                        <li><b>Building Structures: </b>guided tutorials to build models, such as bridges or towers, learning about architectural principles and material strength</li>
                        <li><b>Creating Machines: </b>by constructing simple machines like windmills or cars, kids explore mechanics and energy conversion</li>
                        <li><b>Circuit Integration: </b>Adding lights, sounds, or movement with electronic components introduces basic electrical engineering concepts.</li>
                        <li><b>Programming Basics: </b>A companion app allows children to program the behavior of their creations, teaching them fundamental coding logic and problem-solving skills</li>
                    </ul>
                    <ul className="pros">
                        <li>Growing market demand for toys that support learning in these areas</li>
                        <li>Differentiates from similar toys through its interactivity and adaptability to learning styles and paces.</li>
                        <li>Early testing phases showcase effectiveness and engagement</li>
                    </ul>
                    <ul className="cons">
                        <li>You need <b>$200k</b> to scale up production and meet demand</li>
                    </ul>
                </div>
            </div>
        </div>
    );
    
    // US POLITICS
    const UsPoliticsDesc = (
        <div>
            <div className="desc">
                <h1>US Politics</h1>
                <p>When legislation is "on the floor" this means it is up for debate. Legislators speak for or agains the bill and respond directly to each others arguments in real time.</p>
                <p>Unlike real politics, your score will not be guided</p>
                <p>Choose a piece of legislation to argue for/against.</p>
            </div>
            <div className="options">
                <div>
                    <img onClick={() => {setCharInfo(UsPoliticsGreen); 
                                        UserChoices.setScenario('uspoliticsgreen')}} 
                                        src={uspolitics1} alt='green' />
                    <h3>Green Initiative Act</h3>
                    <p>Aimed at combating climate change through a series of regulatory measures and subsidies.</p>
                    <ul>
                        <li>Nationwide cap on CO2 emissions</li>
                        <li>Subsidizing the development and deployment of solar, wind, and hydroelectric power</li>
                        <li>Tax incentives for businesses/homeowners for clean energy improvements</li>
                        <li>Mandating fossil fuel phase-out and EV adoption by 2035</li>
                        <li>Investing in green infrastructure jobs</li>
                    </ul>
                </div>
                <div>
                    <img onClick={() => {setCharInfo(UsPoliticsGun); 
                                        UserChoices.setScenario('uspoliticsgun')}} 
                                        src={uspolitics2} alt='firearm' />
                    <h3>Gun Control Act</h3>
                    <p>Introduces stringent firearm regulations to improve public safety and reduce gun violence</p>
                    <ul>
                        <li>Universal background checks for all gun sales</li>
                        <li>A ban on the sale and manufacture of high-capacity magazines and assault-style weapons</li>
                        <li>A national gun registry for all firearms sales and transfers</li>
                        <li>Red Flag laws that allow law enforcement to temporarily remove firearms from individuals deemed a threat to themselves or others</li>
                    </ul>
                </div>
                <div>
                    <img onClick={() => {setCharInfo(UsPoliticsUBI); 
                                        UserChoices.setScenario('uspoliticsubi')}} 
                                        src={uspolitics3} alt='ubi' />
                    <h3>Universal Basic Income Act</h3>
                    <p>Social welfare program designed to provide financial stability and stimulate economic growth. This legislation would establish a monthly stipend to all adult citizens.</p>
                    <ul>
                        <li>Reducing poverty and income inequality by ensuring a minimum level of income</li>
                        <li>Replacing a complex array of social welfare programs with a simplified, direct form of financial assistance</li>
                        <li>Alleviating economic distress caused by automation and loss of traditional jobs</li>
                        <li>Simplifying the tax system and reducing administrative costs associated with welfare programs</li>
                    </ul>
                </div>
            </div>
        </div>
    );

    const DebateProcess = (
        <div>
            <h1>Eloquent Debate</h1>
            <div className="backorplay">
                <button className="standard-button" onClick={closeProcess}>Back</button>
                <button className="standard-button" onClick={validatePlayAvailable}>Play</button>
            </div>
            <p className="margin-bot">These scenarios are simply back-and-forth. There is no structured process. However, your goal is to persuade the audience. You may consider utilizing any of the following means of persuasion.</p>
            <div className="step">
                <div className="step-number">Logos</div>
                <div className="step-description">Sound logic will be rewarded. Logical fallacies will be punished. Use facts, reason, and evidence to rationalize your case.</div>
            </div>
            <div className="step">
                <div className="step-number">Ethos</div>
                <div className="step-description">Establish credibility and ethical appeal. People are more likely to agree with you when they trust you. Avoid outright appeal to authority.</div>
            </div>
            <div className="step">
                <div className="step-number">Pathos</div>
                <div className="step-description">Eliciting an emotional response is a means of bypassing logic. Ignore your audiences emotions at your own peril.</div>
            </div>
        </div>
    );

    const GeneralNegProcess = (
        <div>
            <h1>Eloquent Negotiation Process</h1>
            <div className="backorplay">
                <button className="standard-button" onClick={closeProcess}>Back</button>
                <button className="standard-button" onClick={validatePlayAvailable}>Play</button>
            </div>
            <div className="step">
                <div className="step-number">Step 1: Etiquette</div>
            </div>
            <div className="step">
                <div className="step-number">Step 2: Preparation</div>
            </div>
            <div className="step">
                <div className="step-number">Step 3: Build Rapport</div>
            </div>
            <div className="step">
                <div className="step-number">Step 4: Present your case</div>
            </div>
            <div className="step">
                <div className="step-number">Step 5: Handle Objections</div>
            </div>
            <div className="step">
                <div className="step-number">Step 6: Seal the Deal</div>
            </div>
        </div>
    );

    const GeneralSalesProcess = (
        <div>
            <h1>Eloquent Sales Process</h1>
            <div className="backorplay">
                <button className="standard-button" onClick={closeProcess}>Back</button>
                <button className="standard-button" onClick={validatePlayAvailable}>Play</button>
            </div>
            <div className="step">
                <div className="step-number">Step 1: Introduction and Rapport Building</div>
            </div>
            <div className="step">
                <div className="step-number">Step 2: Identify Needs and Pain Points</div>
            </div>
            <div className="step">
                <div className="step-number">Step 3: Present Solutions</div>
            </div>
            <div className="step">
                <div className="step-number">Step 4: Objection Handling</div>
            </div>
            <div className="step">
                <div className="step-number">Step 5: Close the Sale</div>
            </div>
        </div>
    );

    // CUSTOM SELL CHARS
    const Mateo = (
        <div>
            <img className="opponent" src={sell1} alt='prospect' />
            <div className="chars">
                <p><b>Name:</b> Mateo Rodriguez</p>
                <p><b>Traits:</b> Ambitious, Driven, Competitive</p>
            </div>
        </div>
    );
    
    const Chuck = (
        <div>
            <img className="opponent" src={prospect1} alt='prospect' />
            <div className="chars">
                <p><b>Name:</b> Chuck Malone</p>
                <p><b>Traits:</b> Charming, sociable, over-confident</p>
            </div>
        </div>
    );

    const Emily = (
        <div>
            <img className="opponent" src={landscape1} alt='prospect' />
            <div className="chars">
                <p><b>Name:</b> Emily Rivera</p>
                <p><b>Traits:</b> Passionate, Self-Made, Community-Focused, Driven</p>
            </div>
        </div>
    );

    const Aria = (
        <div>
            <img className="opponent" src={massage1} alt='prospect' />
            <div className="chars">
                <p><b>Name:</b> Aria Tanaka</p>
                <p><b>Traits:</b> Dedicated, Holistic, Comforting</p>
            </div>
        </div>
    )

    const Lucas = (
        <div>
            <img className="opponent" src={veterinarian1} alt='prospect' />
            <div className="chars">
                <p><b>Name:</b> Lucas Bennett</p>
                <p><b>Traits:</b> Compassionate, Educator, Practical</p>
            </div>
        </div>
    )

    const Marcus = (
        <div>
            <img className="opponent" src={wallst1} alt='prospect' />
            <div className="chars">
                <p><b>Name:</b> Marcus Elliott</p>
                <p><b>Traits:</b> Resilient, Risk-Tolerant, Decisive</p>
            </div>
        </div>
    )

    const Elizabeth = (
        <div>
            <img className="opponent" src={wallst4} alt='prospect' />
            <div className="chars">
                <p><b>Name:</b> Elizabeth Hamilton</p>
                <p><b>Traits:</b> Astute, Risk-Taker, Decisive</p>
            </div>
        </div>
    )

    const Alexander = (
        <div>
            <img className="opponent" src={wallst3} alt='prospect' />
            <div className="chars">
                <p><b>Name:</b> Alexander Mercer</p>
                <p><b>Traits:</b> Prudent, Long-Term Mindset, Ethical</p>
            </div>
        </div>
    )

    const Ethan = (
        <div>
            <img className="opponent" src={insurance1} alt='prospect' />
            <div className="chars">
                <p><b>Name:</b> Ethan Kim</p>
                <p><b>Traits:</b> Family-Oriented, Prudent, Responsible</p>
            </div>
        </div>
    )

    const Lauren = (
        <div>
            <img className="opponent" src={insurance3} alt='prospect' />
            <div className="chars">
                <p><b>Name:</b> Lauren Thompson</p>
                <p><b>Traits:</b> Caring, Resourceful, Pragmatic</p>
            </div>
        </div>
    )

    const David = (
        <div>
            <img className="opponent" src={insurance2} alt='prospect' />
            <div className="chars">
                <p><b>Name:</b> David Anderson</p>
                <p><b>Traits:</b> Risk-Aware, Conscientious, Pragmatic</p>
            </div>
        </div>
    )

    const Jack = (
        <div>
            <img className="opponent" src={wolfst1} alt='prospect' />
            <div className="chars">
                <p><b>Name:</b> Jack Morrison</p>
                <p><b>Traits:</b> Competitive, Success-Oriented, Impressionable</p>
            </div>
        </div>
    )

    const Vivian = (
        <div>
            <img className="opponent" src={wolfst2} alt='prospect' />
            <div className="chars">
                <p><b>Name:</b> Vivian Beaumont</p>
                <p><b>Profession:</b> Art Dealer</p>
                <p><b>Traits:</b> Sophisticated, Status-Oriented, Adventurous</p>
            </div>
        </div>
    )

    const Alex = (
        <div>
            <img className="opponent" src={wolfst3} alt='prospect' />
            <div className="chars">
                <p><b>Name:</b> Alex Rivera</p>
                <p><b>Traits:</b> Tech Know-it-All, Risk-Taker, Impulsive</p>
            </div>
        </div>
    )

    const Tom = (
        <div>
            <img className="opponent" src={fin1} alt='prospect' />
            <div className="chars">
                <p><b>Name:</b> Tom Jackson</p>
                <p><b>Traits:</b> Educated, Classy, Formidable</p>
            </div>
        </div>
    )

    // CUSTOM INTERVIEW CHARS
    const Adam = (
        <div>
            <img className="opponent" src={interview5} alt='prospect' />
            <div className="chars">
                <p><b>Name:</b> Adam Powell</p>
                <p><b>Profession:</b> Business Development Rep.</p>
                <p><b>Traits:</b> Ambitious, Stoic, Perceptive</p>
                <p><b>Skills:</b> Questioning, Engagement, Likeable</p>
            </div>
        </div>
    )
    
    const Claire = (
        <div>
            <img className="opponent" src={BasicInt1} alt='prospect' />
            <div className="chars">
                <p><b>Name:</b> Claire Hamilton</p>
                <p><b>Profession:</b> Senior HR Manager</p>
                <p><b>Traits:</b> Professional, Alert, Observant</p>
                <p><b>Skills:</b> Conflict Resolution, negotiation, sees you as more than a resume.</p>
            </div>
        </div>
    )

    const Michael = (
        <div>
            <img className="opponent" src={BasicInt2} alt='prospect' />
            <div className="chars">
                <p><b>Name:</b> Michael Richardson</p>
                <p><b>Profession:</b> Corporate Recruitment</p>
                <p><b>Traits:</b> Diplomatic, Friendly, Upbeat.</p>
                <p><b>Skills:</b> , Team-building, talent development.</p>
            </div>
        </div>
    )

    const Vanessa = (
        <div>
            <img className="opponent" src={BasicInt3} alt='prospect' />
            <div className="chars">
                <p><b>Name:</b> Vanessa Lee</p>
                <p><b>Profession:</b> Senior Executive</p>
                <p><b>Traits:</b> Organized, Assertive, Attentive</p>
                <p><b>Skills:</b> Corporate navigation, organization. Has mastered the art of reading people.</p>
            </div>
        </div>
    );

    const Carter = (
        <div>
            <img className="opponent" src={engineer1} alt='engineer' />
            <div className="chars">
                <p><b>Name:</b> Carter Smith</p>
                <p><b>Traits:</b> Experienced, Alert, Fast-Paced</p>
            </div>
        </div>
    );

    const Jessica = (
        <div>
            <img className="opponent" src={software1} alt='engineer' />
            <div className="chars">
                <p><b>Name:</b> Jessica Richardson</p>
                <p><b>Traits:</b> Innovative, Approachable, Meticulous</p>
            </div>
        </div>
    );

    const Hiroshi = (
        <div>
            <img className="opponent" src={scientist1} alt='engineer' />
            <div className="chars">
                <p><b>Name:</b> Hiroshi Nakamura</p>
                <p><b>Traits:</b> Inquisitive, Patient, Precise</p>
            </div>
        </div>
    );

    const Audrey = (
        <div>
            <img className="opponent" src={createint1} alt='engineer' />
            <div className="chars">
                <p><b>Name:</b> Audrey Harper</p>
                <p><b>Traits:</b> Articulate, Perceptive, Imaginative</p>
            </div>
        </div>
    );

    const Gabby = (
        <div>
            <img className="opponent" src={interview2} alt='engineer' />
            <div className="chars">
                <p><b>Name:</b> Gabby Turner</p>
                <p><b>Traits:</b> Collaborative, User-Focused, Insightful</p>
            </div>
        </div>
    );

    const Gabriel = (
        <div>
            <img className="opponent" src={createint2} alt='engineer' />
            <div className="chars">
                <p><b>Name:</b> Gabriel Contway</p>
                <p><b>Traits:</b> Strategic, Adaptable, Data-Driven</p>
            </div>
        </div>
    );

    const Jennifer = (
        <div>
            <img className="opponent" src={coo} alt='engineer' />
            <div className="chars">
                <p><b>Name:</b> Jennifer Grant</p>
                <p><b>Traits:</b> Professional, Cunning, Fierce</p>
            </div>
        </div>
    );

    const Kenji = (
        <div>
            <img className="opponent" src={cfo} alt='engineer' />
            <div className="chars">
                <p><b>Name:</b> Kenji Sato</p>
                <p><b>Traits:</b> High Integrity, Authoritative, Meticulous</p>
            </div>
        </div>
    );

    const Rich = (
        <div>
            <img className="opponent" src={ceo} alt='engineer' />
            <div className="chars">
                <p><b>Name:</b> Rich Sterling</p>
                <p><b>Traits:</b> Visionary, Experienced, Charismatic</p>
            </div>
        </div>
    );

    // CUSTOM NEGOTIATION CHARS
    const Eve = (
        <div>
            <img className="opponent" src={deal5} alt='prospect' />
            <div className="chars">
                <p><b>Name:</b> Eve Crestworth</p>
                <p><b>Traits:</b> Confident, Skeptical, Strategic</p>
                <p><b>Skills:</b> Defensive tactics, Anticipating Arguments, Preparation</p>
            </div>
        </div>
    )

    const Thomas = (
        <div>
            <img className="opponent" src={negotiation3} alt='prospect' />
            <div className="chars">
                <p><b>Name:</b> Thomas Reed</p>
                <p><b>Traits:</b> Fair-Minded, Empathetic, Steadfast</p>
                <p><b>Skills:</b> Numbers, pressuring, controlling the conversation.</p>
            </div>
        </div>
    )

    const Julia = (
        <div>
            <img className="opponent" src={negotiation1} alt='prospect' />
            <div className="chars">
                <p><b>Name:</b> Julia Chen</p>
                <p><b>Traits:</b> Pragmatic, Respectful, Analytical</p>
                <p><b>Skills:</b> Confident leadership, values fairness.</p>
            </div>
        </div>
    )

    const Daniel = (
        <div>
            <img className="opponent" src={negotiation4} alt='prospect' />
            <div className="chars">
                <p><b>Name:</b> Daniel Moreno</p>
                <p><b>Traits:</b> Business-savvy, process-oriented, appreciative</p>
                <p><b>Skills:</b> Stakeholder management, clear-and-concise, takes advantage of mistakes.</p>
            </div>
        </div>
    )

    const Linda = (
        <div>
            <img className="opponent" src={realestate1} alt='engineer' />
            <div className="chars">
                <p><b>Name:</b> Linda Katz</p>
                <p><b>Traits:</b> Detail-oriented, cautious, and value-conscious</p>
            </div>
        </div>
    )

    const Ben = (
        <div>
            <img className="opponent" src={pitch1} alt='prospect' />
            <div className="chars">
                <p><b>Name:</b> Ben Greyson</p>
                <p><b>Traits:</b> Skeptical, Long-Term Mindset, Trusts his Instincts</p>
            </div>
        </div>
    )

    const Luna = (
        <div>
            <img className="opponent" src={deal6} alt='prospect' />
            <div className="chars">
                <p><b>Name:</b> Luna Florence</p>
                <p><b>Traits:</b> Principled, Born Leader, Disruptor</p>
            </div>
        </div>
    )

    const Rico = (
        <div>
            <img className="opponent" src={pitch2} alt='prospect' />
            <div className="chars">
                <p><b>Name:</b> Rico Hawthorne</p>
                <p><b>Traits:</b> Risk-Addicted, Deal-Maker, High Standards</p>
            </div>
        </div>
    )

    // CUSTOM DEBATE CHARS
    const Johnathan = (
        <div>
            <img className="opponent" src={politician1} alt='prospect' />
            <div className="chars">
                <p><b>Name:</b> Johnathan Everett</p>
                <p><b>Traits:</b> Composed, Charismatic, Outspoken</p>
                <p><b>Skills:</b> Logical Deduction, Philosophy, Rhetoric</p>
            </div>
        </div>
    )

    const Natalie = (
        <div>
            <img className="opponent" src={debate1} alt='prospect' />
            <div className="chars">
                <p><b>Name:</b> Natalie Greene</p>
                <p><b>Traits:</b> Sharp, Intuitive, Outspoken</p>
                <p><b>Skills:</b> Well-read, critical thinking, her questions can box you into a corner.</p>
            </div>
        </div>
    )

    const Diana = (
        <div>
            <img className="opponent" src={uspolitics1} alt='prospect' />
            <div className="chars">
                <p><b>Name:</b> Diana Caldwell</p>
                <p><b>Traits:</b> Compelling, Scientific, Determined</p>
                <p><b>Skills:</b> Public Speaking, Constituent Engagement, Detailed</p>
            </div>
        </div>
    )

    const Mike = (
        <div>
            <img className="opponent" src={uspolitics2} alt='prospect' />
            <div className="chars">
                <p><b>Name:</b> Mike Thompson</p>
                <p><b>Traits:</b> Resolute, Influential, Dedicated</p>
                <p><b>Skills:</b> Debate, Emotional Intelligence.</p>
            </div>
        </div>
    )

    const Ava = (
        <div>
            <img className="opponent" src={uspolitics3} alt='prospect' />
            <div className="chars">
                <p><b>Name:</b> Ava Richardson</p>
                <p><b>Traits:</b> Insightful, Uniter, Understanding</p>
                <p><b>Skills:</b> Public Engagement, clarity-of-thought, controlling the conversation.</p>
            </div>
        </div>
    )

    // CUSTOM DESCS
    const SalesCustomDesc = (
        <div>
            <div className="desc">
                <h1>Custom Selling Scenario</h1>
                <p>Insert the name of your product and a brief description.</p>
                <p>Provide context of the sale, (cold call, in-store, etc)</p>
            </div>
            <div className="input-form">
                <h3>Product Details</h3>
                <div className="form-group">
                    <label htmlFor="productName">Name:</label>
                    <input type="text" id="productName" name="productName" placeholder="Enter product name" maxLength="100"></input>
                </div>
                <div className="form-group">
                    <label htmlFor="productDescription">Description:</label>
                    <textarea id="productDescription" name="productDescription" placeholder="Describe your product/service" maxLength="1000"></textarea>
                    <div id="descriptionCounter">Max 1000 characters</div>
                </div>
                <div className="form-group">
                    <label htmlFor="productContext">Context:</label>
                    <input type="text" id="productContext" name="productContext" placeholder="Enter context information (cold call, in-store, etc.)" maxLength="100"></input>
                </div>
            </div>
            <div className="desc">
                <h1>Choose a Character to Sell</h1>
            </div>
            <div className="options">
                <img onClick={() => {setCharInfo(Mateo); 
                                    UserChoices.setScenario('customsellmateo'); 
                                    UserChoices.setOpponent('Mateo Rodriguez');
                                    UserChoices.setTraits('ambitious, driven, and competitive');}} 
                                    src={sell1} alt='prospect' />
                <img onClick={() => {setCharInfo(Chuck); UserChoices.setScenario('customsellchuck'); UserChoices.setOpponent('Chuck Malone')}} src={prospect1} alt='prospect' />
                <img onClick={() => {setCharInfo(Emily); UserChoices.setScenario('customsellemily'); UserChoices.setOpponent('Emily Rivera')}} src={landscape1} alt='landscaping' />
                <img onClick={() => {setCharInfo(Aria); UserChoices.setScenario('customsellaria'); UserChoices.setOpponent('Aria Tanaka')}} src={massage1} alt='massage' />
                <img onClick={() => {setCharInfo(Lucas); UserChoices.setScenario('customselllucas'); UserChoices.setOpponent('Lucas Bennett')}} src={veterinarian1} alt='veterinary' />
                <img onClick={() => {setCharInfo(Marcus); UserChoices.setScenario('customsellmarcus'); UserChoices.setOpponent('Marcus Elliot')}} src={wallst1} alt='wallstreet' />
                <img onClick={() => {setCharInfo(Elizabeth); UserChoices.setScenario('customsellelizabeth'); UserChoices.setOpponent('Elizabeth Hamilton')}} src={wallst4} alt='professional' />
                <img onClick={() => {setCharInfo(Alexander); UserChoices.setScenario('customsellalexander'); UserChoices.setOpponent('Alexander Mercer')}} src={wallst3} alt='finance' />
                <img onClick={() => {setCharInfo(Ethan); UserChoices.setScenario('customsellethan'); UserChoices.setOpponent('Ethan Kim')}} src={insurance1} alt='insurance' />
                <img onClick={() => {setCharInfo(Lauren); UserChoices.setScenario('customselllauren'); UserChoices.setOpponent('Lauren Thompson')}} src={insurance3} alt='fincance' />
                <img onClick={() => {setCharInfo(David); UserChoices.setScenario('customselldavid'); UserChoices.setOpponent('David Anderson')}} src={insurance2} alt='finance' />
                <img onClick={() => {setCharInfo(Jack); UserChoices.setScenario('customselljack'); UserChoices.setOpponent('Jack Morrison')}} src={wolfst1} alt='finance' />
                <img onClick={() => {setCharInfo(Vivian); UserChoices.setScenario('customsellvivian'); UserChoices.setOpponent('Vivian Beaumont')}} src={wolfst2} alt='finance' />
                <img onClick={() => {setCharInfo(Alex); UserChoices.setScenario('customsellalex'); UserChoices.setOpponent('Alex Rivera')}} src={wolfst3} alt='finance' />
                <img onClick={() => {setCharInfo(Tom); UserChoices.setScenario('customselltom'); UserChoices.setOpponent('Tom Jackson')}} src={fin1} alt='finance' />
            </div>
        </div>
    );

    const InterviewCustomDesc = (
        <div>
            <div className="desc">
                <h1>Custom Interview Scenario</h1>
                <p>Insert a job title and brief description. Feel free to copy/paste from a job listing.</p>
            </div>
            
            <div className="input-form">
                <h3>Job Details</h3>
                <div className="form-group">
                    <label htmlFor="jobName">Job Title:</label>
                    <input type="text" id="jobName" name="jobName" placeholder="Enter job title" maxLength="100"></input>
                </div>
                <div className="form-group">
                    <label htmlFor="companyName">Company:</label>
                    <input type="text" id="companyName" name="companyName" placeholder="Enter company name (can be fake)" maxLength="100"></input>
                </div>
                <div className="form-group">
                    <label htmlFor="jobDescription">Job Description:</label>
                    <textarea id="jobDescription" name="jobDescription" placeholder="Describe the job for which you will interview" maxLength="1000"></textarea>
                    <div id="descriptionCounter">Max 1000 characters</div>
                </div>
            </div>
            <div className="desc">
                <h1>Choose a Character to Conduct the Interview</h1>
            </div>
            <div className="options">
                <img onClick={() => {setCharInfo(Adam); UserChoices.setScenario('customintadam'); UserChoices.setOpponent('Adam Powell')} } src={interview5} alt='interviewer' />
                <img onClick={() => {setCharInfo(Claire); UserChoices.setScenario('customintclaire'); UserChoices.setOpponent('Claire Hamilton')}} src={BasicInt1} alt='humanresources' />
                <img onClick={() => {setCharInfo(Michael); UserChoices.setScenario('customintmichael'); UserChoices.setOpponent('Michael Richardson')}} src={BasicInt2} alt='corporate' />
                <img onClick={() => {setCharInfo(Vanessa); UserChoices.setScenario('customintvanessa'); UserChoices.setOpponent('Vanessa Lee')}} src={BasicInt3} alt='executive' />
                <img onClick={() => {setCharInfo(Carter); UserChoices.setScenario('customintcarter'); UserChoices.setOpponent('Carter Smith')}} src={engineer1} alt='executive' />
                <img onClick={() => {setCharInfo(Jessica); UserChoices.setScenario('customintjessica'); UserChoices.setOpponent('Jessica Richardson')}} src={software1} alt='executive' />
                <img onClick={() => {setCharInfo(Hiroshi); UserChoices.setScenario('custominthiroshi'); UserChoices.setOpponent('Hiroshi Nakamura')}} src={scientist1} alt='executive' />
                <img onClick={() => {setCharInfo(Audrey); UserChoices.setScenario('customintaudrey'); UserChoices.setOpponent('Audrey Harper')}} src={createint1} alt='executive' />
                <img onClick={() => {setCharInfo(Gabby); UserChoices.setScenario('customintgabby'); UserChoices.setOpponent('Gabby Turner')}} src={interview2} alt='executive' />
                <img onClick={() => {setCharInfo(Gabriel); UserChoices.setScenario('customintgabriel'); UserChoices.setOpponent('Gabriel Contway')}} src={createint2} alt='executive' />
                <img onClick={() => {setCharInfo(Jennifer); UserChoices.setScenario('customintjennifer'); UserChoices.setOpponent('Jennifer Grant')}} src={coo} alt='executive' />
                <img onClick={() => {setCharInfo(Kenji); UserChoices.setScenario('customintkenji'); UserChoices.setOpponent('Kenji Sato')}} src={cfo} alt='executive' />
                <img onClick={() => {setCharInfo(Rich); UserChoices.setScenario('customintrich'); UserChoices.setOpponent('Rich Sterling')}} src={ceo} alt='executive' />
            </div>
        </div>
    )

    const NegotiationCustomDesc = (
        <div>
            <div className="desc">
                <h1>Custom Negotiation Scenario</h1>
                <p>Define the parameters of a negotiation scenario and choose a counter-party.</p>
                <p>You will need to negotiate towards your goal. Secure concessions from the counterparty, or be forced to meet in the middle.</p>
            </div>
            <div className="input-form">
                <h3>Define your parties primary goal/interests</h3>
                <div className="form-group">
                    <label htmlFor="negParty1">Define your party's primary goal/interests:</label>
                    <textarea id="negParty1" name="negParty1" placeholder="Describe the ideal outcome you are seeking." maxLength="1000"></textarea>
                    <div id="descriptionCounter">Max 1000 characters</div>
                </div>
                <div className="form-group">
                    <label htmlFor="negParty2">Define the counter-party's primary goal/interests:</label>
                    <textarea id="negParty2" name="negParty2" placeholder="Describe the ideal outcome your counterparty seeks." maxLength="1000"></textarea>
                    <div id="descriptionCounter">Max 1000 characters</div>
                </div>
            </div>
            <div className="desc">
                <h1>Choose a Character to Negotiate</h1>
            </div>
            <div className="options">
                <img onClick={() => {setCharInfo(Eve); UserChoices.setScenario('customnegeve'); UserChoices.setOpponent('Eve Crestworth')} } src={deal5} alt='negotiation' />
                <img onClick={() => {setCharInfo(Thomas); UserChoices.setScenario('customnegthomas'); UserChoices.setOpponent('Thomas Reed')}} src={negotiation3} alt='counterparty' />
                <img onClick={() => {setCharInfo(Julia); UserChoices.setScenario('customnegjulia'); UserChoices.setOpponent('Julia Chen')}} src={negotiation1} alt='corporate' />
                <img onClick={() => {setCharInfo(Daniel); UserChoices.setScenario('customnegdaniel'); UserChoices.setOpponent('Daniel Moreno')}} src={negotiation4} alt='executive' />
                <img onClick={() => {setCharInfo(Linda); UserChoices.setScenario('customneglinda'); UserChoices.setOpponent('Linda Katz')}} src={realestate1} alt='executive' />
                <img onClick={() => {setCharInfo(Ben); UserChoices.setScenario('customnegben'); UserChoices.setOpponent('Ben Greyson')}} src={pitch1} alt='executive' />
                <img onClick={() => {setCharInfo(Luna); UserChoices.setScenario('customnegluna'); UserChoices.setOpponent('Luna Florence')}} src={deal6} alt='executive' />
                <img onClick={() => {setCharInfo(Rico); UserChoices.setScenario('customnegrico'); UserChoices.setOpponent('Rico Hawthorne')}} src={pitch2} alt='executive' />
            </div>
        </div>
    )

    const DebateCustomDesc = (
        <div>
            <div className="desc">
                <h1>Custom Debate Scenario</h1>
                <p>Craft a position statement. Your selected opponent will argue the opposite position.</p>
                <p>Example: "The moon landing was fake"</p>
                <p>Example: "The CIA did not kill Kennedy"</p>

            </div>
            <div className="input-form">
                <div className="form-group">
                    <label htmlFor="debateParty1">Position Statement:</label>
                    <textarea id="debateParty1" name="debateParty1" placeholder="Describe your debate position" maxLength="1000"></textarea>
                    <div id="descriptionCounter">Max 1000 characters</div>
                </div>
            </div>
            <div className="desc">
                <h1>Choose a Character to Debate</h1>
            </div>
            <div className="options">
                <img onClick={() => {setCharInfo(Natalie); UserChoices.setScenario('customdebatenatalie'); UserChoices.setOpponent('Natalie Greene')}} src={debate1} alt='debate' />
                <img onClick={() => {setCharInfo(Johnathan); UserChoices.setScenario('customdebatejohnathan'); UserChoices.setOpponent('Johnathan Everett')}} src={politician1} alt='interviewer' />
                <img onClick={() => {setCharInfo(Diana); UserChoices.setScenario('customdebatediana'); UserChoices.setOpponent('Diana Caldwell')}} src={uspolitics1} alt='humanresources' />
                <img onClick={() => {setCharInfo(Ava); UserChoices.setScenario('customdebateava'); UserChoices.setOpponent('Ava Richardson')}} src={uspolitics3} alt='corporate' />
                <img onClick={() => {setCharInfo(Mike); UserChoices.setScenario('customdebatemike'); UserChoices.setOpponent('Mike Thompson')}} src={uspolitics2} alt='executive' />
            </div>
        </div>
    )




    return (
        <div className="container">

            <button className="return-button" onClick={goHome}>
                Return Home
            </button>

            {isDetailVisible && <DetailPopup desc={descInfo} characteristics={charInfo}/>}
            {isProcessVisible && <ProcessPopup process={processInfo}/>}


            <div className="menu">

                <h3 className="category">Custom Scenarios</h3>

                <h3 className="diff-levels">Art of Selling</h3>
                <div onClick={() => {showDetail(SalesCustomDesc, Mateo, GeneralSalesProcess); 
                                    UserChoices.setScenario('customsellmateo')
                                    UserChoices.setOpponent("Mateo Rodriguez")}} className="scenario">
                    <img className="opponent" src={sell1} alt='prospect' />
                    <div>
                        <div className="scenario-title">Sell Anything</div>
                        <div>Practice selling your own product.</div>
                    </div>
                </div>
                
                <h3 className="diff-levels">Art of Interviewing</h3>
                <div onClick={() => {showDetail(InterviewCustomDesc, Adam, BasicIntProcess); 
                                    UserChoices.setScenario('customintadam')
                                    UserChoices.setOpponent("Adam Powell")}} className="scenario">
                    <img className="opponent" src={interview5} alt='prospect' />
                    <div>
                        <div className="scenario-title">Interview for any Job</div>
                        <div>Enter a job description to tailor your experience.</div>
                    </div>
                </div>

                <h3 className="diff-levels">Art of Negotiation</h3>
                <div onClick={() => {showDetail(NegotiationCustomDesc, Eve, GeneralNegProcess); 
                                    UserChoices.setScenario('customnegeve')
                                    UserChoices.setOpponent("Eve Crestworth")}} className="scenario">
                    <img className="opponent" src={deal5} alt='prospect' />
                    <div>
                        <div className="scenario-title">Negotiate any Deal</div>
                        <div>Define counter-parties for unique simulations.</div>
                    </div>
                </div>

                <h3 className="diff-levels">Art of Debate</h3>
                <div onClick={() => {showDetail(DebateCustomDesc, Natalie, DebateProcess); 
                                    UserChoices.setScenario('customdebatenatalie')
                                    UserChoices.setOpponent("Natalie Greene")}} className="scenario">
                    <img className="opponent" src={debate1} alt='prospect' />
                    <div>
                        <div className="scenario-title">Debate any Topic</div>
                        <div>Intellectual discourse on demand</div>
                    </div>
                </div>
            </div>


            <div className="menu">
                
                <h3 className="category">Art of Selling</h3>

                <h3 className="diff-levels">Level 1</h3>
                <div onClick={() => {showDetail(CarSalesDesc, CarSalesChar, CarSalesProcess); 
                                    UserChoices.setScenario('musclecarsales')}} className="scenario">
                    <img className="opponent" src={prospect1} alt='prospect' />
                    <div>
                        <div className="scenario-title">Classic Cars</div>
                        <div>Sell a car to a guy looking for a car.</div>
                        <div className="difficulty-indicators">
                            <span className={winsArr.current.includes("musclecarsalesEasy") ? "dot won" : "dot"}></span>
                            <span className={winsArr.current.includes("musclecarsalesMedium") ? "dot won" : "dot"}></span>
                            <span className={winsArr.current.includes("musclecarsalesHard") ? "dot won" : "dot"}></span>
                        </div>
                    </div>
                </div>

                <h3 className="diff-levels">Level 2</h3>
                <div onClick={() => {showDetail(ColdCallDesc, ColdCallLandscape, ColdCallProcess); 
                                    UserChoices.setScenario('coldcalllandscape');}} className="scenario">
                    <img className="opponent" src={coldcall1} alt='prospect' />
                    <div>
                        <div className="scenario-title">Cold Call</div>
                        <div>Book a meeting for your social media marketing agency.</div>
                        <div className="difficulty-indicators">
                            <span className={winsArr.current.includes("coldcalllandscapeEasy") 
                                            || winsArr.current.includes("coldcallmassageEasy") 
                                            || winsArr.current.includes("coldcallvetEasy") ? "dot won" : "dot"}></span>
                            <span className={winsArr.current.includes("coldcalllandscapeMedium") 
                                            || winsArr.current.includes("coldcallmassageMedium") 
                                            || winsArr.current.includes("coldcallvetMedium") ? "dot won" : "dot"}></span>
                            <span className={winsArr.current.includes("coldcalllandscapeHard") 
                                            || winsArr.current.includes("coldcallmassageHard") 
                                            || winsArr.current.includes("coldcallvetHard") ? "dot won" : "dot"}></span>
                        </div>
                    </div>
                </div>
                <div onClick={() => {showDetail(WallStDesc, WallStGMT, WallStProcess); 
                                    UserChoices.setScenario('wallstgmt');}} className="scenario">
                    <img className="opponent" src={wallst1} alt='prospect' />
                    <div>
                        <div className="scenario-title">Wall Street Simulator</div>
                        <div>As a broker, convince your client to invest in this stock.</div>
                        <div className="difficulty-indicators">
                            <span className={winsArr.current.includes("wallstgmtEasy") 
                                            || winsArr.current.includes("wallsteesEasy") 
                                            || winsArr.current.includes("wallstffiEasy") ? "dot won" : "dot"}></span>
                            <span className={winsArr.current.includes("wallstgmtMedium") 
                                            || winsArr.current.includes("wallsteesMedium") 
                                            || winsArr.current.includes("wallstffiMedium") ? "dot won" : "dot"}></span>
                            <span className={winsArr.current.includes("wallstgmtHard") 
                                            || winsArr.current.includes("wallsteesHard") 
                                            || winsArr.current.includes("wallstffiHard") ? "dot won" : "dot"}></span>
                        </div>
                    </div>
                </div>
                <div onClick={() => {showDetail(InsuranceDesc, InsuranceLife, InsuranceProcess); 
                                    UserChoices.setScenario('insurancelife');}} className="scenario">
                    <img className="opponent" src={insurance1} alt='prospect' />
                    <div>
                        <div className="scenario-title">Insurance</div>
                        <div>Whether Life/Health/Home - convince these families to stay protected.</div>
                        <div className="difficulty-indicators">
                            <span className={winsArr.current.includes("insurancelifeEasy") 
                                            || winsArr.current.includes("insurancehealthEasy") 
                                            || winsArr.current.includes("insurancehomeEasy") ? "dot won" : "dot"}></span>
                            <span className={winsArr.current.includes("insurancelifeMedium") 
                                            || winsArr.current.includes("insurancehealthMedium") 
                                            || winsArr.current.includes("insurancehomeMedium") ? "dot won" : "dot"}></span>
                            <span className={winsArr.current.includes("insurancelifeHard") 
                                            || winsArr.current.includes("insurancehealthHard") 
                                            || winsArr.current.includes("insurancehomeHard") ? "dot won" : "dot"}></span>
                        </div>
                    </div>
                </div>
                
                <h3 className="diff-levels">Level 3</h3>
                <div onClick={() => {showDetail(WolfStDesc, WolfStNTII, InsuranceProcess); 
                                    UserChoices.setScenario('wolfstntii');}} className="scenario">
                    <img className="opponent" src={wallst2} alt='wolf street' />
                    <div>
                        <div className="scenario-title">Wolf Street Simulator</div>
                        <div>This penny stock is the deal of a lifetime...</div>
                        <div className="difficulty-indicators">
                            <span className={winsArr.current.includes("wolfstntiiEasy") 
                                            || winsArr.current.includes("wolfstbgacEasy") 
                                            || winsArr.current.includes("wolfstqltEasy") ? "dot won" : "dot"}></span>
                            <span className={winsArr.current.includes("wolfstntiiMedium") 
                                            || winsArr.current.includes("wolfstbgacMedium") 
                                            || winsArr.current.includes("wolfstqltMedium") ? "dot won" : "dot"}></span>
                            <span className={winsArr.current.includes("wolfstntiiHard") 
                                            || winsArr.current.includes("wolfstbgacHard") 
                                            || winsArr.current.includes("wolfstqltHard") ? "dot won" : "dot"}></span>
                        </div>
                    </div>
                </div>
                <div onClick={() => {showDetail(PortStratDesc, PortStratTom, PortStratProcess); 
                                    UserChoices.setScenario('portstrattom');}} className="scenario">
                    <img className="opponent" src={fin1} alt='financial advisor' />
                    <div>
                        <div className="scenario-title">Portfolio Strategy</div>
                        <div>Win over Advisors to your Investment strategy</div>
                        <div className="difficulty-indicators">
                            <span className={winsArr.current.includes("portstrattomEasy") ? "dot won" : "dot"}></span>
                            <span className={winsArr.current.includes("portstrattomMedium") ? "dot won" : "dot"}></span>
                            <span className={winsArr.current.includes("portstrattomHard") ? "dot won" : "dot"}></span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="menu">

                <h3 className="category">Art of Interviewing</h3>

                <h3 className="diff-levels">Level 1</h3>
                <div onClick={() => {showDetail(BasicIntDesc, BasicIntDev, BasicIntProcess); 
                                    UserChoices.setScenario('basicintdev')}} className="scenario">
                    <img className="opponent" src={BasicInt1} alt='interviewer' />
                    <div>
                        <div className="scenario-title">Basic Interview</div>
                        <div>Answer generic questions for an unspecified job.</div>
                        <div className="difficulty-indicators">
                            <span className={winsArr.current.includes("basicintdevEasy") 
                                            || winsArr.current.includes("basicintcoordEasy") 
                                            || winsArr.current.includes("basicintexecEasy") ? "dot won" : "dot"}></span>
                            <span className={winsArr.current.includes("basicintdevMedium") 
                                            || winsArr.current.includes("basicintcoordMedium") 
                                            || winsArr.current.includes("basicintexecMedium") ? "dot won" : "dot"}></span>
                            <span className={winsArr.current.includes("basicintdevHard") 
                                            || winsArr.current.includes("basicintcoordHard") 
                                            || winsArr.current.includes("basicintexecHard") ? "dot won" : "dot"}></span>
                        </div>
                    </div>
                </div>
                
                <h3 className="diff-levels">Level 2</h3>
                <div onClick={() => {showDetail(TechIntDesc, TechIntEng, BasicIntProcess); 
                                    UserChoices.setScenario('techinteng')}} className="scenario">
                    <img className="opponent" src={engineer1} alt='tech-interview' />
                    <div>
                        <div className="scenario-title">Technical Role</div>
                        <div>Assess your general technical and problem solving abilities.</div>
                        <div className="difficulty-indicators">
                            <span className={winsArr.current.includes("techintengEasy") 
                                            || winsArr.current.includes("techintsoftEasy") 
                                            || winsArr.current.includes("techintsciEasy") ? "dot won" : "dot"}></span>
                            <span className={winsArr.current.includes("techintengMedium") 
                                            || winsArr.current.includes("techintsoftMedium") 
                                            || winsArr.current.includes("techintsciMedium") ? "dot won" : "dot"}></span>
                            <span className={winsArr.current.includes("techintengHard") 
                                            || winsArr.current.includes("techintsoftHard") 
                                            || winsArr.current.includes("techintsciHard") ? "dot won" : "dot"}></span>
                        </div>
                    </div>
                </div>
                <div onClick={() => {showDetail(CreateIntDesc, CreateIntWrite, BasicIntProcess); 
                                    UserChoices.setScenario('createintwrite')}} className="scenario">
                    <img className="opponent" src={interview2} alt='creative-interview' />
                    <div>
                        <div className="scenario-title">Creative Role</div>
                        <div>Assess your general creative intuitions and abilities.</div>
                        <div className="difficulty-indicators">
                            <span className={winsArr.current.includes("createintwriteEasy") 
                                            || winsArr.current.includes("createintuiEasy") 
                                            || winsArr.current.includes("createintsocialEasy") ? "dot won" : "dot"}></span>
                            <span className={winsArr.current.includes("createintwriteMedium") 
                                            || winsArr.current.includes("createintuiMedium") 
                                            || winsArr.current.includes("createintsocialMedium") ? "dot won" : "dot"}></span>
                            <span className={winsArr.current.includes("createintwriteHard") 
                                            || winsArr.current.includes("createintuiHard") 
                                            || winsArr.current.includes("createintsocialHard") ? "dot won" : "dot"}></span>
                        </div>
                    </div>
                </div>

                <h3 className="diff-levels">Level 3</h3>
                <div onClick={() => {showDetail(LeadIntDesc, LeadIntCOO, BasicIntProcess); 
                                    UserChoices.setScenario('leadintcoo')}} className="scenario">
                    <img className="opponent" src={coo} alt='prospect' />
                    <div>
                        <div className="scenario-title">Leadership Role</div>
                        <div>Assess your ability to lead and organize people.</div>
                        <div className="difficulty-indicators">
                            <span className={winsArr.current.includes("leadintcooEasy") 
                                            || winsArr.current.includes("leadintcfoEasy") 
                                            || winsArr.current.includes("leadintceoEasy") ? "dot won" : "dot"}></span>
                            <span className={winsArr.current.includes("leadintcooMedium") 
                                            || winsArr.current.includes("leadintcfoMedium") 
                                            || winsArr.current.includes("leadintceoMedium") ? "dot won" : "dot"}></span>
                            <span className={winsArr.current.includes("leadintcooHard") 
                                            || winsArr.current.includes("leadintcfoHard") 
                                            || winsArr.current.includes("leadintceoHard") ? "dot won" : "dot"}></span>
                        </div>
                    </div>
                </div>
                {/*<div className="scenario">
                    <img className="opponent" src={startup1} alt='prospect' />
                    <div>
                        <div className="scenario-title">Start-Up Environment</div>
                        <div>New companies require a unique type of human.</div>
                        <div className="coming-soon-overlay">COMING SOON</div>
                    </div>
                </div>*/}
                {/*<div className="scenario">
                    <img className="opponent" src={scientist1} alt='prospect' />
                    <div>
                        <div className="scenario-title">Scientific Research</div>
                        <div>Assess your domain-specific abilities in the hard-sciences.</div>
                        <div className="coming-soon-overlay">COMING SOON</div>
                    </div>
            </div>*/}
            </div>
            
            
            <div className="menu">

                <h3 className="category">Art of Negotiation</h3>
                <h3 className="diff-levels">Level 1</h3>
                <div onClick={() => {showDetail(SalaryNegDesc, SalaryNegProj, SalaryNegProcess); 
                                    UserChoices.setScenario('salarynegproj')}} className="scenario">
                    <img className="opponent" src={negotiation4} alt='prospect' />
                    <div>
                        <div className="scenario-title">Salary Negotiation</div>
                        <div>Why do you deserve more?</div>
                        <div className="difficulty-indicators">
                            <span className={winsArr.current.includes("salarynegdevEasy") 
                                            || winsArr.current.includes("salarynegprojEasy") 
                                            || winsArr.current.includes("salarynegdesEasy") ? "dot won" : "dot"}></span>
                            <span className={winsArr.current.includes("salarynegdevMedium") 
                                            || winsArr.current.includes("salarynegprojMedium") 
                                            || winsArr.current.includes("salarynegdesMedium") ? "dot won" : "dot"}></span>
                            <span className={winsArr.current.includes("salarynegdevHard") 
                                            || winsArr.current.includes("salarynegprojHard") 
                                            || winsArr.current.includes("salarynegdesHard") ? "dot won" : "dot"}></span>
                        </div>
                    </div>
                </div>
                
                <h3 className="diff-levels">Level 2</h3>
                {/*<div className="scenario">
                    <img className="opponent" src={deal2} alt='prospect' />
                    <div>
                        <div className="scenario-title">Supply Agreement</div>
                        <div>Strike a bulk discount deal with a supplier.</div>
                        <div className="coming-soon-overlay">COMING SOON</div>
                    </div>
                </div>*/}
                {/*<div className="scenario">
                    <img className="opponent" src={deal3} alt='prospect' />
                    <div>
                        <div className="scenario-title">Joint Venture</div>
                        <div>Explain why working together just makes sense.</div>
                        <div className="coming-soon-overlay">COMING SOON</div>
                    </div>
            </div>*/}
                <div onClick={() => {showDetail(RealEstateDesc, RealEstateLinda, RealEstateProcess); 
                                    UserChoices.setScenario('realestate')}} className="scenario">
                    <img className="opponent" src={realestate1} alt='prospect' />
                    <div>
                        <div className="scenario-title">Real Estate</div>
                        <div>A potential buyer is interested. Can you sweeten the deal enough to close?</div>
                        <div className="difficulty-indicators">
                            <span className={winsArr.current.includes("realestateEasy") ? "dot won" : "dot"}></span>
                            <span className={winsArr.current.includes("realestateMedium") ? "dot won" : "dot"}></span>
                            <span className={winsArr.current.includes("realestateHard") ? "dot won" : "dot"}></span>
                        </div>
                    </div>
                </div>
                {/*<div className="scenario">
                    <img className="opponent" src={deal4} alt='prospect' />
                    <div>
                        <div className="scenario-title">Licensing Deal</div>
                        <div>Secure fair terms for the use of your product.</div>
                        <div className="coming-soon-overlay">COMING SOON</div>
                    </div>
        </div>*/}
                

                <h3 className="diff-levels">Level 3</h3>
                <div onClick={() => {showDetail(InvestmentPitchDesc, InvestmentPitchUrban, RealEstateProcess); 
                                    UserChoices.setScenario('investmentpitchurban')}} className="scenario">
                    <img className="opponent" src={deal6} alt='prospect' />
                    <div>
                        <div className="scenario-title">Investment Pitch</div>
                        <div>Secure funding and favorable terms from investors.</div>
                        <div className="difficulty-indicators">
                            <span className={winsArr.current.includes("investmentpitchurbanEasy") 
                                            || winsArr.current.includes("investmentpitchtreatEasy") 
                                            || winsArr.current.includes("investmentpitchstemEasy") ? "dot won" : "dot"}></span>
                            <span className={winsArr.current.includes("investmentpitchurbanMedium") 
                                            || winsArr.current.includes("investmentpitchtreatMedium") 
                                            || winsArr.current.includes("investmentpitchstemMedium") ? "dot won" : "dot"}></span>
                            <span className={winsArr.current.includes("investmentpitchurbanHard") 
                                            || winsArr.current.includes("investmentpitchtreatHard") 
                                            || winsArr.current.includes("investmentpitchstemHard") ? "dot won" : "dot"}></span>
                        </div>
                    </div>
                </div>
                {/*<div className="scenario">
                    <img className="opponent" src={int1} alt='prospect' />
                    <div>
                        <div className="scenario-title">International Trade Agreement</div>
                        <div>Strike a bulk discount deal with a supplier.</div>
                        <div className="coming-soon-overlay">COMING SOON</div>
                    </div>
    </div>*/}
                {/*<div className="scenario">
                    <img className="opponent" src={hostage1} alt='prospect' />
                    <div>
                        <div className="scenario-title">Hostage Negotiation</div>
                        <div>Secure the release of hostages.</div>
                        <div className="coming-soon-overlay">COMING SOON</div>
                    </div>
    </div>*/}
            </div>

            <div className="menu">

                <h3 className="category">Art of Debate</h3>
                <h3 className="diff-levels">Level 1</h3>
                <div onClick={() => {showDetail(UsPoliticsDesc, UsPoliticsGreen, DebateProcess); 
                                    UserChoices.setScenario('uspoliticsgreen')}} className="scenario">
                    <img className="opponent" src={politician1} alt='prospect' />
                    <div>
                        <div className="scenario-title">US Politics</div>
                        <div>Play the role of a US lawmaker and fight legislative battles.</div>
                        <div className="difficulty-indicators">
                            <span className={winsArr.current.includes("uspoliticsgreenEasy") 
                                            || winsArr.current.includes("uspoliticsgunEasy") 
                                            || winsArr.current.includes("uspoliticsubiEasy") ? "dot won" : "dot"}></span>
                            <span className={winsArr.current.includes("uspoliticsgreenMedium") 
                                            || winsArr.current.includes("uspoliticsgunMedium") 
                                            || winsArr.current.includes("uspoliticsubiMedium") ? "dot won" : "dot"}></span>
                            <span className={winsArr.current.includes("uspoliticsgreenHard") 
                                            || winsArr.current.includes("uspoliticsgunHard") 
                                            || winsArr.current.includes("uspoliticsubiHard") ? "dot won" : "dot"}></span>
                        </div>
                    </div>
                </div>
                
                <h3 className="diff-levels">Level 2</h3>
                <div className="scenario">
                    <img className="opponent" src={law2} alt='prospect' />
                    <div>
                        <div className="scenario-title">Contract Law</div>
                        <div></div>
                        <div className="coming-soon-overlay">COMING SOON</div>
                    </div>
                </div>
                {/*<div className="scenario" href="#debate3">
                    <img className="opponent" src={econ1} alt='prospect' />
                    <div>
                        <div className="scenario-title">Economic Policy</div>
                        <div></div>
                        <div className="coming-soon-overlay">COMING SOON</div>
                    </div>
                </div>*/}
                <div className="scenario">
                    <img className="opponent" src={law1} alt='prospect' />
                    <div>
                        <div className="scenario-title">Criminal Law</div>
                        <div></div>
                        <div className="coming-soon-overlay">COMING SOON</div>
                    </div>
                </div>
                

                <h3 className="diff-levels">Level 3</h3>
                <div className="scenario">
                    <img className="opponent" src={aiCEO} alt='prospect' />
                    <div>
                        <div className="scenario-title">Corporate Law</div>
                        <div></div>
                        <div className="coming-soon-overlay">COMING SOON</div>
                    </div>
                </div>
                {/*<div className="scenario">
                    <img className="opponent" src={theism} alt='prospect' />
                    <div>
                        <div className="scenario-title">Theism vs Atheism</div>
                        <div></div>
                        <div className="coming-soon-overlay">COMING SOON</div>
                    </div>
                </div>*/}
            </div>

            
        </div>
    );
};

export default Pre
