import React from 'react';
import './success.css';
import { useNavigate } from 'react-router-dom';



const PaymentSuccess = () => {
    let navigate = useNavigate();

    const goHome = () => {
        navigate('../');
    }


    return (
        <div className="container">
            <div className="payment-success-card">
                <h1 className="payment-success-title">Payment Successful!</h1>
                <p className="payment-success-text">Thank you for your subscription. You now have full access to all premium features.</p>
                <button className="payment-success-button" onClick={goHome}>
                    Return Home
                </button>
            </div>
        </div>
    )
}

export default PaymentSuccess;