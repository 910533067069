import React from 'react';
import './header.css';
import { useNavigate } from 'react-router-dom';
import group from '../../assets/group3.png';

import { auth } from '../../config/firebase.js';

const UserChoices = require('../../userChoices.js');

const Header = ({ showSignUp, setIntent }) => {
  let navigate = useNavigate();

  const SignOrSubscribe = () => {
    if (auth?.currentUser?.email) {
      navigate('../Prep');
    } else {
      showSignUp();
    }
  }

  return (
    <div className='header section__padding' id='home'>
        <div className='header-content'>
          <h1 className='gradient__text master-text'>Master the Art of Communication</h1>
          
          <p className="master-subtext"> Practice sales and interviews with the power of Artificial Intelligence. </p>
          
          
          <iframe className='vid'
          src="https://www.youtube.com/embed/QRt2YB588K8?si=tnvAr3e1f4ttlacN" 
          title="YouTube video player" 
          frameBorder="1" 
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
          referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
          
          <div className='header-content__input'>
            <input type='email' 
                   placeholder='Your Email Address'
                   
                   onChange={(e) => UserChoices.setTempEmail(e.target.value)}/>
            <button type='button' onClick={() => { setIntent(); SignOrSubscribe(); }}>Play Now</button>
          </div>
          <div className= 'details'>Unlimited Applications - Challenging Scenarios - Dangerously Addicting</div>
          <a className='prodhunt' href="https://www.producthunt.com/posts/verbeloquence-ai?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-verbeloquence&#0045;ai" target="_blank" rel="noreferrer">
            <img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=448566&theme=light" 
            alt="Verbeloquence&#0046;ai - Gamified&#0032;sales&#0044;&#0032;interview&#0044;&#0032;negotiation&#0044;&#0032;and&#0032;debate&#0032;practice | Product Hunt" 
            width="250" height="54" />
          </a>
          
        </div>
        <div className='header-image'> 
          <img src={group} alt='group' />
        </div>
    </div>
  )
}

export default Header