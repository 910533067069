import React from 'react';
import Feature from '../../components/feature/Feature';
import './what.css';

const What = () => {
  return (
    <div className='desc__all section__margin' id='howitworks'>
      <div className='main_question'>
        <p>What is Verbeloquence?</p>
      </div>
 
      <div className='main_desc'>
        <p>Verbeloquence is an <b>audio-based</b> game played by speaking and listening. We create realistic scenarios that help players master the power of their own spoken word.</p>
      </div>

      <div className='desc__all-heading'>
          <h1 className='gradient__text'>Learn by doing. Gain confidence. Sell. Negotiate. Converse.</h1>
      </div>

      
      <div className='desc__all-container'>
        <Feature title="How to Play" text="Speak to AI as if you were speaking to a person. Use personal tried-and-true techniques or try new approaches to close the sale, negotiate the deal, win the argument, or get the job. Verbeloquence only requires a microphone to get started."/>
        <Feature title="How to Win" text="AI can judge the quality of your responses with respect to logic, persuasiveness, and even emotional intelligence. Immersive roleplay will help you win this game, and of course, become a better communicator!"/>
        <Feature title="How it Works" text="Verbeloquence is powered by GPT-Turbo. No two playthroughs are alike, even for the same scenario. The conversation will go wherever you and the AI take it. Stay on track to reach the winning condition!"/>
      </div>
    </div>
  )
}

export default What