import React, { useEffect, useState } from 'react';
import './subscribe.css';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../config/firebase.js';


const DOMAIN = `${process.env.REACT_APP_API_URL}`;


const SubPayment = () => {
    let navigate = useNavigate();
    const [isLoggedIn] = useState(auth?.currentUser?.email ? true : false);
    const [userEmail, setUserEmail] = useState(auth?.currentUser?.email);
    const [userId, setUserId] = useState(auth?.currentUser?.uid);
    console.log("Email1: " + userEmail);
    console.log("User1: " + userId);

    useEffect(() => {
        setUserEmail(auth?.currentUser?.email);
        setUserId(auth?.currentUser?.uid);
    }, []);

    useEffect(() => {
        const handleUserLogIn = async () => {
            console.log("User logged in");
            setUserEmail(auth?.currentUser?.email);
            setUserId(auth?.currentUser?.uid);
        };
    
        window.addEventListener('userLoggedIn', handleUserLogIn);
    
        return () => window.removeEventListener('userLoggedIn', handleUserLogIn);
    }, []);
    

    const checkout = async () => {

        console.log("EmailCheck: " + userEmail);
        console.log("UserCheck: " + userId);

        if (auth?.currentUser?.uid) {
            fetch(DOMAIN + "/create-checkout-session", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                mode: "cors",
                body: JSON.stringify({ 
                    customerEmail: userEmail,
                    firebaseId: userId,
                }),
            })
            .then(res => {
                if (!res.ok) {
                  throw new Error(`HTTP error! Status: ${res.status}`);
                }
                return res.json();
            })
            .then(response => {
                //Navigate to stripe checkout page
                //console.log("Response object:", response);
                window.location.href = response.url;
            })
            .catch((e) => {
                console.log("Error: " + e);
            });
        } else {
            navigate('../');
        }
        
    };


    const proceed = () => {
        if (isLoggedIn) {
          navigate('../Prep');
        } else {
          navigate('../ChooseCharacter');
        }
      }

    const goHome = () => {
        navigate('../');
    }
    

    return (
        <div className="container">
            <button className="return-button" onClick={goHome}>
                Return Home
            </button>
            <div className="pricing-card">
                <h2 className="plan-name">FREE</h2>
                <p className="price"><span className="dollar-sign">$</span>0<span className="per-month">/ month</span></p>
                <hr className="divider"></hr>
                <ul className="features">
                    <li><span className="checkmark">✔</span>Access to Non-Custom Scenarios</li>
                    <li><span className="checkmark">✔</span>Live Personalized Feedback and Suggestions</li>
                    <li><span className="checkmark">✔</span>Three Conversations per Month</li>
                    <li><span className="checkmark">✔</span>24/7 Non-Priority Support</li>
                </ul>
                <button className="subscribe-button" onClick={proceed}>Continue for Free</button>
            </div>
            <div className="pricing-card">
                <h2 className="plan-name">PREMIUM</h2>
                <p className="price"><span className="dollar-sign">$</span>20<span className="per-month">/ month</span></p>
                <hr className="divider"></hr>
                <ul className="features">
                    <li><span className="checkmark">✔</span>Access to All Scenarios and Characters</li>
                    <li><span className="checkmark">✔</span>Custom Scenario Construction</li>
                    <li><span className="checkmark">✔</span>15 Conversations per Day</li>
                    <li><span className="checkmark">✔</span>24/7 Priority Support</li>
                </ul>
                <button className="subscribe-button" onClick={checkout}>Subscribe</button>
            </div>
        </div>

    )
}

export default SubPayment;