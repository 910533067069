import React from 'react';
import './poweredby.css';
import poweredbyopenai from '../../assets/poweredbyopenai.png';
import poweredbydeepgram from '../../assets/poweredbydeepgram.png';
import poweredbygoog from '../../assets/goog.png';
//import { poweredbyopenai, poweredbydeepgram } from './imports'

const Brand = () => {
  return (
    <div className='poweredby div'>
        <div>
          <img src={poweredbyopenai} alt='google'/>
        </div>
        <div>
          <img src={poweredbydeepgram} alt='google'/>
        </div>
        <div>
          <img src={poweredbygoog} alt='google'/>
        </div>
    </div>
  )
}

export default Brand