import React, { useEffect, useState, useRef } from 'react';
import './profile.css';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../config/firebase.js';

import char1 from '../../assets/character1.png';
import char2 from '../../assets/character2.png';
import char3 from '../../assets/character3.png';
import char4 from '../../assets/character4.png';
import char5 from '../../assets/character5.png';
import char6 from '../../assets/character6.png';
import char7 from '../../assets/character7.png';
import char8 from '../../assets/character8.png';
import char9 from '../../assets/character9.png';
import char10 from '../../assets/character10.png';

const UserChoices = require('../../userChoices.js');

const DOMAIN = `${process.env.REACT_APP_API_URL}`;


const ProfilePage = () => {
    UserChoices.rehydrate();
    let navigate = useNavigate();

    const [curChar, setCurChar] = useState(null);
    const [curEmail, setCurEmail] = useState(UserChoices.email);
    const [userId, setUserId] = useState(auth?.currentUser?.uid);
    const [curComp, setCurComp] = useState('-');
    const [curLink, setCurLink] = useState('-');
    const [curX, setCurX] = useState('-');

    const [editing, setEditing] = useState(false);
    const [paid, setPaid] = useState(false);
    const [isCancelVisible, setCancelVisible] = useState(false);
    const [cancelMsg, setCancelMsg] = useState('');

    // Force Render
    const renderTrigger = useRef(() => {});
    const [key, setKey] = useState(false);
    renderTrigger.current = () => {
        setKey(!key);
    };

    // On simple navigation
    useEffect(() => {
        const getProfileData = async () => {
            await retrieveProfileData(auth?.currentUser?.uid).then((data) => {
                setCurComp(data.company);
                setCurLink(data.linkedin);
                setCurX(data.xAccount);
                setPaid(data.premium);
                if(data.cancelledStatus) {
                    setCancelMsg("Cancellation Successful: You still have premium access until the end of your billing cycle. You will not be billed again.")
                }
                renderTrigger.current();
            });
        }

        // Pull from database if signed in
        if (auth?.currentUser?.uid) {
            //console.log("Getting Profile Data");
            setUserId(auth?.currentUser?.uid);
            getProfileData();
        }
    }, []);

    // On Refresh
    useEffect(() => {
        const handleUserLogIn = async () => {
            UserChoices.setEmail(auth?.currentUser?.email);
            setCurEmail(auth?.currentUser?.email);
            setUserId(auth?.currentUser?.uid);
            

            const getProfileData = async () => {
                await retrieveProfileData(auth?.currentUser?.uid).then((data) => {
                    setCurComp(data.company);
                    setCurLink(data.linkedin);
                    setCurX(data.xAccount);
                    setPaid(data.premium);
                    console.log("Cancelled Status: " + data.cancelledStatus);
                    if(data.cancelledStatus) {
                        setCancelMsg("Cancellation Successful: You still have premium access until the end of your billing cycle. You will not be billed again.")
                    }
                    renderTrigger.current();
                });
            }
    
            // Pull from database if signed in
            if (auth?.currentUser?.uid) {
                //console.log("Getting Profile Data");
                getProfileData();
            }
        };
    
        window.addEventListener('userLoggedIn', handleUserLogIn);
    
        return () => window.removeEventListener('userLoggedIn', handleUserLogIn);
      }, []);



    const checkout = async () => {
        console.log("EmailCheck: " + curEmail);
        console.log("UserCheck: " + userId);

        fetch(DOMAIN + "/create-checkout-session", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            mode: "cors",
            body: JSON.stringify({ 
                customerEmail: curEmail,
                firebaseId: userId,
            }),
        })
        .then(res => {
            if (!res.ok) {
              throw new Error(`HTTP error! Status: ${res.status}`);
            }
            return res.json();
        })
        .then(response => {
            //Navigate to stripe checkout page
            //console.log("Response object:", response);
            window.location.href = response.url;
        })
        .catch((e) => {
            console.log("Error: " + e);
        });
    };


    const cancel = async () => {
        console.log("EmailCheck: " + curEmail);
        console.log("UserCheck: " + userId);

        fetch(DOMAIN + "/cancel-subscription", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            mode: "cors",
            body: JSON.stringify({ 
                customerEmail: curEmail,
                firebaseId: userId,
            }),
        })
        .then(res => {
            if (!res.ok) {
              throw new Error(`HTTP error! Status: ${res.status}`);
            }
            return res.json();
        })
        .then(response => {
            //Navigate to stripe checkout page
            console.log("Response object:", response.cancelPending);
            if (response.cancelPending) {
                //setPaid(false);
                setCancelMsg("Cancellation Successful: You still have premium access until the end of your billing cycle")
                renderTrigger.current();
            } else {
                console.log("Error canceling subscription");

            }
            //window.location.href = response.url;
        })
        .catch((e) => {
            console.log("Error: " + e);
        });
    };
    

    const goHome = () => {
        navigate('../');
    }

    const goPrep = () => {
        navigate('../Prep');
    }

    const goChar = () => {
        navigate('../ChooseCharacter');
    }

    const saveProfileData = () => {
        saveProfile(auth?.currentUser?.uid, curComp, curLink, curX);
        setEditing(false);
    }

    // Save profile data
    async function saveProfile(userId, company, linkedin, xAccount) {
        fetch(DOMAIN + "/set-profile-data", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        mode: "cors",
        body: JSON.stringify({ 
            firebaseId: userId,
            comp: company,
            link: linkedin,
            xa: xAccount,
        }),
        })
        .then(res => {
            if (!res.ok) {
            throw new Error(`HTTP error! Status: ${res.status}`);
            }
            return res.json();
        })
        .then(response => {
            console.log("Profile Saved: " + response);
            return response;
        })
        .catch((e) => {
            console.log("Error: " + e);
        });
      }

    async function retrieveProfileData(userId) {
        //console.log("Retrieving Profile Data");
        return fetch(DOMAIN + "/get-profile-data", {
          method: "POST",
          headers: {
              "Content-Type": "application/json",
          },
          mode: "cors",
          body: JSON.stringify({ 
              firebaseId: userId,
          }),
        })
        .then(res => {
            if (!res.ok) {
              throw new Error(`HTTP error! Status: ${res.status}`);
            }
            return res.json();
        })
        .then(response => {
            return response;
        })
        .catch((e) => {
            console.log("Error: " + e);
        });
    }

    useEffect(() => {
        switch (UserChoices.character) {
            case "victor":
                setCurChar(char1);
                break;
            case "akira":
                setCurChar(char6);
                break;
            case "xavier":
                setCurChar(char7);
                break;
            case "maya":
                setCurChar(char3);
                break;
            case "ashton":
                setCurChar(char2);
                break;
            case "naomi":
                setCurChar(char8);
                break;
            case "sebastian":
                setCurChar(char10);
                break;
            case "victoria":
                setCurChar(char4);
                break;
            case "yasuke":
                setCurChar(char5);
                break;
            case "amber":
                setCurChar(char9);
                break;
            default:
                setCurChar(char1);
        }
    }, []);


    function CancelPopup({ onClose }) {
        const [code, setCode] = useState('');
    
        const testUnsubscribe = (test) => {
            if (test === "unsubscribe") {
                cancel();
            } else {
                setCancelMsg("Invalid Input");
            }
        }
    
        return (
            <div className="detail-overlay">
                <div className="sign-popup">
                    <button className="close-button" onClick={onClose}>x</button>
                    <h2>Type "unsubscribe" to cancel your subscription</h2>
                    
                    <input 
                        type="email" 
                        placeholder="unsubscribe"
                        onChange={(e) => setCode(e.target.value)}
                    />
                    <button className="email-sign-up-button" onClick={() => testUnsubscribe(code)}>Click to Cancel Subscription</button>
                    <p className="sign-exception">{cancelMsg}</p>
                </div>
            </div>
        );
    };

    const closeCancelPopup = () => {
        setCancelVisible(false);
    }

    const showCancelPopup = () => {
        setCancelVisible(true);
    }



    return (
        <div className="profile-container">
            {isCancelVisible && <CancelPopup onClose={closeCancelPopup}/>}

            <button className="return-button" onClick={goHome}>
                Return Home
            </button>
            <button className="play-button" onClick={goPrep}>
                Play Now
            </button>
            <div className="user-details-card">
                <div className="user-image-section">
                    <div>
                        <img src={curChar} alt='User profile' className="user-profile-image" onClick={goChar}/>
                        <button className="change-photo-button" onClick={goChar}>📸</button>
                    </div>
                </div>

                <div className="user-info-section">
                    <div className="user-detail">
                    <span className="user-detail-title">Email:</span>
                    <span className="user-detail-content">{curEmail}</span>
                    </div>

                    {editing ? (
                        <React.Fragment>
                            <div className="user-detail">
                            <span className="user-detail-title">Company</span>
                            <input className="comp-input" 
                                    value={curComp}
                                    onChange={(e) => setCurComp(e.target.value)}/>
                            </div>
                            <div className="user-detail">
                            <span className="user-detail-title">LinkedIn</span>
                            <input className="link-input" 
                                    value={curLink}
                                    onChange={(e) => setCurLink(e.target.value)}/>
                            </div>
                            <div className="user-detail">
                            <span className="user-detail-title">X</span>
                            <input className="x-input" 
                                    value={curX}
                                    onChange={(e) => setCurX(e.target.value)}/>
                            </div>
                            <button className="save-button" onClick={saveProfileData}>Save</button>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <div className="user-detail">
                            <span className="user-detail-title">Company</span>
                            <span className="user-detail-content">{curComp}</span>
                            </div>
                            <div className="user-detail">
                            <span className="user-detail-title">LinkedIn</span>
                            <span className="user-detail-content">{curLink}</span>
                            </div>
                            <div className="user-detail">
                            <span className="user-detail-title">X</span>
                            <span className="user-detail-content">{curX}</span>
                            </div>
                            <button className="save-button" onClick={() => setEditing(true)}>Edit</button>
                        </React.Fragment>
                    )}
                    
                </div>
            </div>

            <div className="profile-card personalized-report">
                <div className="report-content">
                    <h2 className="profile-title">Personalized Report/Analytics</h2>
                    {/*<button className="profile-button">Generate Report</button>*/}
                    <p className="profile-title">(Coming Soon)</p>
                </div>
            </div>

            
            <div className="profile-card subscription-options">
                <div className="subscription-content">
                    <h2 className="profile-title">Subscription Status</h2>
                    <div className="subscription-status-container">
                        <div className="sub-option-card">
                            <h2 className="plan-name">FREE</h2>
                            <p className="price"><span className="dollar-sign">$</span>0<span className="per-month">/ month</span></p>
                            <hr className="divider"></hr>
                            <ul className="features">
                                <li><span className="checkmark">✔</span>Access to Non-Custom Scenarios</li>
                                <li><span className="checkmark">✔</span>Live Personalized Feedback and Suggestions</li>
                                <li><span className="checkmark">✔</span>Three Conversations per Month</li>
                                <li><span className="checkmark">✔</span>24/7 Non-Priority Support</li>
                            </ul>
                        </div>
                        <div className="subscription-details">
                            {paid ? (
                                <React.Fragment>
                                    <div className="membership-status-badge">
                                        Premium Member
                                    </div>
                                    <p className="membership-status-text">
                                        You have access to all premium features.
                                    </p>
                                    <button className="subscribe-button" onClick={showCancelPopup}>Unsubscribe</button>
                                    <p>{cancelMsg}</p>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <div className="membership-status-badge">
                                        Free User
                                    </div>
                                    <p className="membership-status-text">
                                        You have limited access.
                                    </p>
                                    <button className="subscribe-button" onClick={checkout}>Upgrade to Premium</button>
                                </React.Fragment>
                            )}
                        </div>
                        <div className="sub-option-card">
                            <h2 className="plan-name">PREMIUM</h2>
                            <p className="price"><span className="dollar-sign">$</span>20<span className="per-month">/ month</span></p>
                            <hr className="divider"></hr>
                            <ul className="features">
                                <li><span className="checkmark">✔</span>Access to All Scenarios and Characters</li>
                                <li><span className="checkmark">✔</span>Custom Scenario Construction</li>
                                <li><span className="checkmark">✔</span>15 Conversations per Day</li>
                                <li><span className="checkmark">✔</span>24/7 Priority Support</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            

        </div>
    )

}

export default ProfilePage;